import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REWARDS_START,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARDS_FAILURE,
  SET_REWARDS_META,
} from './rewards.constants'
import {
  RewardsDictionary,
  RewardsIdList,
  RewardsRequestingStateActions,
} from './rewards.types'
import {
  SetRewardsMetaAction,
  FetchRewardsSuccessAction,
} from './rewards-actions.types'

function rewardsById(
  state: RewardsDictionary = {},
  {type, payload}: FetchRewardsSuccessAction,
): RewardsDictionary {
  switch (type) {
    case FETCH_REWARDS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.rewards}
      }
      return state
    }
    default:
      return state
  }
}

function allRewardsIds(
  state: RewardsIdList = [],
  {type, payload}: FetchRewardsSuccessAction,
): RewardsIdList {
  switch (type) {
    case FETCH_REWARDS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function rewardsMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetRewardsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_REWARDS_META:
      return payload
    default:
      return state
  }
}

function rewardsRequesting(
  state = true,
  {type}: RewardsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_REWARDS_START:
      return true
    case FETCH_REWARDS_SUCCESS:
    case FETCH_REWARDS_FAILURE:
      return false
    default:
      return state
  }
}

const rewardsReducer = combineReducers({
  byId: rewardsById,
  allIds: allRewardsIds,
  meta: rewardsMeta,
  requesting: rewardsRequesting,
})

export default rewardsReducer
