import React from 'react'
import {Route, Redirect, RouteComponentProps} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectUserAuth} from '_redux/user/user.selectors'

const PrivateRoute: React.FC<{
  component: React.FC<RouteComponentProps>
  path: string | string[]
  exact?: boolean
}> = ({component: Component, ...rest}) => {
  const userAuth = useSelector(selectUserAuth)
  const renderPrivateComponent: React.FC<RouteComponentProps> = props => {
    if (!userAuth)
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location.pathname},
          }}
        />
      )
    return <Component {...props} />
  }
  return <Route {...rest} render={renderPrivateComponent} />
}

export default PrivateRoute
