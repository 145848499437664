/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable dot-notation */
/* eslint-disable complexity */
/* eslint-disable no-new */
import React, {useRef, useEffect, FC} from 'react'
import Chart from 'chart.js'
import {ChartProps} from './types'

// https://stackoverflow.com/questions/20966817/how-to-add-text-inside-the-doughnut-chart-using-chart-js
Chart.pluginService.register({
  // eslint-disable-next-line max-statements
  beforeDraw: chart => {
    if (chart?.config?.options?.elements?.center) {
      // Get ctx from string
      const ctx = chart['chart'].ctx
      // Get options from the center object in options
      const centerConfig = chart.config.options.elements.center
      const fontStyle = 'Arial'
      const txt = centerConfig.text
      const color = '#000'
      const maxFontSize = 75
      const sidePadding = 20
      const sidePaddingCalculated =
        (sidePadding / 100) * (chart['innerRadius'] * 2)
      // Start with a base font of 30px
      ctx.font = `30px ${fontStyle}`

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      const stringWidth = ctx.measureText(txt).width
      const elementWidth = chart['innerRadius'] * 2 - sidePaddingCalculated

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth
      const newFontSize = Math.floor(30 * widthRatio)
      const elementHeight = chart['innerRadius'] * 2

      // Pick a new font size so it will not be larger than the height of label.
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
      let minFontSize = 25
      const lineHeight = 25
      let wrapText = false

      if (minFontSize === undefined) {
        minFontSize = 20
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize
        wrapText = true
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
      let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
      ctx.font = `${fontSizeToUse}px ${fontStyle}`
      ctx.fillStyle = color

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY)
        return
      }

      const words = txt.split(' ')
      let line = ''
      const lines = []

      // Break words up into multiple lines if necessary
      for (let n = 0; n < words.length; n++) {
        const testLine = `${line + words[n]} `
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > elementWidth && n > 0) {
          lines.push(line)
          line = `${words[n]} `
        } else {
          line = testLine
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight

      for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY)
        centerY += lineHeight
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY)
    }
  },
})

const DoughnutChart: FC<ChartProps & {centerText?: string}> = ({
  datasets,
  labels,
  centerText,
}) => {
  const ref = useRef<HTMLCanvasElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      new Chart(ref.current, {
        type: 'doughnut',
        data: {
          labels,
          datasets,
        },
        options: {
          cutoutPercentage: 85,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'left',
            align: 'center',
            labels: {
              fontColor: 'black',
              boxWidth: 20,
              padding: 15,
              usePointStyle: true,
            },
          },
          elements: {
            center: {
              text: centerText || '',
            },
          },
        },
      })
    }
  }, [centerText, datasets, labels])
  return (
    <canvas
      ref={ref}
      width="400"
      height="400"
      aria-label="Doughnut Chart displaying data"
      role="img"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  )
}
export default DoughnutChart
