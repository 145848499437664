import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import PageNotFound from 'components/common/page-not-found.component'
import PublicRoute from 'components/hoc/public-route.component'
import PrivateRoute from 'components/hoc/private-route.component'
import DashboardPages from 'pages/dashboard/overview.component'
import LoginPage from 'pages/login/overview.component'

const AllRoutes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <PublicRoute exact path="/login" component={LoginPage} />
      <PrivateRoute path="/dashboard" component={DashboardPages} />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default AllRoutes
