import React, {FC} from 'react'
import {Table} from 'reactstrap'
import {Series} from '_redux/bonds/single/bonds-single.types'
import {formatCash} from 'helpers/utils'
import format from 'date-fns/format'

type Props = {
  series: Series
}

const getInterestPaid = (interestPaid: number) => {
  if (interestPaid === 1) return 'Monthly'
  if (interestPaid === 2) return 'Quarterly'
  if (interestPaid === 3) return 'Annually'

  return '-'
}

const DashboardBondSeriesTable: FC<Props> = ({series}) => {
  return (
    <Table responsive hover bordered>
      <thead>
        <tr>
          <th className="border-left-0 border-bottom-0 pl-0">Name (Tranche)</th>
          <th className="border-bottom-0">On Offer</th>
          <th className="border-bottom-0">Tenor (years)</th>
          <th className="border-bottom-0">Interest Rate(%)</th>
          <th className="border-bottom-0">Min. Units</th>
          <th className="border-bottom-0">Unit Price</th>
          <th className="border-bottom-0">Units Available</th>
          <th className="border-bottom-0">Security</th>
          <th className="border-bottom-0">Interest Paid</th>
          <th className="border-bottom-0">Start Date</th>
          <th className="border-bottom-0">Close Date</th>
          <th className="border-bottom-0">Maturity Date</th>
          <th
            className="border-bottom-0 font-weight-normal text-center"
            colSpan={2}
            scope="colgroup"
          >
            Total Raised
          </th>
          <th
            className="border-right-0 border-bottom-0 font-weight-normal text-center pr-0"
            colSpan={2}
            scope="colgroup"
          >
            Interest Payments (Proj)*
          </th>
        </tr>
        <tr>
          <th className="border-left-0 border-top-0 border-bottom-0 pl-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0" />
          <th className="border-left-0 border-top-0 border-bottom-0 pt-0">
            Estimate
          </th>
          <th className="border-left-0 border-top-0 border-bottom-0 pt-0">
            Actual
          </th>
          <th className="border-top-0 border-bottom-0 pt-0">Annual</th>
          <th className="border-right-0 border-top-0 border-bottom-0 pt-0 pr-0">
            Life of issue
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-left-0 border-bottom-0 pb-0 pl-0">
            {series.bond_name}
          </td>
          <td className="border-bottom-0 pb-0">£{formatCash(+series.offer)}</td>
          <td className="border-bottom-0 pb-0">{series.tenor}</td>
          <td className="border-bottom-0 pb-0 text-danger">
            {series.interest}%
          </td>
          <td className="border-bottom-0 pb-0">
            {series.min_unit.toLocaleString()}
          </td>
          <td className="border-bottom-0 pb-0">
            £{series.unit_price.toLocaleString()}
          </td>
          <td className="border-bottom-0 pb-0">
            {series.units.toLocaleString()}
          </td>
          <td className="border-bottom-0 pb-0">{series.security}</td>
          <td className="border-bottom-0 pb-0">
            {getInterestPaid(series.interest_paid)}
          </td>
          <td className="border-bottom-0 pb-0">
            {format(new Date(series.start_date), 'd MMM yyyy')}
          </td>
          <td className="border-bottom-0 pb-0">
            {format(new Date(series.close_date), 'd MMM yyyy')}
          </td>
          <td className="border-bottom-0 pb-0">
            {format(new Date(series.maturity_date), 'd MMM yyyy')}
          </td>
          <td className="border-bottom-0 pb-0">
            £{formatCash(+series.total_raised.estimate)}
          </td>
          <td className="border-bottom-0 pb-0">
            £{formatCash(+series.total_raised.actual)}
          </td>
          <td className="border-bottom-0 pb-0">
            £{formatCash(+series.interest_payment.annual)}
          </td>
          <td className="border-right-0 border-bottom-0 pb-0 pr-0">
            £{formatCash(+series.interest_payment.life_of_issue)}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default DashboardBondSeriesTable
