import {combineReducers} from 'redux'
import {
  DashboardModelMeta,
  DashboardModelTypes,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_ASSOCIATES_START,
  FETCH_ASSOCIATES_SUCCESS,
  FETCH_ASSOCIATES_FAILURE,
  SET_ASSOCIATES_META,
  SEARCH_ASSOCIATES_SUCCESS,
  CLEAR_SEARCH_ASSOCIATES,
  SET_SEARCH_ASSOCIATES_META,
  SEARCH_ASSOCIATES_START,
  SEARCH_ASSOCIATES_FAILURE,
} from './associates.constants'
import {
  AssociatesDictionary,
  AssociatesIdList,
  AssociatesRequestingStateActions,
  AssociatesModelUpdateStateActions,
  AssociatesModelUpdateMetaActions,
} from './associates.types'

type ModelsByIdState = Record<DashboardModelTypes, AssociatesDictionary>
type AllModelsIdState = Record<DashboardModelTypes, AssociatesIdList>
type ModelsMetaState = Record<DashboardModelTypes, DashboardModelMeta>

export function associatesById(
  state: ModelsByIdState = {
    all: {},
    search: {},
  },
  action: AssociatesModelUpdateStateActions,
): ModelsByIdState {
  switch (action.type) {
    case FETCH_ASSOCIATES_SUCCESS: {
      return {
        ...state,
        all: {...state.all, ...action.payload.entities.associates},
      }
    }
    case SEARCH_ASSOCIATES_SUCCESS: {
      return {
        ...state,
        search: {...state.search, ...action.payload.entities.associates},
      }
    }
    case CLEAR_SEARCH_ASSOCIATES: {
      return {...state, search: {}}
    }
    default:
      return state
  }
}

export function allAssociatesIds(
  state: AllModelsIdState = {
    all: [],
    search: [],
  },
  action: AssociatesModelUpdateStateActions,
): AllModelsIdState {
  switch (action.type) {
    case FETCH_ASSOCIATES_SUCCESS: {
      const stateCopy = [...state.all, ...action.payload.result]
      return {
        ...state,
        all: Array.from(new Set(stateCopy)),
      }
    }
    case SEARCH_ASSOCIATES_SUCCESS: {
      const stateCopy = [...state.search, ...action.payload.result]
      return {
        ...state,
        search: Array.from(new Set(stateCopy)),
      }
    }
    case CLEAR_SEARCH_ASSOCIATES: {
      return {
        ...state,
        search: [],
      }
    }
    default:
      return state
  }
}

export function associatesMeta(
  state: ModelsMetaState = {
    all: {pages: 0},
    search: {pages: 0},
  },
  {type, payload}: AssociatesModelUpdateMetaActions,
): ModelsMetaState {
  switch (type) {
    case SET_ASSOCIATES_META:
      return {...state, all: payload}
    case SET_SEARCH_ASSOCIATES_META:
      return {...state, search: payload}
    default:
      return state
  }
}

export function associatesRequesting(
  state = true,
  {type}: AssociatesRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_ASSOCIATES_START:
    case SEARCH_ASSOCIATES_START:
      return true
    case FETCH_ASSOCIATES_SUCCESS:
    case FETCH_ASSOCIATES_FAILURE:
    case SEARCH_ASSOCIATES_SUCCESS:
    case SEARCH_ASSOCIATES_FAILURE:
      return false
    default:
      return state
  }
}

const AssociatesReducer = combineReducers({
  byId: associatesById,
  allIds: allAssociatesIds,
  meta: associatesMeta,
  requesting: associatesRequesting,
})

export default AssociatesReducer
