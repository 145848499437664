import log from 'loglevel'
import {normalize} from 'normalizr'
import {toast} from 'react-toastify'
import {SagaIterator} from 'redux-saga'
import {call, put} from 'redux-saga/effects'
import AdsService from 'services/api/ads/service'
import * as schema from '_redux/schema'
import {AddAdStartAction, FetchAdStartAction} from './ads-actions.types'
import {
  fetchAdFailure,
  fetchAdsFailure,
  fetchAdsStart,
  fetchAdsSuccess,
  fetchAdSuccess,
  setAdsMeta,
} from './ads.actions'

export function* fetchAdsStartSaga(): SagaIterator<void> {
  const adsService = new AdsService()
  try {
    const {
      data: {data: ads, pages},
    } = yield call([adsService, 'fetchAds'])
    const normalizedAds = yield call(normalize, ads.data, schema.arrayOfAds)
    yield put(fetchAdsSuccess(normalizedAds))
    yield put(setAdsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchAdsFailure())
  }
}

export function* saveNewAdStartSaga({
  payload: {setSubmitting, toggleModal, ...formData},
}: AddAdStartAction): SagaIterator<void> {
  const adsService = new AdsService()
  try {
    yield call([adsService, 'addAd'], formData)
    yield put(fetchAdsStart({page: 1}))
    yield call(toast.success, 'Advert created successfully')
  } catch (error) {
    log.warn(error)
    yield put(fetchAdsFailure())
  } finally {
    setSubmitting(false)
    toggleModal()
  }
}

export function* fetchAdStartSaga({
  payload: id,
}: FetchAdStartAction): SagaIterator<void> {
  const adsService = new AdsService()
  try {
    const {
      data: {
        data: {
          data: [ad],
        },
      },
    } = yield call([adsService, 'fetchAd'], id)
    yield put(fetchAdSuccess(ad))
  } catch (error) {
    log.warn(error)
    yield put(fetchAdFailure())
  }
}
