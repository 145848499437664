import React from 'react'
import {Table, Button} from 'reactstrap'

import {navigate} from 'helpers/utils'
import {TableBodyData} from '../types/table-body.types'
import TableHeaders from './table-headers.component'
import TableLoader from './table-loader.component'

interface Props {
  headers: string[]
  loading: boolean
  page: number
  body: TableBodyData[]
  hasMore: boolean
  loadMore(): void
  actionButtons: (key: string) => JSX.Element
}

const TableWithActions: React.FC<Props> = ({
  headers,
  body,
  loading,
  page,
  hasMore,
  loadMore,
  actionButtons,
}) => {
  const tableBody = body.map(
    ({
      header: {
        value: headerValue,
        underlineValue: underlineHeaderValue,
        isImage: headerIsImage,
        isLink: headerIsLink,
        url: headerUrl,
      },
      data,
      disabled,
      classes,
      key,
    }) => {
      const tableHeader = (
        <td
          onClick={
            headerIsLink && headerUrl
              ? () => {
                  navigate(headerUrl)
                }
              : undefined
          }
          className={`${headerIsLink ? 'clickable' : ''} ${
            headerIsLink ? 'clickable' : ''
          }`}
        >
          {headerIsImage ? (
            <img src={headerValue} alt="Avatar" />
          ) : underlineHeaderValue ? (
            <u>{headerValue}</u>
          ) : (
            headerValue
          )}
        </td>
      )
      const tableData = data.map(
        ({value, underlineValue, isImage, isLink, url}, index) => (
          <td
            onClick={
              isLink && url
                ? () => {
                    navigate(url)
                  }
                : undefined
            }
            key={index}
            className={`${isLink ? 'clickable' : ''}`}
          >
            {isImage && typeof value === 'string' ? (
              <img src={value} alt="Avatar" />
            ) : underlineValue ? (
              <u>{value}</u>
            ) : (
              value
            )}
          </td>
        ),
      )
      return (
        <tr
          className={`${disabled ? 'text-muted' : ''} ${
            classes ? classes : ''
          }`}
          key={key}
        >
          {tableHeader}
          {tableData}
          <td className=" td-actions text-right">
            {actionButtons(key.toString())}
          </td>
        </tr>
      )
    },
  )

  return (
    <Table responsive hover>
      <TableHeaders headers={headers} />
      <tbody>
        {tableBody}
        {(page === 1 && body.length) || !loading ? null : <TableLoader />}
        {!loading && hasMore ? (
          <Button className="mb-1" onClick={loadMore}>
            More
          </Button>
        ) : null}
      </tbody>
    </Table>
  )
}

export default TableWithActions
