/* eslint-disable max-lines-per-function */
import {InvestmentData} from '_redux/bonds/all/bonds-all.types'
import {ChartProps} from 'components/common/charts/types'
import {CalendarMonths, numberRange, formatDate} from 'helpers/utils'
import {theme} from 'AppTheme'
import {getISOWeek} from 'date-fns/esm'

const now = new Date()
const currYear = now.getFullYear()
const currMonth = now.getMonth()
// const currDay = now.getDate()

const generateMonthLabels = (monthAndYearArr: string[]) => {
  return monthAndYearArr.map(str => {
    let label = ''
    switch (true) {
      case str.startsWith('01'):
        label = `${CalendarMonths[0]} ${str.slice(3)}`
        break
      case str.startsWith('02'):
        label = `${CalendarMonths[1]} ${str.slice(3)}`
        break
      case str.startsWith('03'):
        label = `${CalendarMonths[2]} ${str.slice(3)}`
        break
      case str.startsWith('04'):
        label = `${CalendarMonths[3]} ${str.slice(3)}`
        break
      case str.startsWith('05'):
        label = `${CalendarMonths[4]} ${str.slice(3)}`
        break
      case str.startsWith('06'):
        label = `${CalendarMonths[5]} ${str.slice(3)}`
        break
      case str.startsWith('07'):
        label = `${CalendarMonths[6]} ${str.slice(3)}`
        break
      case str.startsWith('08'):
        label = `${CalendarMonths[7]} ${str.slice(3)}`
        break
      case str.startsWith('09'):
        label = `${CalendarMonths[8]} ${str.slice(3)}`
        break
      case str.startsWith('10'):
        label = `${CalendarMonths[9]} ${str.slice(3)}`
        break
      case str.startsWith('11'):
        label = `${CalendarMonths[10]} ${str.slice(3)}`
        break
      default:
        label = `${CalendarMonths[11]} ${str.slice(3)}`
        break
    }
    return label
  })
}

export const getFilteredMonthsToDisplay = (
  from: {yearFrom: number; monthFrom: number; dayFrom: number},
  to: {yearTo: number; monthTo: number; dayTo: number},
): string[] => {
  const monthsToDisplay = numberRange(
    from.monthFrom,
    from.yearFrom === currYear ? currMonth + 1 : 12,
  )
    .map(num => `${num < 10 ? 0 : ''}${num + 1}.${from.yearFrom}`)
    .concat(
      numberRange(
        to.yearTo === currYear ? from.monthFrom : 0,
        to.monthTo + 1,
      ).map(num => `${num < 10 ? 0 : ''}${num + 1}.${to.yearTo}`),
    )
  return Array.from(new Set(monthsToDisplay))
}

export const transformInvestmentDataForChart = (
  investmentData: {[key: string]: InvestmentData[]},
  from: {yearFrom: number; monthFrom: number; dayFrom: number},
  to: {yearTo: number; monthTo: number; dayTo: number},
  labelBy: string,
): ChartProps => {
  let labels: string[] = []
  const investData: number[] = []
  const charityData: number[] = []
  const investBoughtData: number[] = []
  const charityBoughtData: number[] = []
  if (!Object.keys(investmentData).length) {
    return {
      labels: [],
      datasets: [],
    }
  }

  if (labelBy === 'month') {
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    labels = generateMonthLabels(filteredMonths)
    let investTotal = 0
    let charityTotal = 0
    let investBoughtTotal = 0
    let charityBoughtTotal = 0
    filteredMonths.forEach(str => {
      if (investmentData[str]) {
        investmentData[str].forEach(data => {
          if (data.investment_type === 'invest') {
            investTotal = investTotal + data.total_and_fee
            investBoughtTotal = investBoughtTotal + data.units_bought
          } else {
            charityTotal = charityTotal + data.total_and_fee
            charityBoughtTotal = charityBoughtTotal + data.units_bought
          }
        })
        investData.push(investTotal)
        charityData.push(charityTotal)
        investBoughtData.push(investBoughtTotal)
        charityBoughtData.push(charityBoughtTotal)
      } else {
        investData.push(0)
        charityData.push(0)
        investBoughtData.push(0)
        charityBoughtData.push(0)
      }
    })

    return {
      labels,
      datasets: [
        {
          label: 'Total Investments',
          backgroundColor: theme.colors.primary,
          stack: 'Stack 0',
          data: investData,
        },
        {
          label: 'Investments Units Bought',
          backgroundColor: theme.colors.lightPrimary,
          stack: 'Stack 0',
          data: investBoughtData,
        },
        {
          label: 'Total Charity',
          backgroundColor: theme.colors.darkPinkRed,
          stack: 'Stack 1',
          data: charityData,
        },
        {
          label: 'Charity Units Bought',
          backgroundColor: theme.colors.pinkRed,
          stack: 'Stack 1',
          data: charityBoughtData,
        },
      ],
    }
  }

  if (labelBy === 'week') {
    const dateFromWeek = getISOWeek(
      new Date(from.yearFrom, from.monthFrom, from.dayFrom),
    )
    const dateToWeek = getISOWeek(new Date(to.yearTo, to.monthTo, to.dayTo))
    const currentWeek = getISOWeek(now)
    const weeksToDisplay = numberRange(
      dateFromWeek,
      from.yearFrom === currYear ? currentWeek + 1 : 54,
    )
      .map(num => `Wk-${num < 10 ? 0 : ''}${num}.${from.yearFrom}`)
      .concat(
        numberRange(
          to.yearTo === currYear ? dateFromWeek : 1,
          dateToWeek + 1,
        ).map(num => `Wk-${num < 10 ? 0 : ''}${num}.${to.yearTo}`),
      )
    labels = Array.from(new Set(weeksToDisplay))
    const investMap: {[key: number]: number} = {}
    const charityMap: {[key: number]: number} = {}
    const investBoughtMap: {[key: number]: number} = {}
    const charityBoughtMap: {[key: number]: number} = {}
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    filteredMonths.forEach(str => {
      if (investmentData[str]) {
        investmentData[str].forEach(data => {
          const dateWeek = getISOWeek(formatDate(data.date))
          const weekIndex = labels.findIndex(
            label =>
              label ===
              `Wk-${dateWeek < 10 ? 0 : ''}${dateWeek}.${str.slice(3)}`,
          )
          if (data.investment_type === 'invest') {
            const prevInvestData = investMap[weekIndex] || 0
            const prevInvestBoughtData = investBoughtMap[weekIndex] || 0
            investMap[weekIndex] = prevInvestData + data.total_and_fee
            investBoughtMap[weekIndex] =
              prevInvestBoughtData + data.units_bought
          } else {
            const prevCharityData = charityMap[weekIndex] || 0
            const prevCharityBoughtData = charityBoughtMap[weekIndex] || 0
            charityMap[weekIndex] = prevCharityData + data.total_and_fee
            charityBoughtMap[weekIndex] =
              prevCharityBoughtData + data.units_bought
          }
        })
      }
    })

    numberRange(0, labels.length).forEach(num => {
      if (investMap[num]) investData.push(investMap[num])
      else investData.push(0)
      if (charityMap[num]) charityData.push(charityMap[num])
      else charityData.push(0)
      if (investBoughtMap[num]) investBoughtData.push(investBoughtMap[num])
      else investBoughtData.push(0)
      if (charityBoughtMap[num]) charityBoughtData.push(charityBoughtMap[num])
      else charityBoughtData.push(0)
    })

    return {
      labels,
      datasets: [
        {
          label: 'Total Investments',
          backgroundColor: theme.colors.primary,
          data: investData,
          stack: 'Stack 0',
        },
        {
          label: 'Total Charity',
          backgroundColor: theme.colors.darkPinkRed,
          data: charityData,
          stack: 'Stack 1',
        },
        {
          label: 'Investments Units Bought',
          backgroundColor: theme.colors.lightPrimary,
          data: investBoughtData,
          stack: 'Stack 0',
        },
        {
          label: 'Charity Units Bought',
          backgroundColor: theme.colors.pinkRed,
          data: charityBoughtData,
          stack: 'Stack 1',
        },
      ],
    }
  }
  return {
    labels: [],
    datasets: [],
  }
}
