import React, {FC} from 'react'
import {Row, Col, Card, CardTitle} from 'reactstrap'
import AdminLayout from 'components/layouts/admin.component'
import ActionBar from 'components/common/action-bar.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import {StyledDashboardChartsRow} from 'components/common/styles/row.styles'
import {BasicTable as DashboardHomeTable} from '../../common/tables'
import {
  transformBondsForTable,
  transformReferralsForTable,
  transformMembersForTable,
} from './utils'
import DashboardHomeCharts from './charts.component'
import {StyledHomeTableRow} from './styles'
import {useHomeData} from './use-home-data.hook'

const DashboardHome: FC = () => {
  // TODO: Break into smaller hooks. Are these selections repeated elsewhere?
  const {
    currentBondsPage,
    bondsTotal,
    loadMoreBonds,
    currentReferralsPage,
    loadMoreReferrals,
    currentMembersPage,
    membersPages,
    loadMoreMembers,
    bondsRequesting,
    bonds,
    referralsRequesting,
    referrals,
    referralsPages,
    membersRequesting,
    members,
  } = useHomeData()

  const transformedBonds: TableBodyData[] = transformBondsForTable(bonds)
  const transformedReferrals: TableBodyData[] = transformReferralsForTable(
    referrals,
  )
  const transformedMembers: TableBodyData[] = transformMembersForTable(members)

  return (
    <AdminLayout pageTitle="Dashboard">
      <Row>
        <ActionBar headerText="Dashboard" />
      </Row>
      <StyledDashboardChartsRow className="pt-3">
        <DashboardHomeCharts />
      </StyledDashboardChartsRow>
      <StyledHomeTableRow className="py-3">
        <Col className="px-0" sm="4">
          <Card body>
            <CardTitle>Investment opportunities</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['Bond', 'Unit price', 'Returns']}
                loading={bondsRequesting}
                page={currentBondsPage}
                body={transformedBonds}
                hasMore={currentBondsPage < (bondsTotal || 100)}
                loadMore={loadMoreBonds}
              />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle>Latest referrals</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['Order ID', 'Name', 'Status']}
                loading={referralsRequesting}
                page={currentReferralsPage}
                body={transformedReferrals}
                hasMore={currentReferralsPage < referralsPages}
                loadMore={loadMoreReferrals}
              />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle>Latest members</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['', 'Name', 'Join date']}
                loading={membersRequesting}
                page={currentMembersPage}
                body={transformedMembers}
                hasMore={currentMembersPage < membersPages}
                loadMore={loadMoreMembers}
              />
            </div>
          </Card>
        </Col>
      </StyledHomeTableRow>
    </AdminLayout>
  )
}

export default DashboardHome
