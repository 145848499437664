import React, {FC} from 'react'
import Loader from 'react-loader-spinner'
import {theme} from 'AppTheme'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

type Props = {
  height?: number
  width?: number
  type?: 'Grid' | 'Rings'
}

const LoadingSpinner: FC<Props> = ({
  height = 30,
  width = 30,
  type = 'Grid',
}) => {
  return (
    <Loader
      type={type}
      color={theme.colors.primary}
      height={height}
      width={width}
    />
  )
}

export default LoadingSpinner
