import React, {FC} from 'react'
import ActionBar from 'components/common/action-bar.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import {Button, Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import {useDispatch, useSelector} from 'react-redux'
import {
  approveWithdrawal,
  declineWithdrawal,
  fetchWithdrawalsStart,
} from '_redux/withdrawals/withdrawals.actions'
import {
  selectWithdrawalsMeta,
  selectWithdrawalsRequesting,
  selectWithdrawals,
} from '_redux/withdrawals/withdrawals.selectors'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {
  BasicTable as WithdrawalsTable,
  TableWithActions,
} from 'components/common/tables'
import {FiX, FiCheck} from 'react-icons/fi'
import {IconContext} from 'react-icons'
import {transformWithdrawalsForTable} from './utils'

const withdrawalStatus = {
  pending: 'pending',
  approved: 'approved',
  disapproved: 'disapproved',
}

const DashboardWithdrawals: FC = () => {
  const {tab, onTabClick} = useTabs('pending')
  const [
    currentWithdrawalsPage,
    loadMoreWithdrawals,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchWithdrawalsStart, {query: tab})
  const dispatch = useDispatch()
  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    setExtraQueryParams({query: lowercaseTab})

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const withdrawalsRequesting = useSelector(selectWithdrawalsRequesting)
  const {pages: withdrawalsPages} = useSelector(selectWithdrawalsMeta)
  const withdrawals = useSelector(selectWithdrawals)
  const transformedWithdrawals: TableBodyData[] = transformWithdrawalsForTable(
    withdrawals,
    withdrawalStatus[tab],
  )
  const actionButtons = (key: string) => (
    <IconContext.Provider value={{color: 'white', size: '1.4em'}}>
      <Button
        className="btn-icon mr-2"
        color="success"
        size="sm"
        type="button"
        onClick={() => {
          dispatch(approveWithdrawal(key))
        }}
      >
        <FiCheck />
      </Button>
      <Button
        className=" btn-icon"
        color="danger"
        size="sm"
        type="button"
        onClick={() => {
          dispatch(declineWithdrawal(key))
        }}
      >
        <FiX />
      </Button>
    </IconContext.Provider>
  )

  return (
    <AdminLayout pageTitle="Withdrawals">
      <Row>
        <ActionBar headerText="Withdrawals" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={['Pending', 'Approved', 'Disapproved']}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>
      {withdrawals.length ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              loaded={transformedWithdrawals.length}
              total={transformedWithdrawals.length}
            />
            <div>
              {tab.toLowerCase() === 'pending' ? (
                <TableWithActions
                  headers={[
                    '',
                    'ID',
                    'Name',
                    'Amount',
                    'Date',
                    'Type',
                    'Status',
                    '',
                  ]}
                  loading={withdrawalsRequesting}
                  page={currentWithdrawalsPage}
                  body={transformedWithdrawals}
                  hasMore={currentWithdrawalsPage < withdrawalsPages}
                  loadMore={loadMoreWithdrawals}
                  actionButtons={actionButtons}
                />
              ) : (
                <WithdrawalsTable
                  headers={[
                    '',
                    'ID',
                    'Name',
                    'Amount',
                    'Date',
                    'Type',
                    'Status',
                  ]}
                  loading={withdrawalsRequesting}
                  page={currentWithdrawalsPage}
                  body={transformedWithdrawals}
                  hasMore={currentWithdrawalsPage < withdrawalsPages}
                  loadMore={loadMoreWithdrawals}
                />
              )}
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={withdrawalsRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardWithdrawals
