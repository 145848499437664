import {
  FETCH_MEMBER_DETAILS_START,
  FETCH_MEMBER_DETAILS_SUCCESS,
  FETCH_MEMBER_DETAILS_FAILURE,
  FETCH_MEMBER_REFERRED_START,
  FETCH_MEMBER_REFERRED_SUCCESS,
  FETCH_MEMBER_REFERRED_FAILURE,
  FETCH_MEMBER_INVESTMENTS_START,
  FETCH_MEMBER_INVESTMENTS_SUCCESS,
  FETCH_MEMBER_INVESTMENTS_FAILURE,
  FETCH_MEMBER_RETURNS_START,
  FETCH_MEMBER_RETURNS_SUCCESS,
  FETCH_MEMBER_RETURNS_FAILURE,
  FETCH_MEMBER_REWARDS_START,
  FETCH_MEMBER_REWARDS_SUCCESS,
  FETCH_MEMBER_REWARDS_FAILURE,
} from '../members.constants'
import {MembersActionTypes} from '../members.types'
import {
  MemberDetail,
  MemberInvestments,
  MemberReferrals,
  MemberReturns,
  MemberRewards,
} from './members-single.types'

export function fetchMemberDetailsStart(userId: string): MembersActionTypes {
  return {
    type: FETCH_MEMBER_DETAILS_START,
    payload: userId,
  }
}

export function fetchMemberDetailsSuccess(
  data: MemberDetail,
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_DETAILS_SUCCESS,
    payload: data,
  }
}

export function fetchMemberDetailsFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBER_DETAILS_FAILURE,
  }
}
export function fetchMemberReferredStart(userId: string): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REFERRED_START,
    payload: userId,
  }
}

export function fetchMemberReferredSuccess(
  data: MemberReferrals[],
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REFERRED_SUCCESS,
    payload: data,
  }
}

export function fetchMemberReferredFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REFERRED_FAILURE,
  }
}

export function fetchMemberInvestmentsStart(
  userId: string,
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_INVESTMENTS_START,
    payload: userId,
  }
}

export function fetchMemberInvestmentsSuccess(
  data: MemberInvestments[],
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_INVESTMENTS_SUCCESS,
    payload: data,
  }
}

export function fetchMemberInvestmentsFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBER_INVESTMENTS_FAILURE,
  }
}

export function fetchMemberReturnsStart(userId: string): MembersActionTypes {
  return {
    type: FETCH_MEMBER_RETURNS_START,
    payload: userId,
  }
}

export function fetchMemberReturnsSuccess(
  data: MemberReturns[],
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_RETURNS_SUCCESS,
    payload: data,
  }
}

export function fetchMemberReturnsFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBER_RETURNS_FAILURE,
  }
}

export function fetchMemberRewardsStart(userId: string): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REWARDS_START,
    payload: userId,
  }
}

export function fetchMemberRewardsSuccess(
  data: MemberRewards[],
): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REWARDS_SUCCESS,
    payload: data,
  }
}

export function fetchMemberRewardsFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBER_REWARDS_FAILURE,
  }
}
