import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_MEMBERS_START,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  SET_MEMBERS_META,
  FETCH_LOCATION_CHART_DATA_SUCCESS,
  FETCH_LOCATION_CHART_DATA_START,
  FETCH_LOCATION_CHART_DATA_FAILURE,
} from '../members.constants'
import {
  LocationChartDataRequestingStateActions,
  MembersRequestingStateActions,
} from '../members.types'
import {
  MembersDictionary,
  MembersIdList,
  LocationData,
} from './members-all.types'
import {
  FetchMembersSuccessAction,
  SetMembersMetaAction,
  FetchLocationChartDataSuccessAction,
} from './members-all-actions.types'

export function membersById(
  state: MembersDictionary = {},
  {type, payload}: FetchMembersSuccessAction,
): MembersDictionary {
  switch (type) {
    case FETCH_MEMBERS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.members}
      }
      return state
    }
    default:
      return state
  }
}

export function allMembersIds(
  state: MembersIdList = [],
  {type, payload}: FetchMembersSuccessAction,
): MembersIdList {
  switch (type) {
    case FETCH_MEMBERS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

export function membersMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetMembersMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_MEMBERS_META:
      return payload
    default:
      return state
  }
}

export function membersRequesting(
  state = true,
  {type}: MembersRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_MEMBERS_START:
      return true
    case FETCH_MEMBERS_SUCCESS:
    case FETCH_MEMBERS_FAILURE:
      return false
    default:
      return state
  }
}

export const locationChartData = (
  state: LocationData = {
    locations: {
      africa: 0,
      americas: 0,
      asia: 0,
      australia: 0,
      europe: 0,
    },
    members: 0,
  },
  {type, payload}: FetchLocationChartDataSuccessAction,
): LocationData => {
  switch (type) {
    case FETCH_LOCATION_CHART_DATA_SUCCESS:
      return payload
    default:
      return state
  }
}

export function locationChartDataRequesting(
  state = true,
  {type}: LocationChartDataRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_LOCATION_CHART_DATA_START:
      return true
    case FETCH_LOCATION_CHART_DATA_SUCCESS:
    case FETCH_LOCATION_CHART_DATA_FAILURE:
      return false
    default:
      return state
  }
}
