import styled from 'styled-components/macro'
import {Form} from 'formik'

export const StyledForm = styled(Form)`
  a {
    color: #fff;
  }

  input::placeholder {
    font-size: ${(props): string => `${props.theme.fontSizes.small}`};
    font-weight: lighter;
  }

  .invalid-feedback {
    background-color: ${(props): string => `${props.theme.colors.pinkRed}`};
    padding: ${(props): string => `${props.theme.space.XS}`};
    margin-left: 0.2rem;
    width: 98%;
  }

  button {
    &,
    &.focus,
    &:focus {
      background-color: ${(props): string => `${props.theme.colors.primary}`};
      border-color: ${(props): string => `${props.theme.colors.primary}`};
    }

    &:active {
      background-color: ${(props): string =>
        `${props.theme.colors.primary} !important`};
      border-color: ${(props): string =>
        `${props.theme.colors.primary} !important`};
    }

    &:hover {
      color: #fff;
      background-color: ${(props): string =>
        `${props.theme.colors.darkPrimary}`};
      border-color: ${(props): string => `${props.theme.colors.darkPrimary}`};
    }

    &.disabled,
    &:disabled {
      background-color: ${(props): string =>
        `${props.theme.colors.lightPrimary}`};
      border-color: ${(props): string => `${props.theme.colors.lightPrimary}`};
    }
  }
`
