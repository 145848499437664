import React, {FC} from 'react'
import {Table} from 'reactstrap'
import TableHeaders from 'components/common/tables/table-headers.component'
import TableBody from 'components/common/tables/table-body.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import TableLoader from 'components/common/tables/table-loader.component'

type Props = {
  headers: string[]
  loading: boolean
  body: TableBodyData[]
}

const InvestorsTable: FC<Props> = ({headers, body, loading}) => {
  return (
    <Table responsive hover>
      <TableHeaders headers={headers} />
      <tbody>
        <TableBody body={body} />
        {body.length || !loading ? null : <TableLoader />}
      </tbody>
    </Table>
  )
}

export default InvestorsTable
