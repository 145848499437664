import React, {FC} from 'react'
import {Table, CustomInput} from 'reactstrap'

const SettingsTable: FC = () => {
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Functions</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Restrict regional access</td>
          <td>
            <CustomInput
              type="switch"
              id="regionalAccess"
              name="regionalAccess"
            />
          </td>
        </tr>
        <tr>
          <td>Traffic Forwarding</td>
          <td>
            <CustomInput
              type="switch"
              id="trafficForwarding"
              name="trafficForwarding"
            />
          </td>
        </tr>
        <tr>
          <td>Firewall</td>
          <td>
            <CustomInput type="switch" id="firewall" name="firewall" />
          </td>
        </tr>
        <tr>
          <td>Data Loss Prevention</td>
          <td>
            <CustomInput type="switch" id="dataLoss" name="dataLoss" />
          </td>
        </tr>
        <tr>
          <td>SLL Policy</td>
          <td>
            <CustomInput type="switch" id="sllPolicy" name="sllPolicy" />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default SettingsTable
