import {
  FETCH_BOND_SERIES_INVESTORS_SUCCESS,
  CLEAR_BOND_SERIES_INVESTORS,
  FETCH_BOND_SERIES_INVESTORS_START,
  FETCH_BOND_SERIES_INVESTORS_FAILURE,
} from '../bonds.constants'
import {
  BondSeriesInvestorsStateActions,
  BondSeriesInvestorsRequestingStateActions,
} from '../bonds.types'
import {SeriesInvestors} from './bonds-single.types'

export function bondSeriesInvestors(
  state: SeriesInvestors = {},
  action: BondSeriesInvestorsStateActions,
): SeriesInvestors {
  switch (action.type) {
    case FETCH_BOND_SERIES_INVESTORS_SUCCESS: {
      return {
        ...state,
        [action.payload.seriesId]: action.payload.investors,
      }
    }
    case CLEAR_BOND_SERIES_INVESTORS:
      return {}
    default:
      return state
  }
}

export function bondSeriesInvestorsRequesting(
  state = true,
  {type}: BondSeriesInvestorsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BOND_SERIES_INVESTORS_START:
      return true
    case FETCH_BOND_SERIES_INVESTORS_SUCCESS:
    case FETCH_BOND_SERIES_INVESTORS_FAILURE:
    case CLEAR_BOND_SERIES_INVESTORS:
      return false
    default:
      return state
  }
}
