import React, {FC} from 'react'
import {NavItem} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import {StyledHeaderNav} from './styles'

type Props = {
  isOpen: boolean
}
const HeaderNav: FC<Props> = ({isOpen}) => {
  return (
    <StyledHeaderNav visibility={isOpen ? 'visible' : 'hidden'}>
      <NavItem>
        <NavLink
          className="pl-0 nav-link text-black-50"
          to="/dashboard"
          exact
          activeClassName="active-nav-link"
        >
          Dashboard
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/associate-mgmt"
          exact
          activeClassName="active-nav-link"
        >
          Associate Management
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/referrals"
          exact
          activeClassName="active-nav-link"
        >
          Referrals
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/rewards"
          exact
          activeClassName="active-nav-link"
        >
          Rewards
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/returns"
          exact
          activeClassName="active-nav-link"
        >
          Returns
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/withdrawals"
          exact
          activeClassName="active-nav-link"
        >
          Withdrawals
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/reports-analytics"
          exact
          activeClassName="active-nav-link"
        >
          Reports &amp; Analytics
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/bond-mgmt"
          exact
          activeClassName="active-nav-link"
        >
          Bond Management
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/settings-access"
          exact
          activeClassName="active-nav-link"
        >
          System &amp; Access
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/chat"
          exact
          activeClassName="active-nav-link"
        >
          Communication Chat
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link text-black-50"
          to="/dashboard/adverts"
          exact
          activeClassName="active-nav-link"
        >
          Adverts
        </NavLink>
      </NavItem>
    </StyledHeaderNav>
  )
}

export default HeaderNav
