import styled from 'styled-components/macro'
import {Container} from 'reactstrap'

export const StyledAdminLayoutContainer = styled(Container)`
  max-width: 95vw;

  .clickable {
    cursor: pointer;
  }

  .absolute-center-right {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
