import TableHeaders from 'components/common/tables/table-headers.component'
import TableLoader from 'components/common/tables/table-loader.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import {navigate} from 'helpers/utils'
import React, {SetStateAction} from 'react'
import {IconContext} from 'react-icons'
import {FiChevronDown, FiChevronRight} from 'react-icons/fi'
import {Button, Table} from 'reactstrap'

const dropdownIconValues = {
  size: '1.4em',
  style: {verticalAlign: 'middle'},
}
interface Props {
  activeRowState: [string, React.Dispatch<SetStateAction<string>>]
  headers: string[]
  loading: boolean
  page: number
  body: TableBodyData[]
  hasMore: boolean
  loadMore(): void
}

const TableWithDropdown: React.FC<Props> = ({
  activeRowState,
  headers,
  body,
  loading,
  page,
  hasMore,
  loadMore,
}) => {
  const [activeRow, setActiveRow] = activeRowState
  const renderRowItemCaret = (rowId: string | number) => {
    let caret = <FiChevronDown />
    if (activeRow === rowId) {
      caret = <FiChevronRight />
    }
    return (
      <IconContext.Provider value={dropdownIconValues}>
        {caret}
      </IconContext.Provider>
    )
  }

  const handleRowItemCaretClick = (rowId: string | number) => {
    if (!activeRow || activeRow !== rowId) {
      return setActiveRow(String(rowId))
    }
    return setActiveRow('')
  }

  const tableBody = body.flatMap(
    ({
      header: {
        value: headerValue,
        underlineValue: underlineHeaderValue,
        isImage: headerIsImage,
        isLink: headerIsLink,
        url: headerUrl,
      },
      data,
      key,
    }) => {
      const isRowActive = (rowIdx: string, isStatusOpen = false) =>
        activeRow.includes(String(rowIdx)) && !isStatusOpen
          ? 'text-danger'
          : 'text-muted'

      const tableHeader = (
        <td
          onClick={
            headerIsLink && headerUrl
              ? () => {
                  navigate(headerUrl)
                }
              : undefined
          }
          className={`${headerIsLink ? 'clickable' : ''} 
          `}
        >
          {headerIsImage ? (
            <img src={headerValue} alt="Avatar" />
          ) : underlineHeaderValue ? (
            <u>{headerValue}</u>
          ) : (
            headerValue
          )}
        </td>
      )

      const tableData = data.map(({value, url}, index) => (
        <td
          onClick={() => {
            navigate(url)
          }}
          key={index}
          className="clickable"
        >
          <u>{value}</u>
        </td>
      ))
      tableData.push(
        <td
          key={tableData.length}
          onClick={() => {
            handleRowItemCaretClick(key)
          }}
        >
          {renderRowItemCaret(key)}
        </td>,
      )
      const itemRows = [
        <tr className={`${isRowActive(String(key))}`} key={key}>
          {tableHeader}
          {tableData}
        </tr>,
      ]

      return itemRows
    },
  )

  return (
    <Table responsive hover>
      <TableHeaders headers={headers} />
      <tbody>
        {tableBody}
        {(page === 1 && body.length) || !loading ? null : <TableLoader />}
        {!loading && hasMore ? (
          <Button className="mb-1" onClick={loadMore}>
            More
          </Button>
        ) : null}
      </tbody>
    </Table>
  )
}

export default TableWithDropdown
