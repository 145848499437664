import {AxiosResponse} from 'axios'
import {getCookieValue, toFormData} from 'helpers/utils'
import {AddBondFormProps} from 'components/dashboard/bond-mgmt/types'
import {
  EditBondDetailsFormProps,
  EditBondSeriesFormProps,
  CreateBondSeriesFormProps,
} from 'components/dashboard/bond-profile/types'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {
  FETCH_BONDS_ENDPOINT,
  FETCH_BOND_ENDPOINT,
  FETCH_BONDS_SUMMARY_ENDPOINT,
  ADD_BOND_ENDPOINT,
  EDIT_BOND_ENDPOINT,
  EDIT_SERIES_ENDPOINT,
  ADD_SERIES_ENDPOINT,
  FETCH_SERIES_INVESTORS_ENDPOINT,
  EDIT_SERIES_STATUS_ENDPOINT,
  FETCH_INVESTMENT_CHART_ENDPOINT,
  DELETE_BOND_ENDPOINT,
} from '../endpoints'

class BondsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchBonds(page: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_BONDS_ENDPOINT}?page=${page}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchBondsSummary(): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_BONDS_SUMMARY_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async addBond(data: AddBondFormProps): Promise<AxiosResponse<any>> {
    const form = toFormData({image: '', ...data})
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: ADD_BOND_ENDPOINT,
      data: form,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async deleteBond(id: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${DELETE_BOND_ENDPOINT}?id=${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
  async fetchBond(id: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_BOND_ENDPOINT}?id=${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async editBondDetails(
    data: EditBondDetailsFormProps & {
      bond_id: number
      image: string | File
      status: number
    },
  ): Promise<AxiosResponse<any>> {
    const form = toFormData({...data})
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: EDIT_BOND_ENDPOINT,
      data: form,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async editBondSeries(
    data: EditBondSeriesFormProps & {
      bond_id: string
      id: number
      series: number
      status: number
    },
  ): Promise<AxiosResponse<any>> {
    const form = toFormData({...data, video_link: data.video_link || undefined})
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: EDIT_SERIES_ENDPOINT,
      data: form,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async createBondSeries(
    data: CreateBondSeriesFormProps & {
      bond_id: string
      series: number
      status: number
    },
  ): Promise<AxiosResponse<any>> {
    const form = toFormData({...data, video_link: data.video_link || undefined})
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: ADD_SERIES_ENDPOINT,
      data: form,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchBondSeriesInvestors(id: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_SERIES_INVESTORS_ENDPOINT}/${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async setBondSeriesStatus(id: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: `${EDIT_SERIES_STATUS_ENDPOINT}?id=${id}`,
      options: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async getInvestmentData(): Promise<AxiosResponse> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_INVESTMENT_CHART_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default BondsService
