import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import log from 'loglevel'
import MembersService from 'services/api/members/service'
import {
  FetchMemberDetailsStartAction,
  FetchMemberInvestmentsStartAction,
  FetchMemberReferredStartAction,
  FetchMemberReturnsStartAction,
  FetchMemberRewardsStartAction,
} from './members-single-actions.types'
import {
  fetchMemberDetailsSuccess,
  fetchMemberDetailsFailure,
  fetchMemberInvestmentsSuccess,
  fetchMemberInvestmentsFailure,
  fetchMemberReferredSuccess,
  fetchMemberReferredFailure,
  fetchMemberReturnsSuccess,
  fetchMemberReturnsFailure,
  fetchMemberRewardsSuccess,
  fetchMemberRewardsFailure,
} from './members-single.actions'

export function* fetchMemberDetailsStartSaga({
  payload: userId,
}: FetchMemberDetailsStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data},
    } = yield call([membersService, 'fetchMemberDetail'], userId)
    yield put(fetchMemberDetailsSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchMemberDetailsFailure())
  }
}

export function* fetchMemberInvestmentsStartSaga({
  payload: userId,
}: FetchMemberInvestmentsStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data},
    } = yield call([membersService, 'fetchMemberInvestments'], userId)
    yield put(fetchMemberInvestmentsSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchMemberInvestmentsFailure())
  }
}

export function* fetchMemberReferredStartSaga({
  payload: userId,
}: FetchMemberReferredStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data},
    } = yield call([membersService, 'fetchMemberReferred'], userId)
    yield put(fetchMemberReferredSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchMemberReferredFailure())
  }
}

export function* fetchMemberReturnsStartSaga({
  payload: userId,
}: FetchMemberReturnsStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data},
    } = yield call([membersService, 'fetchMemberReturns'], userId)
    yield put(fetchMemberReturnsSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchMemberReturnsFailure())
  }
}

export function* fetchMemberRewardsStartSaga({
  payload: userId,
}: FetchMemberRewardsStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data},
    } = yield call([membersService, 'fetchMemberRewards'], userId)
    yield put(fetchMemberRewardsSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchMemberRewardsFailure())
  }
}
