import React from 'react'
import {FormikProps} from 'formik'
import {StyledForm} from 'components/common/styles/base-form.styles'
import {FormGroup, Label} from 'reactstrap'
import {Input, Submit} from 'formstrap'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {EditBondDetailsFormProps} from './types'

const EditBondDetailsForm: React.FC<FormikProps<EditBondDetailsFormProps>> = ({
  isValidating,
  isSubmitting,
}) => (
  <StyledForm>
    <FormGroup>
      <Label>Name</Label>
      <Input
        name="name"
        type="text"
        placeholder="Name"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup>
      <Label>Location</Label>
      <Input
        name="location"
        type="text"
        placeholder="Location"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup>
      <Label>Description</Label>
      <Input
        name="description"
        type="textarea"
        rows={8}
        placeholder="Description"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup className="mb-0">
      <Submit withLoading>Submit</Submit>
      {!isValidating && isSubmitting ? (
        <div className="text-center pt-3 pb-1">
          <LoadingSpinner />
        </div>
      ) : null}
    </FormGroup>
  </StyledForm>
)

export default EditBondDetailsForm
