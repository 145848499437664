import {theme} from 'AppTheme'
import ActionBar from 'components/common/action-bar.component'
import {StyledFixedTableRow} from 'components/common/styles/row.styles'
import {BasicTable} from 'components/common/tables'
import TableFilters from 'components/common/tables/table-filter.component'
import AdminLayout from 'components/layouts/admin.component'
import React, {FC} from 'react'
import {IconContext} from 'react-icons'
import {FiEdit, FiSave} from 'react-icons/fi'
import {Card, CardTitle, Col, Row} from 'reactstrap'
import AccessLevelTable from './access-level-table.component'
import SettingsTable from './settings-table.component'
import {CustomButton, StyledSettingsTableRow, TextArea} from './styles'
import SystemTable from './system-table.component'

const editIconValues = {
  color: theme.colors.primary,
  size: '1.4em',
  style: {verticalAlign: 'middle', marginBottom: '4px'},
}

const SettingsAccess: FC = () => {
  return (
    <AdminLayout pageTitle="Settings & Access">
      <Row>
        <ActionBar headerText="Settings & Access" />
      </Row>
      <StyledSettingsTableRow className="py-3">
        <Col className="px-0" sm="3">
          <Card body>
            <CardTitle className="font-weight-bold">Settings</CardTitle>
            <div>
              <SettingsTable />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="5">
          <Card body>
            <CardTitle className="font-weight-bold">
              <span>Access level</span>
              <IconContext.Provider value={editIconValues}>
                <div
                  role="button"
                  // onClick={toggleOpenEditModal}
                  className="d-inline text-secondary float-right"
                >
                  <span className="pr-2">Edit</span>
                  <FiEdit />
                </div>
              </IconContext.Provider>
            </CardTitle>
            <div>
              <AccessLevelTable />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle className="font-weight-bold">
              <span>System</span>
              <IconContext.Provider value={editIconValues}>
                <div
                  role="button"
                  // onClick={toggleOpenEditModal}
                  className="d-inline text-secondary float-right"
                >
                  <span className="pr-2">Backup now</span>
                  <FiSave />
                </div>
              </IconContext.Provider>
            </CardTitle>
            <div>
              <SystemTable />
            </div>
          </Card>
        </Col>
      </StyledSettingsTableRow>
      <StyledFixedTableRow className="py-3">
        <Col sm="12" className="border rounded">
          <div className="font-weight-bold py-3 border-bottom">
            System Message
          </div>
          <TableFilters
            inputComponent={
              <>
                <TextArea placeholder="Type Message Here" />
                <CustomButton>Send to all</CustomButton>
                &nbsp; &nbsp;
                <CustomButton color="danger">Send to selected</CustomButton>
              </>
            }
            loaded={0}
            total={0}
          />
          <div>
            <BasicTable
              headers={[
                '',
                '',
                'ID',
                'Name',
                'Membership',
                'Location',
                'Status',
                'Join Date',
                'Referrals',
                'Units',
                'Amount',
              ]}
              loading={false}
              page={1}
              body={[]}
              hasMore={false}
              loadMore={() => {
                console.log('no more data')
              }}
            />
          </div>
        </Col>
      </StyledFixedTableRow>
    </AdminLayout>
  )
}

export default SettingsAccess
