import {AxiosResponse} from 'axios'
import {getCookieValue} from 'helpers/utils'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {
  FETCH_MEMBERS_ENDPOINT,
  MEMBER_DETAIL_ENDPOINT,
  MEMBER_INVESTMENTS_ENDPOINT,
  MEMBER_REFERRED_ENDPOINT,
  FETCH_LOCATION_CHART_ENDPOINT,
  MEMBER_RETURNS_ENDPOINT,
  MEMBER_REWARDS_ENDPOINT,
} from '../endpoints'

class MembersService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchMembers(page: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_MEMBERS_ENDPOINT}?page=${page}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchMemberDetail(userId: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${MEMBER_DETAIL_ENDPOINT}${userId}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchMemberInvestments(userId: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${MEMBER_INVESTMENTS_ENDPOINT}${userId}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchMemberReferred(userId: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${MEMBER_REFERRED_ENDPOINT}${userId}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchMemberReturns(userId: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${MEMBER_RETURNS_ENDPOINT}${userId}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchMemberRewards(userId: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${MEMBER_REWARDS_ENDPOINT}${userId}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async getLocationData(): Promise<AxiosResponse> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_LOCATION_CHART_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default MembersService
