import React, {StrictMode, FC} from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import {Provider} from 'react-redux'
import store from '_redux/root-reducer'
import 'picturefill'
import 'picturefill/dist/plugins/mutation/pf.mutation.min'
import 'bootstrap/dist/css/bootstrap.css'

const Root: FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root'),
)
