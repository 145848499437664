import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {
  FETCH_REFERRALS_START,
  FETCH_REFERRED_USERS_START,
  FETCH_REFERRAL_CHART_DATA_START,
  SEARCH_REFERRED_USERS_START,
} from './referrals.constants'
import {
  FetchReferralsStartSaga,
  FetchReferredUsersStartSaga,
  FetchReferralChartDataStartSaga,
  SearchReferredUsersStartSaga,
} from './referrals.sagas'

export function* onFetchReferralsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_REFERRALS_START, FetchReferralsStartSaga)
}

export function* onFetchReferredUsersStart(): SagaIterator<void> {
  yield takeLatest(FETCH_REFERRED_USERS_START, FetchReferredUsersStartSaga)
}

export function* onFetchReferralDataStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_REFERRAL_CHART_DATA_START,
    FetchReferralChartDataStartSaga,
  )
}

export function* onSearchReferredUsersStart(): SagaIterator<void> {
  yield takeLatest(SEARCH_REFERRED_USERS_START, SearchReferredUsersStartSaga)
}

export function* referralsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchReferralsStart),
    call(onFetchReferredUsersStart),
    call(onFetchReferralDataStart),
    call(onSearchReferredUsersStart),
  ])
}
