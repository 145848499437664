import {TableBodyData} from 'components/common/types/table-body.types'
import {
  MemberDetail,
  MemberInvestments,
  MemberReferrals,
  MemberReturns,
  MemberRewards,
} from '_redux/members/single/members-single.types'
import {format} from 'date-fns'
import {formatDate} from 'helpers/utils'

export const transformDetailsForTable = (
  details: MemberDetail[],
): TableBodyData[] => {
  return details.map(detail => ({
    key: detail.id,
    header: {
      value: detail?.id.toString() ?? '',
    },
    data: [
      // TODO: Location and funds not included
      {value: 'Ashford, United Kingdom'},
      {value: detail?.status ?? '-'},
      {value: detail?.email ?? '-'},
      {value: detail?.level ?? '-'},
      {value: '3'},
      {value: detail?.join_date ?? '-'},
      {
        value: detail?.reffered_by ?? '-',
        isLink: true,
        url: `/dashboard/members/${detail?.reffered_by_id}`,
        underlineValue: true,
      },
    ],
  }))
}

export const transformInvestmentsForTable = (
  investments: MemberInvestments[],
): TableBodyData[] => {
  return investments.map(investment => ({
    key: investment.id,
    header: {
      value: investment?.name ?? '-',
      url: `/dashboard/bond-profile/${investment.id}`,
      isLink: true,
      underlineValue: true,
    },
    data: [
      {
        value: '',
      },
      {value: investment?.location ?? '-'},
      {value: investment?.active ?? '-'},
      {value: investment?.start_date ?? '-'},
      {value: investment?.end_date ?? '-'},
      {value: investment?.unit_price ?? '-'},
      {value: investment?.units ?? '-'},
      {value: investment?.returns ?? '-'},
    ],
  }))
}

export const transformReferralsForTable = (
  referrals: MemberReferrals[],
): TableBodyData[] => {
  return referrals.map(referred => ({
    key: referred?.id ?? '',
    header: {
      value: referred?.id?.toString() ?? 'n/a',
    },
    data: [
      {
        value: referred?.name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${referred.id}`,
      },
      {value: referred?.level ?? '-'},
      {value: referred?.location ?? '-'},
      {value: referred?.status ?? '-'},
      {value: format(formatDate(referred?.joined_date), 'd MMM YYY') || '-'},
      {value: referred?.refferals ?? '-'},
      {value: referred?.bonds ?? '-'},
    ],
    disabled: true,
  }))
}

export const transformReturnsForTable = (
  returns: MemberReturns[],
): TableBodyData[] => {
  return returns.map((singleReturn, index) => ({
    key: `${singleReturn?.reward_type}${index}` ?? '',
    header: {
      value: singleReturn?.date || '-',
    },
    data: [
      {
        value: singleReturn?.description || singleReturn?.reward_type || '-',
      },
      {value: singleReturn.series?.name ?? '-'},
      {value: singleReturn?.series_phrase ?? '-'},
      {
        value: singleReturn?.['return']
          ? `£${singleReturn?.['return'].toLocaleString()}`
          : '-',
      },
      {
        value:
          singleReturn?.withdraw_amount === 0
            ? singleReturn.withdraw_amount
            : `£${singleReturn.withdraw_amount.toLocaleString()}`,
      },
    ],
    classes: singleReturn?.description
      ? 'bg-success text-white'
      : 'bg-danger text-white',
  }))
}

export const transformRewardsForTable = (
  rewards: MemberRewards[],
): TableBodyData[] => {
  return rewards.map((reward, index) => ({
    key: `${reward?.reward_type}${index}` ?? '',
    header: {
      value: reward?.date || '-',
    },
    data: [
      {
        value: reward?.description || reward?.reward_type || '-',
      },
      {value: reward?.unit_donated ?? '-'},
      {value: reward?.reffered_user?.name ?? '-'},
      {value: reward.series?.name ?? '-'},
      {
        value: reward?.['return']
          ? `£${reward?.['return'].toLocaleString()}`
          : '-',
      },
      {
        value:
          reward?.withdraw_amount === 0
            ? reward.withdraw_amount
            : `£${reward.withdraw_amount.toLocaleString()}`,
      },
    ],
    classes: reward?.description
      ? 'bg-success text-white'
      : 'bg-danger text-white',
  }))
}
