import React from 'react'
import {FormikProps} from 'formik'
import {Input, Submit} from 'formstrap'
import {FormGroup, Label} from 'reactstrap'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {StyledForm} from 'components/common/styles/base-form.styles'
import {LoginFormProps} from './types'

type Props = {
  onClickForgotPassword(): void
}

const LoginForm: React.FC<FormikProps<LoginFormProps> & Props> = ({
  isValidating,
  isSubmitting,
  onClickForgotPassword,
}) => (
  <StyledForm>
    <FormGroup>
      <Label className="text-white">Email</Label>
      <Input
        name="email"
        type="email"
        className="form-control-lg"
        placeholder="Email address"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup>
      <Label className="text-white">Password</Label>
      <Input
        name="password"
        type="password"
        className="form-control-lg"
        placeholder="Password"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup className="mb-0">
      <Submit size="lg" block withLoading>
        Login
      </Submit>
      {!isValidating && isSubmitting ? (
        <div className="text-center pt-3 pb-2">
          <LoadingSpinner />
        </div>
      ) : null}
    </FormGroup>
    <FormGroup>
      <span
        onClick={onClickForgotPassword}
        className="text-white font-weight-bold"
      >
        Forgot password?
      </span>
    </FormGroup>
  </StyledForm>
)

export default LoginForm
