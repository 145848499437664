import React, {FC} from 'react'
import {Row, Container} from 'reactstrap'
import Logo from 'assets/images/logo_with_tagline.png'
import LogoWebp from 'assets/images/logo_with_tagline.webp'
import LoadingSpinner from './loading-spinner.component'

const SplashScreen: FC = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center h-100">
      <Row className="w-50 mx-auto">
        <picture className="text-center">
          <source srcSet={LogoWebp} type="image/webp" />
          <source srcSet={Logo} type="image/png" />
          <img src={Logo} alt="SoaRising" className="w-75 mx-auto" />
        </picture>
      </Row>
      <Row className="w-50 mx-auto">
        <div className="mx-auto pt-5">
          <LoadingSpinner />
        </div>
      </Row>
    </Container>
  )
}

export default SplashScreen
