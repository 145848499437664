import TableBody from 'components/common/tables/table-body.component'
import TableHeaders from 'components/common/tables/table-headers.component'
import TableLoader from 'components/common/tables/table-loader.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import React, {FC, useCallback, useEffect, useMemo} from 'react'
import {FiTrash} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Table} from 'reactstrap'
import {deleteAdminStart, fetchAdminsStart} from '_redux/admins/admins.actions'
import {
  selectAdmins,
  selectAdminsRequesting,
} from '_redux/admins/admins.selectors'
import {Admin} from '_redux/admins/admins.types'

const transformToAdminTableBody = (
  allAdminUsers: Array<Admin>,
  deleteAdmin: (id: number) => void,
) =>
  allAdminUsers.map(adminUser => ({
    key: adminUser?.id ?? '',
    header: {
      value: '',
    },
    data: [
      {
        isLink: true,
        url: `/dashboard/members/${adminUser.id}`,
        value: adminUser?.name ?? '-',
        underlineValue: true,
      },
      {value: adminUser.level ?? '-'},
      {
        value: (
          <Button
            color="danger"
            size="sm"
            type="button"
            onClick={() => deleteAdmin(adminUser.id)}
          >
            <FiTrash />
          </Button>
        ),
      },
    ],
  }))

const AccessLevelTable: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAdminsStart())
  }, [dispatch])

  const loading = useSelector(selectAdminsRequesting)
  const adminUsers = useSelector(selectAdmins)

  const handleDeleteAdmin = useCallback(
    (id: number) => {
      dispatch(deleteAdminStart(id))
    },
    [dispatch],
  )

  const body: Array<TableBodyData> = useMemo(
    () => transformToAdminTableBody(adminUsers, handleDeleteAdmin),
    [adminUsers, handleDeleteAdmin],
  )

  return (
    <Table responsive hover>
      <TableHeaders headers={['Name', 'Status', '']} />
      <tbody>
        <TableBody body={body} />
        {body.length || !loading ? null : <TableLoader />}
      </tbody>
    </Table>
  )
}

export default AccessLevelTable
