/* eslint-disable no-new */
import React, {useRef, useEffect, FC} from 'react'
import Chart from 'chart.js'
import {ChartProps} from './types'

const VerticalBarChart: FC<ChartProps> = ({datasets, labels, isUpdate}) => {
  const ref = useRef<HTMLCanvasElement | null>(null)
  const chart = useRef<Chart | null>(null)
  useEffect(() => {
    const getMax = () => Math.max(...datasets.flatMap(dataset => dataset.data))
    if (ref.current && datasets.length && !isUpdate) {
      chart.current = new Chart(ref.current, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: datasets?.[0]?.stack !== undefined,
              },
            ],
            yAxes: [
              {
                stacked: datasets?.[0]?.stack !== undefined,
                ticks: {
                  suggestedMax: (getMax() || 0) + 20,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 5,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
        },
      })
    }
  }, [datasets, labels, isUpdate])

  useEffect(() => {
    if (chart.current && isUpdate) {
      chart.current.data.labels = labels
      chart.current.data.datasets = datasets
      chart.current.update()
    }
  }, [datasets, isUpdate, labels])
  return (
    <canvas
      ref={ref}
      width="400"
      height="400"
      aria-label="Bar Chart displaying data"
      role="img"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  )
}
export default VerticalBarChart
