import log from 'loglevel'
import {normalize} from 'normalizr'
import {toast} from 'react-toastify'
import {SagaIterator} from 'redux-saga'
import {call, put} from 'redux-saga/effects'
import AdminsService from 'services/api/admins/service'
import * as schema from '_redux/schema'
import {
  AddAdminStartAction,
  DeleteAdminStartAction,
} from './admins-actions.types'
import {
  fetchAdminsFailure,
  fetchAdminsStart,
  fetchAdminsSuccess,
  updateAdmins,
} from './admins.actions'

export function* fetchAdminsStartSaga(): SagaIterator<void> {
  const adminsService = new AdminsService()
  try {
    const {
      data: {data: admins},
    } = yield call([adminsService, 'fetchAdmins'])
    const normalizedAdmins = yield call(normalize, admins, schema.arrayOfAdmins)
    yield put(fetchAdminsSuccess(normalizedAdmins))
  } catch (error) {
    log.warn(error)
    yield put(fetchAdminsFailure())
  }
}

export function* saveNewAdminStartSaga({
  payload: id,
}: AddAdminStartAction): SagaIterator<void> {
  const adminsService = new AdminsService()
  try {
    yield call([adminsService, 'addAdmin'], id)
    yield put(fetchAdminsStart())
    yield call(toast.success, 'Admin created successfully')
  } catch (error) {
    log.warn(error)
    yield put(fetchAdminsFailure())
  }
}

export function* deleteAdminStartSaga({
  payload: id,
}: DeleteAdminStartAction): SagaIterator<void> {
  const adminsService = new AdminsService()
  try {
    yield call([adminsService, 'deleteAdmin'], `${id}`)
    yield put(updateAdmins(id))
    yield call(toast.success, 'Admin deleted successfully')
  } catch (error) {
    log.warn(error)
    yield put(fetchAdminsFailure())
  }
}
