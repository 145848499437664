export const FETCH_BONDS_START = 'FETCH_BONDS_START'
export const FETCH_BONDS_SUCCESS = 'FETCH_BONDS_SUCCESS'
export const FETCH_BONDS_FAILURE = 'FETCH_BONDS_FAILURE'
export const SET_BONDS_META = 'SET_BONDS_META'

export const FETCH_BOND_START = 'FETCH_BOND_START'
export const FETCH_BOND_SUCCESS = 'FETCH_BOND_SUCCESS'
export const FETCH_BOND_FAILURE = 'FETCH_BOND_FAILURE'

export const FETCH_BONDS_SUMMARY_START = 'FETCH_BONDS_SUMMARY_START'
export const FETCH_BONDS_SUMMARY_SUCCESS = 'FETCH_BONDS_SUMMARY_SUCCESS'
export const FETCH_BONDS_SUMMARY_FAILURE = 'FETCH_BONDS_SUMMARY_FAILURE'

export const DELETE_BOND_START = 'DELETE_BOND_START'
export const DELETE_BOND_SUCCESS = 'DELETE_BOND_SUCCESS'
export const ADD_BOND_START = 'ADD_BOND_START'
export const EDIT_BOND_DETAILS_START = 'EDIT_BOND_DETAILS_START'
export const EDIT_BOND_SERIES_START = 'EDIT_BOND_SERIES_START'
export const CREATE_BOND_SERIES_START = 'CREATE_BOND_SERIES_START'

export const FETCH_BOND_SERIES_INVESTORS_START =
  'FETCH_BOND_SERIES_INVESTORS_START'
export const FETCH_BOND_SERIES_INVESTORS_SUCCESS =
  'FETCH_BOND_SERIES_INVESTORS_SUCCESS'
export const FETCH_BOND_SERIES_INVESTORS_FAILURE =
  'FETCH_BOND_SERIES_INVESTORS_FAILURE'
export const CLEAR_BOND_SERIES_INVESTORS = 'CLEAR_BOND_SERIES_INVESTORS'

export const SET_BOND_SERIES_STATUS_START = 'SET_BOND_SERIES_STATUS_START'

export const FETCH_INVESTMENT_CHART_DATA_START =
  'FETCH_INVESTMENT_CHART_DATA_START'
export const FETCH_INVESTMENT_CHART_DATA_SUCCESS =
  'FETCH_INVESTMENT_CHART_DATA_SUCCESS'
export const FETCH_INVESTMENT_CHART_DATA_FAILURE =
  'FETCH_INVESTMENT_CHART_DATA_FAILURE'
