import format from 'date-fns/format'
import {formatDate, titleCaseName} from 'helpers/utils'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from 'components/common/types/table-body.types'
import {ReferredUser} from '_redux/referrals/referrals.types'

export const transformReferredUsersForTable = (
  referredUsers: ReferredUser[],
  tab: string | undefined,
): TableBodyData[] => {
  const filteredReferrals = tab
    ? referredUsers.filter(user => user.Status === tab)
    : referredUsers

  return filteredReferrals.map(user => ({
    key: user?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },
    data: [
      {value: user?.id ?? '-'},
      {
        value: user?.Name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${user.user_id}`,
      },
      {value: user?.Membership ?? '-'},
      {value: user?.Location ?? '-'},
      {value: titleCaseName(user?.Status) ?? '-'},
      {
        value: format(
          // Date comes in dd.mm.yyyy format instead of dd-mm-yy
          formatDate(user?.Date_joined),
          'd MMMM yyyy',
        ),
      },
      {value: user?.Referrals_by ?? '-'},
      {value: user?.Referrals ?? '-'},
      {
        value:
          user?.Amount === 0 ? user.Amount : `£${user.Amount.toLocaleString()}`,
      },
    ],
    disabled: user?.Status !== 'Active',
  }))
}
