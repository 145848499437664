export const FETCH_ADMINS_START = 'FETCH_ADMINS_START'
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS'
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE'

export const SET_ADMINS_META = 'SET_ADMINS_META'

export const FETCH_ADMIN_START = 'FETCH_ADMIN_START'
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS'
export const FETCH_ADMIN_FAILURE = 'FETCH_ADMIN_FAILURE'

export const ADD_ADMIN_START = 'ADD_ADMIN_START'
export const EDIT_ADMIN_START = 'EDIT_ADMIN_START'
export const DELETE_ADMIN_START = 'DELETE_ADMIN_START'
export const UPDATE_ADMINS = 'UPDATE_ADMINS'
