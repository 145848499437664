import styled from 'styled-components/macro'
import {Nav} from 'reactstrap'

export const StyledHeaderNav = styled(Nav)<{visibility: string}>`
  visibility: ${(props): string => `${props.visibility}`};
  .active-nav-link {
    color: #343a40 !important;
    font-weight: bold;
  }
`
