import {
  FETCH_MEMBER_DETAILS_START,
  FETCH_MEMBER_INVESTMENTS_START,
  FETCH_MEMBER_REFERRED_START,
  FETCH_MEMBER_DETAILS_FAILURE,
  FETCH_MEMBER_INVESTMENTS_FAILURE,
  FETCH_MEMBER_REFERRED_FAILURE,
  FETCH_MEMBER_DETAILS_SUCCESS,
  FETCH_MEMBER_INVESTMENTS_SUCCESS,
  FETCH_MEMBER_REFERRED_SUCCESS,
  FETCH_MEMBER_RETURNS_START,
  FETCH_MEMBER_RETURNS_FAILURE,
  FETCH_MEMBER_RETURNS_SUCCESS,
  FETCH_MEMBER_REWARDS_START,
  FETCH_MEMBER_REWARDS_SUCCESS,
  FETCH_MEMBER_REWARDS_FAILURE,
} from '../members.constants'
import {CurrentMemberStateActions} from '../members.types'
import {CurrentMemberState} from './members-single.types'

// eslint-disable-next-line complexity
export function currentMember(
  state: CurrentMemberState = {
    details: {requesting: false, data: {}},
    investments: {requesting: false, data: []},
    referred: {requesting: false, data: []},
    returns: {requesting: false, data: []},
    rewards: {requesting: false, data: []},
  },
  action: CurrentMemberStateActions,
): CurrentMemberState {
  switch (action.type) {
    case FETCH_MEMBER_DETAILS_START:
      return {...state, details: {requesting: true, data: {}}}
    case FETCH_MEMBER_INVESTMENTS_START:
      return {...state, investments: {requesting: true, data: []}}
    case FETCH_MEMBER_REFERRED_START:
      return {...state, referred: {requesting: true, data: []}}
    case FETCH_MEMBER_RETURNS_START:
      return {...state, returns: {requesting: true, data: []}}
    case FETCH_MEMBER_REWARDS_START:
      return {...state, rewards: {requesting: true, data: []}}
    case FETCH_MEMBER_DETAILS_FAILURE:
      return {...state, details: {requesting: false, data: {}}}
    case FETCH_MEMBER_INVESTMENTS_FAILURE:
      return {...state, investments: {requesting: false, data: []}}
    case FETCH_MEMBER_REFERRED_FAILURE:
      return {...state, referred: {requesting: false, data: []}}
    case FETCH_MEMBER_RETURNS_FAILURE:
      return {...state, returns: {requesting: false, data: []}}
    case FETCH_MEMBER_REWARDS_FAILURE:
      return {...state, rewards: {requesting: false, data: []}}
    case FETCH_MEMBER_DETAILS_SUCCESS:
      return {...state, details: {requesting: false, data: action.payload}}
    case FETCH_MEMBER_INVESTMENTS_SUCCESS:
      return {...state, investments: {requesting: false, data: action.payload}}
    case FETCH_MEMBER_REFERRED_SUCCESS:
      return {
        ...state,
        referred: {requesting: false, data: action.payload},
      }
    case FETCH_MEMBER_RETURNS_SUCCESS:
      return {
        ...state,
        returns: {requesting: false, data: action.payload},
      }
    case FETCH_MEMBER_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: {requesting: false, data: action.payload},
      }
    default:
      return state
  }
}
