import React, {FC} from 'react'
import AdminLayout from 'components/layouts/admin.component'
import {Row, Nav} from 'reactstrap'
import ActionBar from 'components/common/action-bar.component'
import {
  StyledNavLinkRow,
  StyledDashboardChartsRow,
} from 'components/common/styles/row.styles'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import DashboardReportsAnalyticsCharts from './charts.component'

const DashboardReportsAnalytics: FC = () => {
  const {tab, onTabClick} = useTabs('investments')
  return (
    <AdminLayout pageTitle="Reports & Analytics">
      <Row>
        <ActionBar headerText="Reports & Analytics" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={['Investments', 'Funds', 'Returns', 'Rewards', 'Referrals']}
            currentTab={tab}
            toggleCurrentTab={onTabClick}
          />
        </Nav>
      </StyledNavLinkRow>
      <StyledDashboardChartsRow className="pt-3">
        <DashboardReportsAnalyticsCharts />
      </StyledDashboardChartsRow>
    </AdminLayout>
  )
}

export default DashboardReportsAnalytics
