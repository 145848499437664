import {useState, useRef, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {BondsActionTypes} from '_redux/bonds/bonds.types'
import {ReferralsActionTypes} from '_redux/referrals/referrals.types'
import {MembersActionTypes} from '_redux/members/members.types'
import {AssociatesActionTypes} from '_redux/associates/associates.types'
import {RewardsActionTypes} from '_redux/rewards/rewards.types'
import {AdsActionTypes} from '_redux/ads/ads.types'
import {ReturnsActionTypes} from '_redux/returns/returns.types'
import {WithdrawalsActionTypes} from '_redux/withdrawals/withdrawals.types'
import {DashboardModelQuery} from '../types/dashboard-models.types'

type FetchDataActions =
  | BondsActionTypes
  | ReferralsActionTypes
  | MembersActionTypes
  | AssociatesActionTypes
  | RewardsActionTypes
  | AdsActionTypes
  | ReturnsActionTypes
  | WithdrawalsActionTypes

export function useSearch(
  action: (data: DashboardModelQuery) => FetchDataActions,
  clearAction: () => FetchDataActions,
): [
  number,
  () => void,
  (qParams: Partial<DashboardModelQuery>) => void,
  () => void,
] {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const cb = useRef(action)
  const clearCb = useRef(clearAction)

  const loadMore = (): void => {
    setCurrentPage(currentPage + 1)
  }
  const resetCurrentPage = useCallback(() => {
    const clearFunction = clearCb.current
    setCurrentPage(1)
    dispatch(clearFunction())
  }, [dispatch])
  const searchRequest = useCallback(
    (qParams: Partial<DashboardModelQuery>) => {
      const fetchFunction = cb.current
      const query = {page: currentPage, ...qParams}
      dispatch(fetchFunction(query))
    },
    [dispatch, currentPage, cb],
  )

  return [currentPage, loadMore, searchRequest, resetCurrentPage]
}
