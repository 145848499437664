import React, {FC} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'

type Props = {
  isOpen: boolean
  toggle(): void
  title: string
}

const BaseModal: FC<Props> = ({isOpen, toggle, children, title = ''}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <strong>{title}</strong>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="danger">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BaseModal
