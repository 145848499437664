import {SagaIterator} from 'redux-saga'
import {all, call, takeLatest} from 'redux-saga/effects'
import {
  ADD_ADMIN_START,
  DELETE_ADMIN_START,
  FETCH_ADMINS_START,
} from './admins.constants'
import {
  deleteAdminStartSaga,
  fetchAdminsStartSaga,
  saveNewAdminStartSaga,
} from './admins.sagas'

export function* onFetchAdminsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_ADMINS_START, fetchAdminsStartSaga)
}

export function* onAddAdminStart(): SagaIterator<void> {
  yield takeLatest(ADD_ADMIN_START, saveNewAdminStartSaga)
}
export function* onDeleteAdminStart(): SagaIterator<void> {
  yield takeLatest(DELETE_ADMIN_START, deleteAdminStartSaga)
}

export function* adminsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchAdminsStart),
    call(onAddAdminStart),
    call(onDeleteAdminStart),
  ])
}
