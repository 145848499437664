import React, {FC, useState} from 'react'
import {IconContext} from 'react-icons'
import {FaBars, FaTimes, FaEnvelope, FaUser} from 'react-icons/fa'
import Logo from 'assets/images/logo.png'
import LogoWebp from 'assets/images/logo.webp'
import {theme} from 'AppTheme'

import {NavLink} from 'react-router-dom'

import HeaderNav from './nav.component'

const headerIconValues = {color: 'black', size: '1.4em'}
const headerIconActiveValues = {color: theme.colors.primary, size: '1.4em'}

const Header: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center vw-100 pt-4 py-2">
        <IconContext.Provider
          value={isOpen ? headerIconActiveValues : headerIconValues}
        >
          <div role="button" onClick={toggle}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
        </IconContext.Provider>
        <picture className="text-center">
          <source srcSet={LogoWebp} type="image/webp" />
          <source srcSet={Logo} type="image/png" />
          <img src={Logo} alt="SoarRising logo" className="w-25 h-75" />
        </picture>
        <IconContext.Provider value={headerIconValues}>
          <div className="d-flex justify-content-between align-items-end">
            <FaEnvelope className="mr-4" />

            <NavLink
              to="/dashboard/admin-profile"
              exact
              activeClassName="active-nav-link"
            >
              <FaUser />
            </NavLink>
          </div>
        </IconContext.Provider>
      </div>
      <HeaderNav isOpen={isOpen} />
    </>
  )
}

export default Header
