import React, {FC} from 'react'
import {Row, Container} from 'reactstrap'
import Page404 from 'assets/images/page_not_found.png'
import Page404Webp from 'assets/images/page_not_found.webp'

const PageNotFound: FC = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center h-100">
      <Row className="w-50 mx-auto">
        <picture className="text-center">
          <source srcSet={Page404Webp} type="image/webp" />
          <source srcSet={Page404} type="image/png" />
          <img src={Page404} alt="Page not found" className="mx-auto" />
        </picture>
      </Row>
    </Container>
  )
}

export default PageNotFound
