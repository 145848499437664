import styled from 'styled-components/macro'
import {Row} from 'reactstrap'

export const StyledNavLinkRow = styled(Row)`
  .nav-link {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:hover {
      color: #000;
    }

    &.active {
      color: ${(props): string => `${props.theme.colors.primary}`};
      font-weight: bold;
    }
  }
`

export const StyledTableRow = styled(Row)`
  & table {
    font-size: 12px;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
`

export const StyledFixedTableRow = styled(StyledTableRow)`
  & table {
    max-height: 420px;
    overflow-y: scroll;
  }
`

export const StyledDashboardChartsRow = styled(Row)`
  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: ${(props): string => `${props.theme.colors.lightPrimary}`};
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    background-color: ${(props): string => `${props.theme.colors.primary}`};
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    background-color: ${(props): string => `${props.theme.colors.primary}`};
  }
`
