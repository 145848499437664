import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {AdsState} from './ads.types'

function getAds(state: AppState): AdsState {
  return state.ads
}

export const selectAdDetails = createSelector(
  getAds,
  ads => ads.current.details,
)

export const selectAdRequesting = createSelector(
  getAds,
  ads => ads.current.requesting,
)

export const selectAdsRequesting = createSelector(getAds, ads => ads.requesting)

export const selectAdsMeta = createSelector(getAds, ads => ads.meta)

export const selectAds = createSelector(getAds, ads =>
  ads.allIds.map(id => ads.byId[id]),
)
