import {Ad} from '_redux/ads/ads.types'
import {TableBodyData} from './../../common/types/table-body.types'

export const transformAdsForTable = (ads: Array<Ad>): TableBodyData[] => {
  const data = ads.map(ad => ({
    key: ad.id,
    header: {
      value: ad?.image ?? '-',
      isImage: true,
    },
    data: [
      {
        value: ad?.bond_name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/adverts/${ad.id}`,
      },
      {value: ad?.description ?? '-'},
      {value: ad?.active === 1 ? 'Active' : 'Inactive'},
    ],
  }))
  return data
}
