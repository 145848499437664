import ActionBar from 'components/common/action-bar.component'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import {useSearch} from 'components/common/hooks/use-search.hook'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {TableWithActions} from 'components/common/tables'
import TableFilters from 'components/common/tables/table-filter.component'
import {TableBodyData, TSortBy} from 'components/common/types/table-body.types'
import AdminLayout from 'components/layouts/admin.component'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {FiPlus} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Nav, Row} from 'reactstrap'
import {addAdminStart} from '_redux/admins/admins.actions'
import {
  clearSearchAssociates,
  fetchAssociatesStart,
  searchAssociatesStart,
} from '_redux/associates/associates.actions'
import {
  makeGetSortedAssociates,
  selectAssociatesMeta,
  selectAssociatesRequesting,
  selectAssociatesSearchMeta,
} from '_redux/associates/associates.selectors'
import {AssociatesMembership} from '_redux/associates/associates.types'
import {AppState} from '_redux/store.types'
import {transformAssociatesForTable} from './utils'

const membershipValues = {
  'bond holder': 'FUND HOLDER',
  green: 'GREEN Associate',
  silver: 'SILVER Associate',
  gold: 'GOLD Associate',
  platinum: 'PLATINUM Associate',
  'platinum plus': 'PLATINUM Plus Associate',
  'brand ambassador': 'AMBASSADOR',
}

// eslint-disable-next-line max-lines-per-function
const DashboardAssociateMgmt: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [sortOrder, setSortOrder] = useState<TSortBy>('newest')
  const [searchTerm, setSearchTerm] = useState('')
  const [
    currentAssociatesPage,
    loadMoreAssociates,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchAssociatesStart)
  const [
    currentSearchPage,
    loadMoreSearch,
    handleSearchRequest,
    resetCurrentSearchPage,
  ] = useSearch(searchAssociatesStart, clearSearchAssociates)
  const dispatch = useDispatch()
  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/(all|deleted account)/.test(lowercaseTab)) {
      setExtraQueryParams({query: AssociatesMembership[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const associatesRequesting = useSelector(selectAssociatesRequesting)
  const {pages: associatesPages} = useSelector(selectAssociatesMeta)
  const {pages: searchAssociatesPages} = useSelector(selectAssociatesSearchMeta)
  const select = useMemo(makeGetSortedAssociates, [])
  const associates = useSelector((state: AppState) =>
    select(state, {sortOrder, modelType: 'all'}),
  )
  const associatesSearch = useSelector((state: AppState) =>
    select(state, {sortOrder, modelType: 'search'}),
  )
  const transformedAssociates: TableBodyData[] = transformAssociatesForTable(
    associates,
    membershipValues[tab],
  )
  const transformedSearchAssociates: TableBodyData[] = transformAssociatesForTable(
    associatesSearch,
    undefined,
  )

  const handleSort = (sortBy: TSortBy) => {
    setSortOrder(sortBy)
  }

  const handleSearch = useCallback(
    (query: string) => {
      setSearchTerm(query)
      handleSearchRequest({query})
    },
    [handleSearchRequest],
  )

  const clearSearchResults = useCallback(() => {
    setSearchTerm('')
    resetCurrentSearchPage()
  }, [resetCurrentSearchPage])

  useEffect(() => {
    return () => {
      clearSearchResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddAdmin = (key: string) => {
    dispatch(addAdminStart(key))
  }
  const tableValues = (() => ({
    page: searchTerm ? currentSearchPage : currentAssociatesPage,
    body: searchTerm ? transformedSearchAssociates : transformedAssociates,
    hasMore: searchTerm
      ? currentSearchPage < searchAssociatesPages
      : currentAssociatesPage < associatesPages,
    loadMore: searchTerm ? loadMoreSearch : loadMoreAssociates,
  }))()

  const actionButtons = (key: string) => (
    <Button
      className="btn-icon mr-2"
      size="sm"
      type="button"
      onClick={() => {
        handleAddAdmin(key)
      }}
    >
      <FiPlus />
      <span>Add as admin</span>
    </Button>
  )

  return (
    <AdminLayout pageTitle="Associate Management">
      <Row>
        <ActionBar headerText="Associate Management" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={[
              'All',
              'Bond Holder',
              'Green',
              'Silver',
              'Gold',
              'Platinum',
              'Platinum Plus',
              'Brand Ambassador',
              'Deleted Accounts',
            ]}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>

      {(searchTerm ? true : associates.length) ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              handleSort={handleSort}
              handleSearch={handleSearch}
              clearSearchResults={clearSearchResults}
              hasSearchResults={transformedSearchAssociates.length > 0}
              loaded={
                searchTerm
                  ? transformedSearchAssociates.length
                  : transformedAssociates.length
              }
              total={
                (searchTerm ? searchAssociatesPages : associatesPages) * 50
              }
            />
            <div>
              <TableWithActions
                headers={[
                  '',
                  'ID',
                  'Name',
                  'Membership',
                  'Location',
                  'Status',
                  'Join Date',
                  'Referrals',
                  'Units',
                  'Amount',
                  '',
                ]}
                loading={associatesRequesting}
                actionButtons={actionButtons}
                {...tableValues}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={associatesRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardAssociateMgmt
