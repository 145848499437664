import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_REWARDS_START} from './rewards.constants'
import {FetchRewardsStartSaga} from './rewards.sagas'

export function* onFetchRewardsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_REWARDS_START, FetchRewardsStartSaga)
}

export function* rewardsSagas(): SagaIterator<void> {
  yield all([call(onFetchRewardsStart)])
}
