import {combineReducers} from 'redux'
import {
  membersById,
  allMembersIds,
  membersMeta,
  membersRequesting,
  locationChartData,
  locationChartDataRequesting,
} from './all/members-all.reducer'
import {currentMember} from './single/members-single.reducer'

const membersReducer = combineReducers({
  byId: membersById,
  allIds: allMembersIds,
  meta: membersMeta,
  requesting: membersRequesting,
  current: currentMember,
  chartData: locationChartData,
  chartRequesting: locationChartDataRequesting,
})

export default membersReducer
