import React from 'react'
import {RouteComponentProps, Switch, Route} from 'react-router-dom'
import Dashboard from 'components/dashboard/home'
import DashboardAssociateMgmt from 'components/dashboard/associate-mgmt'
import DashboardReportsAnalytics from 'components/dashboard/reports-analytics'
import DashboardBondMgmt from 'components/dashboard/bond-mgmt'
import DashboardReferrals from 'components/dashboard/referrals'
import DashboardBondProfile from 'components/dashboard/bond-profile'
import DashboardSettingsAccess from 'components/dashboard/settings-access'
import DashboardAdminProfile from 'components/dashboard/admin-profile'
import DashboardMemberProfile from 'components/dashboard/member-profile'
import DashboardChat from 'components/dashboard/chat'
import AdsProfile from 'components/dashboard/ads-profile'
import AdsList from 'components/dashboard/ads-list'
import DashboardRewards from 'components/dashboard/rewards'
import DashboardReturns from 'components/dashboard/returns'
import DashboardWithdrawals from 'components/dashboard/withdrawals'

const DashboardPages: React.FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route
        exact
        path="/dashboard/associate-mgmt"
        component={DashboardAssociateMgmt}
      />
      <Route exact path="/dashboard/rewards" component={DashboardRewards} />
      <Route exact path="/dashboard/referrals" component={DashboardReferrals} />
      <Route exact path="/dashboard/returns" component={DashboardReturns} />
      <Route
        exact
        path="/dashboard/withdrawals"
        component={DashboardWithdrawals}
      />
      <Route exact path="/dashboard/bond-mgmt" component={DashboardBondMgmt} />
      <Route
        exact
        path="/dashboard/bond-profile/:id"
        component={DashboardBondProfile}
      />
      <Route
        exact
        path="/dashboard/settings-access"
        component={DashboardSettingsAccess}
      />
      <Route
        exact
        path="/dashboard/reports-analytics"
        component={DashboardReportsAnalytics}
      />

      <Route
        exact
        path="/dashboard/admin-profile"
        component={DashboardAdminProfile}
      />
      <Route
        exact
        path="/dashboard/members/:id"
        component={DashboardMemberProfile}
      />
      <Route exact path="/dashboard/chat" component={DashboardChat} />
      <Route exact path="/dashboard/adverts/:id" component={AdsProfile} />
      <Route exact path="/dashboard/adverts" component={AdsList} />
    </Switch>
  )
}
export default DashboardPages
