import {theme} from 'AppTheme'
import ActionBar from 'components/common/action-bar.component'
import LoadingSpinner from 'components/common/loading-spinner.component'
import AdminLayout from 'components/layouts/admin.component'
import {PlaceholderImage} from 'helpers/placeholder-image'
import React, {FC, useEffect, useState} from 'react'
import {IconContext} from 'react-icons'
import {FiEdit} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Input, Label, Row} from 'reactstrap'
import {fetchAdminProfileStart} from '_redux/user/auth/user-auth.actions'
import {AdminProfile} from '_redux/user/auth/user-auth.types'
import {selectAdminProfile} from '_redux/user/user.selectors'
import {BasicTable as AdminTableHeader} from '../../common/tables'
import {StyledDetailsRow} from './styles'
import {tranformPrfoileInfoForTable} from './utils'

const colorRed = {color: theme.colors.primary}
const editIconValues = {
  ...colorRed,
  size: '1.4em',
  style: {
    verticalAlign: 'middle',
    marginLeft: '7.5px',
    marginTop: '-5px',
  },
}

const DashboardAdminProfile: FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    dispatch(fetchAdminProfileStart(setLoading))
  }, [dispatch])
  const profileInfo: AdminProfile = useSelector(selectAdminProfile)
  const transformedProfileInfo = tranformPrfoileInfoForTable(profileInfo)

  return (
    <AdminLayout pageTitle="Admin Profile">
      <Row>
        <ActionBar headerText="Profile" />
      </Row>

      <StyledDetailsRow className="py-3 align-items-start">
        {loading ? (
          <div className="mx-auto py-5">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Col sm="2" className="border rounded pt-3 pb-2 px-3">
              <div className="rounded-circle border-0 overflow-hidden img-thumbnail">
                <img
                  className="img-fluid "
                  alt="admin-avatar"
                  src={
                    `data:image/png;base64,${profileInfo?.profile_pic}` ||
                    PlaceholderImage
                  }
                />
              </div>
              <IconContext.Provider value={editIconValues}>
                <Label for="bondImage" className="float-right clickable">
                  <FiEdit />
                </Label>
                <Input
                  type="file"
                  name="file"
                  id="bondImage"
                  accept="image/*"
                  className="d-none"
                />
              </IconContext.Provider>
            </Col>

            <Col sm="9" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex justify-content-between">
                Details
                <span className=" clickable">
                  Edit
                  <IconContext.Provider value={editIconValues}>
                    <FiEdit />
                  </IconContext.Provider>
                </span>
              </div>
              <div className="font-weight-bold py-3 border-bottom text-danger text-capitalize">
                {`${profileInfo?.firstname} ${profileInfo?.lastname}`}
              </div>

              <div>
                <AdminTableHeader
                  headers={[
                    'ID',
                    'Level',
                    'Location',
                    'Status',
                    'Email',
                    'Password',
                    'Join Date',
                    'Supervisor',
                  ]}
                  loading={loading}
                  page={1}
                  body={transformedProfileInfo}
                  hasMore={false}
                  loadMore={() => {
                    console.log('clicked')
                  }}
                />
              </div>
            </Col>
          </>
        )}
      </StyledDetailsRow>
    </AdminLayout>
  )
}

export default DashboardAdminProfile
