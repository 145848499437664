import {StyledTableRow} from 'components/common/styles/row.styles'
import styled from 'styled-components'

export const StyledChatRow = styled(StyledTableRow)`
  & .col-md-8 {
    margin-right: 1%;
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }

  & live-chart {
    background-color: ${({theme}) => theme.colors.gray};
  }
`
