import React from 'react'
import {Route, Redirect, RouteComponentProps} from 'react-router-dom'
import { getCookieValue } from 'helpers/utils'

const PublicRoute: React.FC<{
  component: React.FC<RouteComponentProps>
  path: string
  exact?: boolean
}> = ({component: Component, ...rest}) => {
   const userToken = getCookieValue('soarRisingToken')
  const renderPublicComponent: React.FC<RouteComponentProps> = props => {
    return userToken ? <Redirect to="/" /> : <Component {...props} />
  }
  return <Route {...rest} render={renderPublicComponent} />
}

export default PublicRoute
