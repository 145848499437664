import React, {FC} from 'react'
import {NavItem} from 'reactstrap'

type Props = {
  tabs: string[]
  currentTab: string
  toggleCurrentTab(tab: string): void
}
const SubNavLinks: FC<Props> = ({tabs, currentTab, toggleCurrentTab}) => {
  const [firstTab] = tabs
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {tabs.map(tab => {
        return (
          <NavItem key={tab}>
            <p
              className={`nav-link  mb-0 ${tab === firstTab ? 'pl-0' : ''} ${
                currentTab.toLowerCase() === tab.toLowerCase() ? 'active' : ''
              }`}
              onClick={() => toggleCurrentTab(tab)}
            >
              {tab}
            </p>
          </NavItem>
        )
      })}
    </>
  )
}

export default SubNavLinks
