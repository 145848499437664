import React from 'react'
import {Table, Button} from 'reactstrap'
import {TableBodyData} from 'components/common/types/table-body.types'
import TableHeaders from 'components/common/tables/table-headers.component'
import TableBody from 'components/common/tables/table-body.component'
import TableLoader from 'components/common/tables/table-loader.component'

interface Props {
  headers: string[]
  loading: boolean
  page: number
  body: TableBodyData[]
  hasMore: boolean
  loadMore(): void
}

const DashboardTable: React.FC<Props> = ({
  headers,
  body,
  loading,
  page,
  hasMore,
  loadMore,
}) => {
  return (
    <>
      <Table responsive hover>
        <TableHeaders headers={headers} />
        <tbody>
          <TableBody body={body} />
          {(page === 1 && body.length) || !loading ? null : <TableLoader />}
        </tbody>
      </Table>
      {!loading && hasMore ? (
        <Button className="mb-1" onClick={loadMore}>
          More
        </Button>
      ) : null}
    </>
  )
}

export default DashboardTable
