import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import ReturnsService from 'services/api/returns/service'
import {normalize} from 'normalizr'
import {FetchReturnsStartAction} from './returns-actions.types'
import {
  fetchReturnsFailure,
  fetchReturnsSuccess,
  setReturnsMeta,
} from './returns.actions'

export function* FetchReturnsStartSaga({
  payload: {page},
}: FetchReturnsStartAction): SagaIterator<void> {
  const returnsService = new ReturnsService()
  try {
    const {
      data: {data: returns, pages},
    } = yield call([returnsService, 'fetchReturns'], page)
    const normalizedReturns = yield call(
      normalize,
      returns,
      schema.arrayOfReturns,
    )
    yield put(fetchReturnsSuccess(normalizedReturns))
    yield put(setReturnsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchReturnsFailure())
  }
}
