import styled from 'styled-components/macro'
import {Row, Col, Nav} from 'reactstrap'

export const StyledDetailsRow = styled(Row)`
  & .img-thumbnail {
    min-height: 10rem;
  }

  & table {
    font-size: 12px;

    &:first-child {
      font-size: 11px;

      td,
      th {
        padding: 0.75rem 0.3rem;
        text-align: center;
      }
    }
  }

  .col-sm-9 {
    margin-left: 1.2%;
    flex: 0 0 82.13%;
    max-width: 82.13%;
  }

  & .activate {
    & button {
      &,
      &.focus,
      &:focus {
        background-color: ${(props): string => `${props.theme.colors.primary}`};
        border-color: ${(props): string => `${props.theme.colors.primary}`};
      }

      &:active {
        background-color: ${(props): string =>
          `${props.theme.colors.primary} !important`};
        border-color: ${(props): string =>
          `${props.theme.colors.primary} !important`};
      }

      &:hover {
        color: #fff;
        background-color: ${(props): string =>
          `${props.theme.colors.darkPrimary}`};
        border-color: ${(props): string => `${props.theme.colors.darkPrimary}`};
      }

      &.disabled,
      &:disabled {
        background-color: ${(props): string =>
          `${props.theme.colors.lightPrimary}`};
        border-color: ${(props): string =>
          `${props.theme.colors.lightPrimary}`};
      }
    }
  }
`

export const StyledEditBondNav = styled(Nav)`
  border-bottom: ${(props): string =>
    `1px solid ${props.theme.colors.primary}`};
  & .nav-link {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: #000;
    }

    &.active {
      border-color: ${(props): string =>
        `${props.theme.colors.primary} ${props.theme.colors.primary} #fff`};
      font-weight: bold;
    }
  }
`

export const StyledChartRow = styled(Row)`
  .card.card-body {
    height: 200px;
    max-width: 200px;
  }
`

export const StyledChartCol = styled(Col)<{color: string}>`
  .rounded-circle {
    border-color: ${(props): string => `${props.theme.colors[props.color]}`};
    border-style: solid;
    border-width: 1rem;
  }
`
