import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_RETURNS_START,
  FETCH_RETURNS_SUCCESS,
  FETCH_RETURNS_FAILURE,
  SET_RETURNS_META,
} from './returns.constants'
import {NormalizedReturns, ReturnsActionTypes} from './returns.types'

export function fetchReturnsStart(
  data: DashboardModelQuery,
): ReturnsActionTypes {
  return {
    type: FETCH_RETURNS_START,
    payload: data,
  }
}

export function fetchReturnsSuccess(
  data: NormalizedReturns,
): ReturnsActionTypes {
  return {
    type: FETCH_RETURNS_SUCCESS,
    payload: data,
  }
}

export function fetchReturnsFailure(): ReturnsActionTypes {
  return {
    type: FETCH_RETURNS_FAILURE,
  }
}

export function setReturnsMeta(meta: DashboardModelMeta): ReturnsActionTypes {
  return {
    type: SET_RETURNS_META,
    payload: meta,
  }
}
