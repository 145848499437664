/* eslint-disable max-lines-per-function */
import {theme} from 'AppTheme'
import ActionBar from 'components/common/action-bar.component'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {StyledFixedTableRow} from 'components/common/styles/row.styles'
import {
  BasicTable as AdminTableHeader,
  BasicTable,
  TableWithDropdown,
} from 'components/common/tables'
import {TableBodyData} from 'components/common/types/table-body.types'
import AdminLayout from 'components/layouts/admin.component'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {titleCaseName} from 'helpers/utils'
import React, {FC, useEffect} from 'react'
import {IconContext} from 'react-icons'
import {FiChevronDown, FiEdit} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps} from 'react-router'
import {Col, Input, Label, Row} from 'reactstrap'
import {selectCurrentMember} from '_redux/members/members.selectors'
import {
  fetchMemberDetailsStart,
  fetchMemberInvestmentsStart,
  fetchMemberReferredStart,
  fetchMemberReturnsStart,
  fetchMemberRewardsStart,
} from '_redux/members/single/members-single.actions'
import {
  MemberDetail,
  MemberInvestments,
  MemberReferrals,
  MemberReturns,
  MemberRewards,
} from '_redux/members/single/members-single.types'
import {StyledDetailsRow} from './styles'
import {bondsRowsForReferralsTable} from './table-bonds-rows.component'
import {purchaseHistoryRowsForInvestmentsTable} from './table-investments-rows.component'
import {
  transformDetailsForTable,
  transformInvestmentsForTable,
  transformReferralsForTable,
  transformReturnsForTable,
  transformRewardsForTable,
} from './utils'

const LoadingOrEmptyText: FC<{loading: boolean}> = ({loading}) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? (
        <div className="text-center mx-auto py-5">
          <LoadingSpinner />
        </div>
      ) : (
        <p className="text-center">No Data</p>
      )}
    </>
  )
}

const colorRed = {color: theme.colors.primary}
const editIconValues = {
  ...colorRed,
  size: '1.4em',
  style: {verticalAlign: 'middle', marginLeft: '7.5px', marginTop: '-5px'},
}

interface MatchParams {
  id: string
}

const DashboardMemberProfile: FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const dispatch = useDispatch()
  const currentMember = useSelector(selectCurrentMember)
  useEffect(() => {
    dispatch(fetchMemberDetailsStart(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchMemberInvestmentsStart(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchMemberReferredStart(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchMemberReturnsStart(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchMemberRewardsStart(id))
  }, [dispatch, id])

  const transformedDetails: TableBodyData[] = transformDetailsForTable(
    currentMember.details.data.id
      ? [currentMember.details.data as MemberDetail]
      : [],
  )
  const transformedInvestments: TableBodyData[] = transformInvestmentsForTable(
    currentMember.investments.data
      ? (currentMember.investments.data as MemberInvestments[])
      : [],
  )
  const transformedReferrals: TableBodyData[] = transformReferralsForTable(
    currentMember.referred.data.length
      ? (currentMember.referred.data as MemberReferrals[])
      : [],
  )
  const transformedReturns: TableBodyData[] = transformReturnsForTable(
    currentMember.returns.data.length
      ? (currentMember.returns.data as MemberReturns[])
      : [],
  )
  const transformedRewards: TableBodyData[] = transformRewardsForTable(
    currentMember.rewards.data.length
      ? (currentMember.rewards.data as MemberRewards[])
      : [],
  )

  const bondsRowsForTable = bondsRowsForReferralsTable(
    currentMember.referred.data as MemberReferrals[],
  )
  const PurchaseHistoryRowsForTable = purchaseHistoryRowsForInvestmentsTable(
    currentMember.investments.data as MemberInvestments[],
  )

  return (
    <AdminLayout pageTitle="User Profile">
      <Row>
        <ActionBar headerText="User Profile" />
      </Row>
      <StyledDetailsRow className="py-3 align-items-start">
        <Col sm="2" className="border rounded pt-3 pb-2 px-3 ">
          <div className="img-thumbnail overflow-hidden rounded-circle">
            <img
              className="img-fluid border-0 "
              alt="..."
              src={`data:image/png;base64,${
                currentMember.details.data.profile_pic || PlaceholderImage
              }`}
            />
          </div>
          <IconContext.Provider value={editIconValues}>
            <Label for="bondImage" className="float-right clickable">
              <FiEdit />
            </Label>
            <Input
              type="file"
              name="file"
              id="bondImage"
              accept="image/*"
              className="d-none"
            />
          </IconContext.Provider>
        </Col>

        <Col sm="9">
          <StyledFixedTableRow>
            <Col sm="12" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex">
                Details
                <span className="ml-auto clickable">
                  Edit
                  <IconContext.Provider value={editIconValues}>
                    <FiEdit />
                  </IconContext.Provider>
                </span>
              </div>
              <div className="font-weight-bold py-3 border-bottom ">
                {titleCaseName(
                  `${currentMember.details.data.firstname || 'N/A'} ${
                    currentMember.details.data.lastname || ''
                  }`,
                )}
              </div>
              {currentMember.details.data.email ? (
                <div>
                  <AdminTableHeader
                    headers={[
                      'ID',
                      'Location',
                      'Status',
                      'Email',
                      'Membership',
                      'Funds',
                      'Joined Date',
                      'Referred By',
                    ]}
                    loading={false}
                    page={1}
                    body={transformedDetails}
                    hasMore={false}
                    loadMore={() => {
                      console.log('clicked')
                    }}
                  />
                </div>
              ) : (
                <LoadingOrEmptyText
                  loading={currentMember.details.requesting}
                />
              )}
            </Col>
          </StyledFixedTableRow>

          <StyledFixedTableRow className="py-3">
            <Col sm="12" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex">
                Investments ({transformedInvestments.length})
                <span className="ml-auto clickable">
                  Filters
                  <IconContext.Provider value={editIconValues}>
                    <FiChevronDown />
                  </IconContext.Provider>
                </span>
              </div>

              <div>
                <TableWithDropdown
                  headers={[
                    'Bond',
                    '',
                    'Location',
                    'Status',
                    'StartDate',
                    'EndDate',
                    'Units total',
                    'Units available',
                    'Return',
                  ]}
                  expandedRowData={PurchaseHistoryRowsForTable}
                  hasActiveColor
                  loading={false}
                  page={1}
                  body={transformedInvestments}
                  hasMore={false}
                  loadMore={() => {
                    console.log('clicked')
                  }}
                />
              </div>
            </Col>
          </StyledFixedTableRow>

          <StyledFixedTableRow className="py-3">
            <Col sm="12" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex">
                Referrals ({transformedReferrals.length})
                <span className="ml-auto clickable">
                  Filters
                  <IconContext.Provider value={editIconValues}>
                    <FiChevronDown />
                  </IconContext.Provider>
                </span>
              </div>

              <div>
                <TableWithDropdown
                  headers={[
                    'ID',
                    'Name',
                    'Membership',
                    'Location',
                    'Status',
                    'Join Date',
                    'Referrals',
                    'Bonds',
                    '',
                  ]}
                  hasActiveColor
                  expandedRowData={bondsRowsForTable}
                  loading={false}
                  page={1}
                  body={transformedReferrals}
                  hasMore={false}
                  loadMore={() => {
                    console.log('clicked')
                  }}
                />
              </div>
            </Col>
          </StyledFixedTableRow>

          <StyledFixedTableRow className="py-3">
            <Col sm="12" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex">
                Returns ({transformedReturns.length})
                <span className="ml-auto clickable">
                  Filters
                  <IconContext.Provider value={editIconValues}>
                    <FiChevronDown />
                  </IconContext.Provider>
                </span>
              </div>

              <div>
                <BasicTable
                  headers={[
                    'Date',
                    'Description',
                    'Series Name',
                    'Series Phase',
                    'Return',
                    'Withdrawal Amount',
                  ]}
                  loading={false}
                  page={1}
                  body={transformedReturns}
                  hasMore={false}
                  loadMore={() => {
                    console.log('clicked')
                  }}
                />
              </div>
            </Col>
          </StyledFixedTableRow>

          <StyledFixedTableRow className="py-3">
            <Col sm="12" className="border rounded">
              <div className="font-weight-bold py-3 border-bottom d-flex">
                Rewards ({transformedRewards.length})
                <span className="ml-auto clickable">
                  Filters
                  <IconContext.Provider value={editIconValues}>
                    <FiChevronDown />
                  </IconContext.Provider>
                </span>
              </div>

              <div>
                <BasicTable
                  headers={[
                    'Date',
                    'Description',
                    'Unit Donated',
                    'Referred User',
                    'Series Name',
                    'Reward',
                    'Withdrawal Amount',
                  ]}
                  loading={false}
                  page={1}
                  body={transformedRewards}
                  hasMore={false}
                  loadMore={() => {
                    console.log('clicked')
                  }}
                />
              </div>
            </Col>
          </StyledFixedTableRow>
        </Col>
      </StyledDetailsRow>
    </AdminLayout>
  )
}

export default DashboardMemberProfile
