import {theme} from 'AppTheme'
import ActionBar from 'components/common/action-bar.component'
import AdminLayout from 'components/layouts/admin.component'
import {PlaceholderImage} from 'helpers/placeholder-image'
import React, {useState} from 'react'
import {IconContext} from 'react-icons'
import {FiEye} from 'react-icons/fi'
import {Col, Row} from 'reactstrap'
import ChatLog from './log-table.component'
import {StyledChatRow} from './styles'
import {transformLogsForTable} from './utils'

const iconStyles = {color: theme.colors.primary, style: {marginLeft: '7.5px'}}

const Chat = (): JSX.Element => {
  const [isChatActive, setIsChatActive] = useState<string>('')
  // const logs: unknown[] = []
  const transformedLogs = transformLogsForTable()

  return (
    <AdminLayout pageTitle="Communication Chat">
      <Row>
        <ActionBar
          onAddClick={() => console.log('add')}
          headerText="Communication Chat"
        />
      </Row>

      <StyledChatRow className="py-3 d-grid">
        <Col sm="12" md={isChatActive ? '8' : '12'} className="border rounded">
          <div className="font-weight-bold py-3 border-bottom d-flex">
            Chat log
            <span className="ml-auto clickable">
              View log
              <IconContext.Provider value={iconStyles}>
                <FiEye />
              </IconContext.Provider>
            </span>
          </div>
          <ChatLog
            activeRowState={[isChatActive, setIsChatActive]}
            headers={[
              '#',
              'Name',
              'Subject',
              'Date Opened',
              'Date Closed',
              'Status',
            ]}
            body={transformedLogs}
            loading={false}
            page={1}
            hasMore={false}
            loadMore={() => console.log('click')}
          />
        </Col>
        <Col
          sm="12"
          md="4"
          className={`border rounded ${isChatActive ? 'd-block' : 'd-none'}`}
        >
          <div className="font-weight-bold py-3">Live Chat</div>

          <aside className="bg-light rounded live-chat py-3 px-4">
            <img src={PlaceholderImage} alt="..." />
            {/* TODO:  Create care agent and investor chat card*/}
          </aside>
          <input
            type="text"
            name="user-message"
            id="user-message"
            placeholder="Type message here"
            className="form-control w-100 my-3"
          />
        </Col>
      </StyledChatRow>
    </AdminLayout>
  )
}

export default Chat
