import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {MembersState} from './members.types'

function getMembers(state: AppState): MembersState {
  return state.members
}

export const selectLocationChartData = createSelector(
  getMembers,
  members => members.chartData,
)

export const selectLocationChartDataRequesting = createSelector(
  getMembers,
  members => members.chartRequesting,
)

export const selectMembersRequesting = createSelector(
  getMembers,
  members => members.requesting,
)

export const selectCurrentMember = createSelector(
  getMembers,
  members => members.current,
)

export const selectMembersMeta = createSelector(
  getMembers,
  members => members.meta,
)

export const selectMembers = createSelector(getMembers, members =>
  members.allIds.map(id => members.byId[id]),
)
