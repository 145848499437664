import {TableBodyData} from './../../common/types/table-body.types'

export const transformLogsForTable = (): TableBodyData[] => {
  return [
    {
      key: '1',
      header: {
        value: '1',
      },
      data: [
        {value: 'Aisha'},
        {value: '-'},
        {value: '-'},
        {value: '-'},
        {value: 'inprogress'},
      ],
    },
  ]
}
