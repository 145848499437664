import React, {FC} from 'react'

type Props = {
  headers: string[]
}

const TableHeaders: FC<Props> = ({headers}) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={header + index}>{header}</th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeaders
