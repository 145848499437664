import {TableBodyData} from 'components/common/types/table-body.types'
import {AdminProfile} from '_redux/user/auth/user-auth.types'

export const tranformPrfoileInfoForTable = (
  profileInfo: AdminProfile,
): TableBodyData[] => {
  return [
    {
      key: profileInfo?.id ?? '-',
      header: {
        value: profileInfo?.id ?? '-',
      },
      data: [
        {value: profileInfo?.level},
        {value: profileInfo?.location ?? '-'},
        {value: profileInfo?.status ?? '-'},
        {value: profileInfo?.email ?? '-'},

        {value: profileInfo?.password ?? '*****'},
        {value: profileInfo?.join_date ?? '-'},
        {
          value: profileInfo?.supervisor ?? '-',
          underlineValue: true,
          isLink: true,
          url: '',
        },
      ],
    },
  ]
}
