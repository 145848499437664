import {useSelector, useDispatch} from 'react-redux'
import {
  selectLocationChartDataRequesting,
  selectLocationChartData,
} from '_redux/members/members.selectors'
import {selectInvestmentChartData} from '_redux/bonds/bonds.selectors'
import {LocationData} from '_redux/members/all/members-all.types'
import {useEffect} from 'react'
import {MembersActionTypes} from '_redux/members/members.types'
import {
  ReferralsActionTypes,
  ReferralData,
} from '_redux/referrals/referrals.types'
import {BondsActionTypes} from '_redux/bonds/bonds.types'
import {InvestmentData} from '_redux/bonds/all/bonds-all.types'
import {selectReferralChartData} from '_redux/referrals/referrals.selectors'

type FetchChartDataActions =
  | MembersActionTypes
  | ReferralsActionTypes
  | BondsActionTypes

export function useChartsData(
  fetchChartActions: (() => FetchChartDataActions)[],
): {
  locationChartDataRequesting: boolean
  locationChartData: LocationData
  investmentChartData: {
    [key: string]: InvestmentData[]
  }
  referralChartData: {
    [key: string]: ReferralData[]
  }
} {
  const locationChartDataRequesting = useSelector(
    selectLocationChartDataRequesting,
  )
  const locationChartData = useSelector(selectLocationChartData)
  const investmentChartData = useSelector(selectInvestmentChartData)
  const referralChartData = useSelector(selectReferralChartData)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchChartActions.forEach(action => {
      dispatch(action())
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    locationChartDataRequesting,
    locationChartData,
    investmentChartData,
    referralChartData,
  }
}
