import React, {FC} from 'react'
import LoadingSpinner from '../loading-spinner.component'

const TableLoader: FC = () => {
  return (
    <tr>
      <td colSpan={100} className="text-center pt-4">
        <LoadingSpinner />
      </td>
    </tr>
  )
}

export default TableLoader
