import React from 'react'
import {Helmet} from 'react-helmet'
import {RouteComponentProps} from 'react-router-dom'
import {StaticContext} from 'react-router'
import {Row} from 'reactstrap'
import Logo from 'assets/images/logo_with_tagline.png'
import LogoWebp from 'assets/images/logo_with_tagline.webp'
import LoginFormOverview from 'components/login/overview.component'
import {StyledLoginContainer} from 'components/login/styles'

export type LoginPageLocationState = {
  from?: string
}

const LoginPage: React.FC<RouteComponentProps<
  Record<string, string | undefined>,
  StaticContext,
  LoginPageLocationState | Record<string, unknown> | null | undefined
>> = ({location}) => {
  const from =
    location.state &&
    'from' in location.state &&
    typeof location.state.from === 'string'
      ? location.state.from
      : '/dashboard'

  return (
    <>
      <Helmet>
        <title>Login | SoarRising</title>
      </Helmet>
      <StyledLoginContainer
        fluid
        className="d-flex flex-column justify-content-center align-items-center h-100"
      >
        <Row className="w-50 mx-auto pb-5">
          <picture className="text-center">
            <source srcSet={LogoWebp} type="image/webp" />
            <source srcSet={Logo} type="image/png" />
            <img
              src={Logo}
              alt="SoarRising logo"
              className="w-50 pb-5 mx-auto"
            />
          </picture>
        </Row>
        <Row className="w-50 mx-auto pb-5">
          <LoginFormOverview redirectTo={from} />
        </Row>
      </StyledLoginContainer>
    </>
  )
}
export default LoginPage
