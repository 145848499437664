/* eslint-disable max-lines-per-function */
import React, {FC, useEffect} from 'react'
import {Col, Card, CardTitle, Input, Row, FormGroup, Button} from 'reactstrap'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import VerticalBarChart from 'components/common/charts/vertical-bar.component'
import {useChartsData} from 'components/common/hooks/use-charts.hook'
import DoughnutChart from 'components/common/charts/doughnut.component'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {fetchLocationChartDataStart} from '_redux/members/all/members-all.actions'
import {fetchReferralChartDataStart} from '_redux/referrals/referrals.actions'
import {fetchInvestmentChartDataStart} from '_redux/bonds/all/bonds-all.actions'
// import {numberRange, CalendarMonths} from 'helpers/utils'
import {useChartFilter} from '../../common/hooks/use-chart-filter.hook'
import {
  transformLocationDataForChart,
  transformInvestmentDataForChart,
  transformReferralDataForChart,
} from './utils'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loaderOrNoResult = (loading: boolean): JSX.Element => (
  <div className="mx-auto my-auto">
    {loading ? (
      <LoadingSpinner type="Rings" width={120} height={120} />
    ) : (
      <span>No Result</span>
    )}
  </div>
)

const DashboardHomeCharts: FC = () => {
  const {
    locationChartDataRequesting,
    locationChartData,
    investmentChartData,
    referralChartData,
  } = useChartsData([
    fetchLocationChartDataStart,
    fetchInvestmentChartDataStart,
    fetchReferralChartDataStart,
  ])

  const {
    locationChartProps,
    setLocationChartProps,
    investmentChartProps,
    setInvestmentChartProps,
    referralChartProps,
    setReferralChartProps,
    dataInterval,
    chart,
    shouldFilter,
    setShouldFilter,
    filterData,
    handleSetChart,
    handleSetDataInterval,
    from,
    to,
    handleDayClick,
  } = useChartFilter()

  useEffect(() => {
    if (!locationChartProps.labels.length) {
      const transformedLocationData = transformLocationDataForChart(
        locationChartData,
      )
      setLocationChartProps(transformedLocationData)
    }
  }, [
    locationChartData,
    locationChartProps.labels.length,
    setLocationChartProps,
  ])

  useEffect(() => {
    if (!investmentChartProps.labels.length) {
      const transformedInvestmentData = transformInvestmentDataForChart(
        investmentChartData,
        {
          yearFrom: from.getFullYear(),
          monthFrom: from.getMonth(),
          dayFrom: from.getDate(),
        },
        {yearTo: to.getFullYear(), monthTo: to.getMonth(), dayTo: to.getDate()},
        dataInterval,
      )
      setInvestmentChartProps(transformedInvestmentData)
    } else if (/bonds|both/.test(chart) && shouldFilter) {
      const transformedInvestmentData = transformInvestmentDataForChart(
        investmentChartData,
        {
          yearFrom: from.getFullYear(),
          monthFrom: from.getMonth(),
          dayFrom: from.getDate(),
        },
        {
          yearTo: to.getFullYear(),
          monthTo: to.getMonth(),
          dayTo: to.getDate(),
        },
        dataInterval,
      )
      setInvestmentChartProps({
        ...transformedInvestmentData,
        isUpdate: true,
      })
      setShouldFilter(false)
    }
  }, [
    chart,
    dataInterval,
    from,
    investmentChartData,
    investmentChartProps.labels.length,
    setInvestmentChartProps,
    setShouldFilter,
    shouldFilter,
    to,
  ])

  useEffect(() => {
    if (!referralChartProps.labels.length) {
      const transformedReferralData = transformReferralDataForChart(
        referralChartData,
        {
          yearFrom: from.getFullYear(),
          monthFrom: from.getMonth(),
          dayFrom: from.getDate(),
        },
        {yearTo: to.getFullYear(), monthTo: to.getMonth(), dayTo: to.getDate()},
        dataInterval,
      )
      setReferralChartProps(transformedReferralData)
    } else if (/referrals|both/.test(chart) && shouldFilter) {
      const transformedReferralData = transformReferralDataForChart(
        referralChartData,
        {
          yearFrom: from.getFullYear(),
          monthFrom: from.getMonth(),
          dayFrom: from.getDate(),
        },
        {yearTo: to.getFullYear(), monthTo: to.getMonth(), dayTo: to.getDate()},
        dataInterval,
      )
      setReferralChartProps({...transformedReferralData, isUpdate: true})
      setShouldFilter(false)
    }
  }, [
    chart,
    dataInterval,
    from,
    referralChartData,
    referralChartProps.labels.length,
    setReferralChartProps,
    setShouldFilter,
    shouldFilter,
    to,
  ])

  const modifiers = {start: from, end: to}

  return (
    <>
      <Col className="px-0" sm="8">
        <Card body>
          <div>
            <CardTitle>Statistical overview</CardTitle>
            <Row className="px-3">
              <DayPicker
                className="Selectable"
                numberOfMonths={2}
                selectedDays={[from, {from, to}]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
              />
              <Col>
                <FormGroup tag="fieldset" className="mb-0" row>
                  <legend className="col-form-label py-0 font-weight-bold">
                    Chart
                  </legend>
                  <Input
                    name="chart"
                    value={chart}
                    onChange={handleSetChart}
                    type="select"
                  >
                    <option value="bonds">Bonds</option>
                    <option value="referrals">Referrals</option>
                    <option value="both">Both</option>
                  </Input>
                </FormGroup>
                <FormGroup tag="fieldset" className="mb-0" row>
                  <legend className="col-form-label py-0 font-weight-bold">
                    Interval
                  </legend>
                  <Input
                    name="interval"
                    value={dataInterval}
                    onChange={handleSetDataInterval}
                    type="select"
                  >
                    <option value="month">Monthly</option>
                    <option value="week">Weekly</option>
                    <option value="day">Daily</option>
                  </Input>
                </FormGroup>
                <div className="text-center pt-4">
                  <Button type="button" onClick={filterData}>
                    Filter
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          <div className="d-flex">
            <div className="w-50 mb-0">
              <VerticalBarChart {...investmentChartProps} />
            </div>
            <div className="w-50">
              <VerticalBarChart {...referralChartProps} />
            </div>
          </div>
        </Card>
      </Col>

      <Col className="pr-0" sm="4">
        <Card body className="h-100">
          {locationChartProps.labels.length ? (
            <>
              <CardTitle>Member locations</CardTitle>
              <div className="my-auto">
                <DoughnutChart
                  {...locationChartProps}
                  centerText={`${locationChartData.members} members`}
                />
              </div>
            </>
          ) : (
            loaderOrNoResult(locationChartDataRequesting)
          )}
        </Card>
      </Col>
    </>
  )
}

export default DashboardHomeCharts
