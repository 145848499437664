import referralsReducer from './referrals.reducer'
import {
  FetchReferralsStartAction,
  FetchReferralsFailureAction,
  FetchReferralsSuccessAction,
  SetReferralsMetaAction,
  FetchReferredUsersStartAction,
  FetchReferredUsersSuccessAction,
  FetchReferredUsersFailureAction,
  SetReferredUsersMetaAction,
  FetchReferralChartDataStartAction,
  FetchReferralChartDataSuccessAction,
  FetchReferralChartDataFailureAction,
  SetSearchReferredUsersMetaAction,
  SearchReferredUsersSuccessAction,
  ClearSearchReferredUsersAction,
  SearchReferredUsersStartAction,
  SearchReferredUsersFailureAction,
} from './referrals-actions.types'

export type Referral = {
  id: number
  name: string
  status: string
}

export type ReferralsDictionary = {
  [key: string]: Referral
}

export type ReferralsIdList = string[]

export type NormalizedReferrals = {
  entities: {
    referrals: ReferralsDictionary
  }
  result: ReferralsIdList
}

export type ReferredUser = {
  Amount: number
  Bond: {
    [key: number]: {
      bond_name: string
      id: number
      purchase_date: string
      reward: number
      series_name: string
      status: string
      units: number
    }
  }
  Date_joined: string
  Location: string
  Membership: string
  Name: string
  Referrals: number
  Referrals_by: number
  Referrals_by_id: number
  Status: string
  Unit: number
  user_id: number
  id: number
}

export type ReferredUsersDictionary = {
  [key: string]: ReferredUser
}

export type ReferredUsersIdList = string[]

export type NormalizedReferredUsers = {
  entities: {
    referredUsers: ReferredUsersDictionary
  }
  result: ReferredUsersIdList
}

export enum ReferralStatus {
  pending,
  active,
}

export type ReferralData = {
  date: string
  status: string
}

export type ReferralsRequestingStateActions =
  | FetchReferralsStartAction
  | FetchReferralsSuccessAction
  | FetchReferralsFailureAction

export type ReferredUsersRequestingStateActions =
  | FetchReferredUsersStartAction
  | FetchReferredUsersSuccessAction
  | FetchReferredUsersFailureAction
  | SearchReferredUsersStartAction
  | SearchReferredUsersSuccessAction
  | SearchReferredUsersFailureAction

export type ReferredUsersModelUpdateMetaActions =
  | SetReferredUsersMetaAction
  | SetSearchReferredUsersMetaAction

export type ReferredUsersModelUpdateStateActions =
  | FetchReferredUsersSuccessAction
  | SearchReferredUsersSuccessAction
  | ClearSearchReferredUsersAction

export type ReferralChartDataRequestingStateActions =
  | FetchReferralChartDataStartAction
  | FetchReferralChartDataSuccessAction
  | FetchReferralChartDataFailureAction

export type ReferralsState = ReturnType<typeof referralsReducer>

export type ReferralsActionTypes =
  | ReferralsRequestingStateActions
  | ReferredUsersRequestingStateActions
  | FetchReferralsSuccessAction
  | ReferredUsersModelUpdateMetaActions
  | ReferredUsersModelUpdateStateActions
  | SetReferralsMetaAction
  | ReferralChartDataRequestingStateActions
