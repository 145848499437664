import styled from 'styled-components/macro'

export const ErrorImageOverlay = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ErrorImageContainer = styled.div<{imageUrl: string}>`
  display: inline-block;
  background-image: ${(props): string => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  width: 40vh;
  height: 40vh;
`

export const ErrorImageText = styled.h2`
  font-size: 3rem;
  color: #333;
  text-align: center;
`
