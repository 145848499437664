import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {
  FETCH_MEMBERS_START,
  FETCH_MEMBER_DETAILS_START,
  FETCH_MEMBER_INVESTMENTS_START,
  FETCH_MEMBER_REFERRED_START,
  FETCH_LOCATION_CHART_DATA_START,
  FETCH_MEMBER_RETURNS_START,
  FETCH_MEMBER_REWARDS_START,
} from './members.constants'
import {
  fetchMembersStartSaga,
  FetchLocationChartDataStartSaga,
} from './all/members-all.sagas'
import {
  fetchMemberDetailsStartSaga,
  fetchMemberInvestmentsStartSaga,
  fetchMemberReferredStartSaga,
  fetchMemberReturnsStartSaga,
  fetchMemberRewardsStartSaga,
} from './single/members-single.sagas'

export function* onFetchMembersStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBERS_START, fetchMembersStartSaga)
}

export function* onFetchMemberDetailsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBER_DETAILS_START, fetchMemberDetailsStartSaga)
}

export function* onFetchMemberInvestmentsStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_MEMBER_INVESTMENTS_START,
    fetchMemberInvestmentsStartSaga,
  )
}

export function* onFetchMemberReferredStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBER_REFERRED_START, fetchMemberReferredStartSaga)
}

export function* onFetchMemberReturnsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBER_RETURNS_START, fetchMemberReturnsStartSaga)
}

export function* onFetchMemberRewardsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBER_REWARDS_START, fetchMemberRewardsStartSaga)
}

export function* onFetchLocationDataStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_LOCATION_CHART_DATA_START,
    FetchLocationChartDataStartSaga,
  )
}

export function* membersSagas(): SagaIterator<void> {
  yield all([
    call(onFetchMembersStart),
    call(onFetchMemberDetailsStart),
    call(onFetchMemberInvestmentsStart),
    call(onFetchMemberReferredStart),
    call(onFetchMemberReturnsStart),
    call(onFetchMemberRewardsStart),
    call(onFetchLocationDataStart),
  ])
}
