import React, {useState} from 'react'
import {DateUtils} from 'react-day-picker'
import {ChartProps} from 'components/common/charts/types'

const now = new Date()
const currYear = now.getFullYear()
const currMonth = now.getMonth()
const currDay = now.getDate()

export const useChartFilter = (): {
  locationChartProps: ChartProps
  setLocationChartProps: React.Dispatch<React.SetStateAction<ChartProps>>
  investmentChartProps: ChartProps
  setInvestmentChartProps: React.Dispatch<React.SetStateAction<ChartProps>>
  referralChartProps: ChartProps
  setReferralChartProps: React.Dispatch<React.SetStateAction<ChartProps>>
  dataInterval: string
  chart: string
  shouldFilter: boolean
  setShouldFilter: React.Dispatch<React.SetStateAction<boolean>>
  filterData(): void
  handleSetChart(event: React.ChangeEvent<HTMLInputElement>): void
  handleSetDataInterval(event: React.ChangeEvent<HTMLInputElement>): void
  from: Date
  to: Date
  handleDayClick(day: Date): void
} => {
  const [locationChartProps, setLocationChartProps] = useState<ChartProps>({
    labels: [],
    datasets: [],
  })
  const [investmentChartProps, setInvestmentChartProps] = useState<ChartProps>({
    labels: [],
    datasets: [],
  })
  const [referralChartProps, setReferralChartProps] = useState<ChartProps>({
    labels: [],
    datasets: [],
  })
  const [{from, to}, setRange] = useState<{
    from: Date
    to: Date
  }>({
    from: new Date(currYear, currMonth, 1),
    to: new Date(currYear, currMonth, currDay),
  })

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, {
      from,
      to,
    })
    setRange(range)
  }
  const [dataInterval, setDataInterval] = useState('month')
  const [chart, setChart] = useState('bonds')
  const [shouldFilter, setShouldFilter] = useState(false)

  const filterData = () => {
    setShouldFilter(true)
    setTimeout(() => {
      setShouldFilter(false)
    }, 1000)
  }

  const handleSetChart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setChart(value)
  }

  const handleSetDataInterval = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {value} = event.target
    setDataInterval(value)
  }

  return {
    locationChartProps,
    setLocationChartProps,
    investmentChartProps,
    setInvestmentChartProps,
    referralChartProps,
    setReferralChartProps,
    dataInterval,
    chart,
    shouldFilter,
    setShouldFilter,
    filterData,
    handleSetChart,
    handleSetDataInterval,
    from,
    to,
    handleDayClick,
  }
}
