import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import MembersService from 'services/api/members/service'
import {normalize} from 'normalizr'
import {FetchMembersStartAction} from './members-all-actions.types'
import {
  fetchMembersFailure,
  fetchMembersSuccess,
  setMembersMeta,
  fetchLocationChartDataSuccess,
  fetchLocationChartDataFailure,
} from './members-all.actions'

export function* fetchMembersStartSaga({
  payload: {page},
}: FetchMembersStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data: members, pages},
    } = yield call([membersService, 'fetchMembers'], page)
    const normalizedMembers = yield call(
      normalize,
      members,
      schema.arrayOfMembers,
    )
    yield put(fetchMembersSuccess(normalizedMembers))
    yield put(setMembersMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchMembersFailure())
  }
}

export function* FetchLocationChartDataStartSaga(): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data,
    } = yield call([membersService, 'getLocationData'])
    yield put(fetchLocationChartDataSuccess(data))
  } catch (reason) {
    yield put(fetchLocationChartDataFailure())
    log.warn(reason)
  }
}
