import {usePagination} from 'components/common/hooks/use-pagination.hook'
import {fetchBondsStart} from '_redux/bonds/all/bonds-all.actions'
import {fetchReferralsStart} from '_redux/referrals/referrals.actions'
import {fetchMembersStart} from '_redux/members/all/members-all.actions'
import {useSelector} from 'react-redux'
import {
  selectMembersRequesting,
  selectMembers,
  selectMembersMeta,
} from '_redux/members/members.selectors'
import {
  selectBonds,
  selectBondsMeta,
  selectBondsRequesting,
} from '_redux/bonds/bonds.selectors'
import {
  selectReferralsRequesting,
  selectReferralsMeta,
  selectReferrals,
} from '_redux/referrals/referrals.selectors'
import {Bond} from '_redux/bonds/single/bonds-single.types'
import {Referral} from '_redux/referrals/referrals.types'
import {Member} from '_redux/members/single/members-single.types'

export function useHomeData(): {
  currentBondsPage: number
  loadMoreBonds: () => void
  currentReferralsPage: number
  loadMoreReferrals: () => void
  currentMembersPage: number
  loadMoreMembers: () => void
  bondsRequesting: boolean
  bondsPages: number
  bondsTotal?: number
  bonds: {
    bond: Bond
    bondSeriesKeys: string[]
  }[]
  referralsRequesting: boolean
  referralsPages: number
  referrals: Referral[]
  membersRequesting: boolean
  membersPages: number
  members: Member[]
} {
  const [currentBondsPage, loadMoreBonds] = usePagination(fetchBondsStart)
  const [currentReferralsPage, loadMoreReferrals] = usePagination(
    fetchReferralsStart,
  )
  const [currentMembersPage, loadMoreMembers] = usePagination(fetchMembersStart)

  const bondsRequesting = useSelector(selectBondsRequesting)
  const {pages: bondsPages, total: bondsTotal} = useSelector(selectBondsMeta)
  const bonds = useSelector(selectBonds)

  const referralsRequesting = useSelector(selectReferralsRequesting)
  const {pages: referralsPages} = useSelector(selectReferralsMeta)
  const referrals = useSelector(selectReferrals)

  const membersRequesting = useSelector(selectMembersRequesting)
  const {pages: membersPages} = useSelector(selectMembersMeta)
  const members = useSelector(selectMembers)

  return {
    currentBondsPage,
    loadMoreBonds,
    currentReferralsPage,
    loadMoreReferrals,
    currentMembersPage,
    loadMoreMembers,
    bondsRequesting,
    bondsPages,
    bondsTotal,
    bonds,
    referralsRequesting,
    referralsPages,
    referrals,
    membersRequesting,
    membersPages,
    members,
  }
}
