import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_MEMBERS_START,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  SET_MEMBERS_META,
  FETCH_LOCATION_CHART_DATA_START,
  FETCH_LOCATION_CHART_DATA_SUCCESS,
  FETCH_LOCATION_CHART_DATA_FAILURE,
} from '../members.constants'
import {MembersActionTypes} from '../members.types'
import {NormalizedMembers, LocationData} from './members-all.types'

export function fetchMembersStart(
  data: DashboardModelQuery,
): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_START,
    payload: data,
  }
}

export function fetchMembersSuccess(
  data: NormalizedMembers,
): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    payload: data,
  }
}

export function fetchMembersFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_FAILURE,
  }
}

export function setMembersMeta(meta: DashboardModelMeta): MembersActionTypes {
  return {
    type: SET_MEMBERS_META,
    payload: meta,
  }
}

export function fetchLocationChartDataStart(): MembersActionTypes {
  return {
    type: FETCH_LOCATION_CHART_DATA_START,
  }
}
export function fetchLocationChartDataSuccess(
  data: LocationData,
): MembersActionTypes {
  return {
    type: FETCH_LOCATION_CHART_DATA_SUCCESS,
    payload: data,
  }
}
export function fetchLocationChartDataFailure(): MembersActionTypes {
  return {
    type: FETCH_LOCATION_CHART_DATA_FAILURE,
  }
}
