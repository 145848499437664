import * as Yup from 'yup'
import {parseISO, isAfter} from 'date-fns'

export const EditBondDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  location: Yup.string()
    .min(2, 'Too Short!')
    .max(256, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(1024, 'Too Long!')
    .required('Required'),
})

export const EditBondSeriesSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  offer: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  tenor: Yup.number().integer('Must be whole number'),
  interest: Yup.number().integer('Must be whole number'),
  units: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  min_unit: Yup.number()
    .test(
      'test-unit',
      'Min. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  max_unit: Yup.number()
    .test(
      'test-maxUnit',
      'Max. units must be greater than min. units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.min_unit < value
      },
    )
    .test(
      'test-unit',
      'Max. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  unit_price: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  interest_paid: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .when('opportunityType', {
      is: val => val === 'investment',
      then: Yup.number().required(),
      otherwise: Yup.number().notRequired(),
    }),
  security: Yup.string()
    .trim()
    .when('opportunityType', {
      is: val => val === 'investment',
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  start_date: Yup.mixed().required('Required'),
  close_date: Yup.mixed()
    .test(
      'test-startDate',
      'End date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .required('Required'),
  maturity_date: Yup.mixed()
    .test(
      'test-closeDate',
      'Maturity date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .test(
      'test-maturityDate',
      'Maturity date must be after close date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.close_date))
      },
    )
    .when('opportunityType', {
      is: val => val === 'investment',
      then: Yup.mixed().required(),
      otherwise: Yup.mixed().notRequired(),
    }),
  estimate: Yup.number(),
  actual: Yup.number(),
  annual: Yup.number(),
  life_of_issue: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  opportunity_type: Yup.string().required('Required'),
  video_type: Yup.string(),
  video_link: Yup.string(),
})

export const CreateBondSeriesSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  offer: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  tenor: Yup.number().integer('Must be whole number'),
  interest: Yup.number().integer('Must be whole number'),
  units: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  min_unit: Yup.number()
    .test(
      'test-unit',
      'Min. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  max_unit: Yup.number()
    .test(
      'test-maxUnit',
      'Max. units must be greater than min. units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.min_unit < value
      },
    )
    .test(
      'test-unit',
      'Max. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  unit_price: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  interest_paid: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .when('opportunityType', {
      is: val => val === 'investment',
      then: Yup.number().required(),
      otherwise: Yup.number().notRequired(),
    }),
  security: Yup.string()
    .trim()
    .when('opportunityType', {
      is: val => val === 'investment',
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  start_date: Yup.mixed().required('Required'),
  close_date: Yup.mixed()
    .test(
      'test-startDate',
      'End date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .required('Required'),
  maturity_date: Yup.mixed().when('opportunityType', {
    is: val => val === 'investment',
    then: Yup.mixed()
      .test(
        'test-closeDate',
        'Maturity date must be after start date',
        function validate(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return isAfter(parseISO(value), parseISO(this.parent.start_date))
        },
      )
      .test(
        'test-maturityDate',
        'Maturity date must be after close date',
        function validate(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return isAfter(parseISO(value), parseISO(this.parent.close_date))
        },
      )
      .required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  estimate: Yup.number(),
  actual: Yup.number(),
  annual: Yup.number(),
  life_of_issue: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  opportunity_type: Yup.string().required('Required'),
  video_type: Yup.string(),
  video_link: Yup.string(),
})

export type EditBondDetailsFormProps = Yup.InferType<
  typeof EditBondDetailsSchema
>
export type EditBondSeriesFormProps = Yup.InferType<typeof EditBondSeriesSchema>
export type CreateBondSeriesFormProps = Yup.InferType<
  typeof CreateBondSeriesSchema
>
