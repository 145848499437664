import {useState, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {BondsActionTypes} from '_redux/bonds/bonds.types'
import {ReferralsActionTypes} from '_redux/referrals/referrals.types'
import {MembersActionTypes} from '_redux/members/members.types'
import {AssociatesActionTypes} from '_redux/associates/associates.types'
import {RewardsActionTypes} from '_redux/rewards/rewards.types'
import {AdsActionTypes} from '_redux/ads/ads.types'
import {ReturnsActionTypes} from '_redux/returns/returns.types'
import {WithdrawalsActionTypes} from '_redux/withdrawals/withdrawals.types'
import {DashboardModelQuery} from '../types/dashboard-models.types'

type FetchDataActions =
  | BondsActionTypes
  | ReferralsActionTypes
  | MembersActionTypes
  | AssociatesActionTypes
  | RewardsActionTypes
  | AdsActionTypes
  | ReturnsActionTypes
  | WithdrawalsActionTypes

export function usePagination(
  action: (data: DashboardModelQuery) => FetchDataActions,
  extraQueryParams?: Partial<DashboardModelQuery>,
): [
  number,
  () => void,
  () => void,
  (qParams: Partial<DashboardModelQuery>) => void,
] {
  const [currentPage, setCurrentPage] = useState(1)
  const [extraParams, setExtraParams] = useState(extraQueryParams)
  const loadMore = (): void => {
    setCurrentPage(currentPage + 1)
  }
  const resetCurrentPage = () => {
    setCurrentPage(1)
  }
  const setExtraQueryParams = (qParams: Partial<DashboardModelQuery>) => {
    setExtraParams(qParams)
  }

  const dispatch = useDispatch()
  const cb = useRef(action)
  useEffect(() => {
    const fetchFunction = cb.current
    const query = extraParams
      ? {page: currentPage, ...extraParams}
      : {page: currentPage}
    dispatch(fetchFunction(query))
  }, [dispatch, currentPage, cb, extraParams])

  return [currentPage, loadMore, resetCurrentPage, setExtraQueryParams]
}
