import * as Yup from 'yup'

export const AddBondSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  location: Yup.string()
    .min(2, 'Too Short!')
    .max(256, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(1024, 'Too Long!')
    .required('Required'),
})

export type AddBondFormProps = Yup.InferType<typeof AddBondSchema>
