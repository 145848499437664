import React from 'react'
import {ThemeProvider} from 'styled-components'

export interface Space {
  NONE: string
  XS: string
  S: string
  M: string
  L: string
  XL: string
  XXL: string
  XXXL: string
}

export const space: Space = {
  NONE: '0',
  XS: '0.5rem',
  S: '1.2rem',
  M: '1.5rem',
  L: '3rem',
  XL: '4rem',
  XXL: '5rem',
  XXXL: '7rem',
}

export const theme = {
  colors: {
    lightPrimary: '#dd3e3f',
    primary: '#D50F10',
    darkPrimary: '#bf0d0e',
    pinkRed: '#fbeaec',
    darkPinkRed: '#F49698',
    gray: '#F5F3F3',
  },
  fontSizes: {
    small: '1rem',
    medium: '1.2rem',
    large: '2rem',
    xLarge: '3rem',
  },
  fontFamily: 'Nunito Sans, sans-serif',
  space,
}

const AppTheme: React.FC = ({children}) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default AppTheme
