import format from 'date-fns/format'
import {formatDate} from 'helpers/utils'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from 'components/common/types/table-body.types'
import {Withdrawal} from '_redux/withdrawals/withdrawals.types'

export const transformWithdrawalsForTable = (
  withdrawals: Withdrawal[],
  tab: string | undefined,
): TableBodyData[] => {
  const filteredwithdrawals = tab
    ? withdrawals.filter(withdrawal => withdrawal.status === tab)
    : withdrawals

  return filteredwithdrawals.map(withdrawal => ({
    key: withdrawal?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },
    data: [
      {value: withdrawal?.id ?? '-'},
      {
        value: withdrawal?.Name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${withdrawal.user_id}`,
      },
      {
        value:
          withdrawal?.amount === 0
            ? withdrawal.amount
            : `£${withdrawal.amount.toLocaleString()}`,
      },
      {
        value: format(formatDate(withdrawal?.date), 'd MMMM yyyy'),
      },
      {
        value: withdrawal?.type ?? '-',
      },
      {
        value: withdrawal?.status ?? '-',
      },
    ],
  }))
}
