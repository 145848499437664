import styled from 'styled-components/macro'
import {Row, Button} from 'reactstrap'

export const StyledSettingsTableRow = styled(Row)`
  & table {
    font-size: 13px;

    & .custom-control-input:checked ~ .custom-control-label::before {
      border-color: ${(props): string => `${props.theme.colors.primary}`};
      background-color: ${(props): string => `${props.theme.colors.primary}`};
    }
  }

  .card.card-body {
    height: 470px;
    overflow-y: scroll;
  }
`
export const CustomButton = styled(Button)`
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${({color}) => color || '#D6D3D2'};
  border-color: ${({color}) => color || '#D6D3D2'};
`
export const TextArea = styled.textarea.attrs({
  className: 'form-control bg-light border-0 my-2 ',
})`
  background-color: ${({theme}) => theme.colors.gray} !important;
  padding: 20px;
  margin: 0;
  resize: none;
`
