import {Returns} from '_redux/returns/returns.types'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from 'components/common/types/table-body.types'

export const transformReturnsForTable = (
  returns: Returns[],
  tab: string | undefined,
): TableBodyData[] => {
  const filteredReturns = tab
    ? returns.filter(singleReturn => singleReturn.Membership === tab)
    : returns

  return filteredReturns.map(singleReturn => ({
    key: singleReturn?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },
    data: [
      {value: singleReturn?.id ?? '-'},
      {
        value: singleReturn?.Name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${singleReturn.user_id}`,
      },
      {value: singleReturn?.Membership ?? '-'},
      {value: singleReturn?.Location ?? '-'},
      {
        value:
          singleReturn?.Returns_Amount === 0
            ? singleReturn.Returns_Amount
            : `£${singleReturn.Returns_Amount.toLocaleString()}`,
      },
    ],
  }))
}
