import {theme} from 'AppTheme'
import {removeCookie} from 'helpers/utils'
import React from 'react'
import {IconContext} from 'react-icons'
import {FiPower} from 'react-icons/fi'
import {useHistory} from 'react-router'
import {Button} from 'reactstrap'

const Footer = (): JSX.Element => {
  const history = useHistory()
  const handleLogout = () => {
    removeCookie('soarRisingToken')
    history.push('/login')
  }
  return (
    <footer className="d-flex fixed align-items-center py-2">
      <h6
        onClick={handleLogout}
        className="text-danger mt-2 font-weight-medium"
      >
        Logout
      </h6>
      <Button onClick={handleLogout} color="transparent">
        <IconContext.Provider
          value={{
            color: theme.colors.primary,
            style: {verticalAlign: 'sub'},
          }}
        >
          <FiPower />
        </IconContext.Provider>
      </Button>
    </footer>
  )
}

export default Footer
