import styled, {createGlobalStyle} from 'styled-components/macro'

export const GlobalStyle = createGlobalStyle`
  .fontfamily-loaded {
  html,
  body {
    font-family: ${props => props.theme.fontFamily};
    }
  }
  
  html,
  body {
    font-family: sans-serif;
  }
`

export const AppContainer = styled.div`
  overflow-x: hidden;
`
