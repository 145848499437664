import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {SetAdsMetaAction, FetchAdsSuccessAction} from './ads-actions.types'

import {
  FETCH_ADS_START,
  FETCH_ADS_SUCCESS,
  FETCH_ADS_FAILURE,
  SET_ADS_META,
  FETCH_AD_SUCCESS,
  FETCH_AD_START,
  FETCH_AD_FAILURE,
} from './ads.constants'

import {
  AdsRequestingStateActions,
  AdsStateActions,
  AdsDictionary,
  AdsIdList,
  Ad,
} from './ads.types'

export function adsById(
  state: AdsDictionary = {},
  {type, payload}: FetchAdsSuccessAction,
): AdsDictionary {
  switch (type) {
    case FETCH_ADS_SUCCESS: {
      return {...state, ...payload?.entities.ads}
    }
    default:
      return state
  }
}

export function allAdsIds(
  state: AdsIdList = [],
  {type, payload}: FetchAdsSuccessAction,
): AdsIdList {
  switch (type) {
    case FETCH_ADS_SUCCESS: {
      const stateCopy = [...state, ...payload.result]
      return Array.from(new Set(stateCopy))
    }
    default:
      return state
  }
}

export function adsRequesting(
  state = true,
  {type}: AdsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_ADS_START:
      return true
    case FETCH_ADS_SUCCESS:
    case FETCH_ADS_FAILURE:
      return false
    default:
      return state
  }
}

export function adsMeta(
  state: DashboardModelMeta = {pages: 0, total: 0},
  {type, payload}: SetAdsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_ADS_META:
      return payload
    default:
      return state
  }
}

export function currentAd(
  state = {requesting: false, details: {} as Ad},
  action: AdsStateActions,
): {details: Ad; requesting: boolean} {
  switch (action.type) {
    case FETCH_AD_START:
      return {...state, requesting: true}
    case FETCH_AD_SUCCESS:
      return {details: action.payload, requesting: false}
    case FETCH_AD_FAILURE:
      return {...state, requesting: false}
    default:
      return state
  }
}

const adsReducer = combineReducers({
  byId: adsById,
  allIds: allAdsIds,
  meta: adsMeta,
  requesting: adsRequesting,
  current: currentAd,
})

export default adsReducer
