import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {WithdrawalsState} from './withdrawals.types'

function getWithdrawals(state: AppState): WithdrawalsState {
  return state.withdrawals
}

export const selectWithdrawalsRequesting = createSelector(
  getWithdrawals,
  withdrawals => withdrawals.requesting,
)

export const selectWithdrawalsMeta = createSelector(
  getWithdrawals,
  withdrawals => withdrawals.meta,
)

export const selectWithdrawals = createSelector(getWithdrawals, withdrawals =>
  withdrawals.allIds.map(id => withdrawals.byId[id]),
)
