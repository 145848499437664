import React, {FC} from 'react'
import ActionBar from 'components/common/action-bar.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import {Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import {useSelector} from 'react-redux'
import {fetchReturnsStart} from '_redux/returns/returns.actions'
import {
  selectReturnsMeta,
  selectReturnsRequesting,
  selectReturns,
} from '_redux/returns/returns.selectors'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {BasicTable as ReturnsTable} from 'components/common/tables'
import {AssociatesMembership} from '_redux/associates/associates.types'
import {transformReturnsForTable} from './utils'

const membershipValues = {
  'bond holder': 'FUND HOLDER',
  green: 'GREEN Associate',
  silver: 'SILVER Associate',
  gold: 'GOLD Associate',
  platinum: 'PLATINUM Associate',
  'platinum plus': 'PLATINUM Plus Associate',
  'brand ambassador': 'AMBASSADOR',
}

const DashboardReturns: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [
    currentReturnsPage,
    loadMoreReturns,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchReturnsStart)

  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/(all|deleted account)/.test(lowercaseTab)) {
      setExtraQueryParams({query: AssociatesMembership[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const returnsRequesting = useSelector(selectReturnsRequesting)
  const {pages: returnsPages} = useSelector(selectReturnsMeta)
  const returns = useSelector(selectReturns)
  const transformedReturns: TableBodyData[] = transformReturnsForTable(
    returns,
    membershipValues[tab],
  )

  return (
    <AdminLayout pageTitle="Returns">
      <Row>
        <ActionBar headerText="Returns" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={[
              'All',
              'Bond Holder',
              'Green',
              'Silver',
              'Gold',
              'Platinum',
              'Platinum Plus',
              'Brand Ambassador',
              'Deleted Accounts',
            ]}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>
      {returns.length ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              loaded={transformedReturns.length}
              total={transformedReturns.length}
              sortFilter={false}
            />
            <div>
              <ReturnsTable
                headers={['', 'ID', 'Name', 'Membership', 'Location', 'Amount']}
                loading={returnsRequesting}
                page={currentReturnsPage}
                body={transformedReturns}
                hasMore={currentReturnsPage < returnsPages}
                loadMore={loadMoreReturns}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={returnsRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardReturns
