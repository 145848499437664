import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_RETURNS_START,
  FETCH_RETURNS_SUCCESS,
  FETCH_RETURNS_FAILURE,
  SET_RETURNS_META,
} from './returns.constants'
import {
  ReturnsDictionary,
  ReturnsIdList,
  ReturnsRequestingStateActions,
} from './returns.types'
import {
  SetReturnsMetaAction,
  FetchReturnsSuccessAction,
} from './returns-actions.types'

function returnsById(
  state: ReturnsDictionary = {},
  {type, payload}: FetchReturnsSuccessAction,
): ReturnsDictionary {
  switch (type) {
    case FETCH_RETURNS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.returns}
      }
      return state
    }
    default:
      return state
  }
}

function allReturnsIds(
  state: ReturnsIdList = [],
  {type, payload}: FetchReturnsSuccessAction,
): ReturnsIdList {
  switch (type) {
    case FETCH_RETURNS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function returnsMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetReturnsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_RETURNS_META:
      return payload
    default:
      return state
  }
}

function returnsRequesting(
  state = true,
  {type}: ReturnsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_RETURNS_START:
      return true
    case FETCH_RETURNS_SUCCESS:
    case FETCH_RETURNS_FAILURE:
      return false
    default:
      return state
  }
}

const returnsReducer = combineReducers({
  byId: returnsById,
  allIds: allReturnsIds,
  meta: returnsMeta,
  requesting: returnsRequesting,
})

export default returnsReducer
