import {format} from 'date-fns'
import React, {FC} from 'react'
import {MemberInvestments} from '_redux/members/single/members-single.types'
import {formatDate} from '../../../helpers/utils'

type Props = {
  investmentHistory: {
    date: string
    units: string
    single_return: number
  }
}

const InvestmentPurchaseHistoryRow: FC<Props> = ({investmentHistory}) => {
  const historyListKeys = Object.keys(investmentHistory)
  return (
    <>
      <tr className="bg-light">
        <td />
        <td />
        <td />
        <td />
        <td className="font-weight-bold">Purchase Date</td>
        <td />
        <td />
        <td className="font-weight-bold">Units</td>
        <td className="font-weight-bold">Single Return</td>
        <td />
      </tr>
      {historyListKeys.map(key => {
        const {date, units, single_return} = investmentHistory[
          parseInt(key, 10)
        ]
        return (
          <tr className="bg-light" key={key}>
            <td />
            <td />
            <td />
            <td />
            <td>
              {date
                ? format(
                    // Date comes in dd.mm.yyyy format instead of dd-mm-yy
                    formatDate(date),
                    'd MMMM yyyy',
                  )
                : '-'}
            </td>
            <td />
            <td />
            <td>{units}</td>
            <td>£{single_return}</td>
            <td />
          </tr>
        )
      })}
    </>
  )
}

export default InvestmentPurchaseHistoryRow

export const purchaseHistoryRowsForInvestmentsTable = (
  investments: Array<MemberInvestments>,
): {
  [key: string]: JSX.Element
} => {
  const investmentData: {[key: string]: JSX.Element} = {}
  investments.forEach(investment => {
    const data = (
      <InvestmentPurchaseHistoryRow investmentHistory={investment.history} />
    )
    investmentData[`${investment.id}`] = data
  })
  return investmentData
}
