import React, {FC} from 'react'
import {Formik, FormikHelpers} from 'formik'
import {loginUserStart} from '_redux/user/auth/user-auth.actions'
import {useDispatch} from 'react-redux'
import {Col} from 'reactstrap'
import {toast} from 'react-toastify'
import LoginForm from './form.component'
import {LoginFormProps, LoginSchema} from './types'

const initialValues: LoginFormProps = {
  email: '',
  password: '',
}

const LoginFormOverview: FC<{redirectTo: string}> = ({redirectTo}) => {
  const dispatch = useDispatch()
  const onClickForgotPassword = () => {
    toast.info('Please contact your administrator to reset your password')
  }
  const handleSubmit = (
    values: LoginFormProps,
    {setSubmitting}: FormikHelpers<LoginFormProps>,
  ): void => {
    dispatch(loginUserStart({...values, redirectTo, setSubmitting}))
  }
  return (
    <Col sm={{size: 6, offset: 3}} className="pb-5">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={LoginSchema}
        children={(props): React.ReactNode => (
          <LoginForm {...props} onClickForgotPassword={onClickForgotPassword} />
        )}
      />
    </Col>
  )
}

export default LoginFormOverview
