import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {
  FETCH_WITHDRAWALS_START,
  APPROVE_WITHDRAWAL,
  DECLINE_WITHDRAWAL,
} from './withdrawals.constants'
import {
  FetchWithdrawalsStartSaga,
  ApproveWithdrawalStartSaga,
  DeclineWithdrawalsStartSaga,
} from './withdrawals.sagas'

export function* onFetchWithdrawalsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_WITHDRAWALS_START, FetchWithdrawalsStartSaga)
}
export function* onApproveWithdrawalsStart(): SagaIterator<void> {
  yield takeLatest(APPROVE_WITHDRAWAL, ApproveWithdrawalStartSaga)
}
export function* onDeclineWithdrawalsStart(): SagaIterator<void> {
  yield takeLatest(DECLINE_WITHDRAWAL, DeclineWithdrawalsStartSaga)
}

export function* withdrawalsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchWithdrawalsStart),
    call(onApproveWithdrawalsStart),
    call(onDeclineWithdrawalsStart),
  ])
}
