import {SagaIterator} from 'redux-saga'
import {takeLatest, takeEvery, all, call} from 'redux-saga/effects'
import {
  FETCH_BONDS_START,
  FETCH_BONDS_SUMMARY_START,
  ADD_BOND_START,
  FETCH_BOND_START,
  EDIT_BOND_DETAILS_START,
  EDIT_BOND_SERIES_START,
  CREATE_BOND_SERIES_START,
  FETCH_BOND_SERIES_INVESTORS_START,
  SET_BOND_SERIES_STATUS_START,
  FETCH_INVESTMENT_CHART_DATA_START,
  DELETE_BOND_START,
} from './bonds.constants'
import {
  FetchBondsStartSaga,
  FetchBondsSummaryStartSaga,
  FetchInvestmentChartDataStartSaga,
} from './all/bonds-all.sagas'
import {
  AddBondStartSaga,
  FetchBondStartSaga,
  EditBondDetailsStartSaga,
  EditBondSeriesStartSaga,
  CreateBondSeriesStartSaga,
  FetchBondSeriesInvestorsStartSaga,
  SetBondSeriesStatusStartSaga,
  DeleteBondStartSaga,
} from './single/bonds-single.sagas'

// TODO: Activate bond series success should refetch bond
export function* onFetchBondsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_BONDS_START, FetchBondsStartSaga)
}

export function* onFetchBondsSummaryStart(): SagaIterator<void> {
  yield takeLatest(FETCH_BONDS_SUMMARY_START, FetchBondsSummaryStartSaga)
}

export function* onDeleteBondStart(): SagaIterator<void> {
  yield takeLatest(DELETE_BOND_START, DeleteBondStartSaga)
}

export function* onAddBondStart(): SagaIterator<void> {
  yield takeLatest(ADD_BOND_START, AddBondStartSaga)
}

export function* onFetchBondStart(): SagaIterator<void> {
  yield takeLatest(FETCH_BOND_START, FetchBondStartSaga)
}

export function* onEditBondDetailStart(): SagaIterator<void> {
  yield takeLatest(EDIT_BOND_DETAILS_START, EditBondDetailsStartSaga)
}

export function* onEditBondSeriesStart(): SagaIterator<void> {
  yield takeLatest(EDIT_BOND_SERIES_START, EditBondSeriesStartSaga)
}

export function* onCreateBondSeriesStart(): SagaIterator<void> {
  yield takeLatest(CREATE_BOND_SERIES_START, CreateBondSeriesStartSaga)
}

export function* onFetchBondSeriesInvestorsStart(): SagaIterator<void> {
  yield takeEvery(
    FETCH_BOND_SERIES_INVESTORS_START,
    FetchBondSeriesInvestorsStartSaga,
  )
}

export function* onSetBondSeriesStatusStart(): SagaIterator<void> {
  yield takeLatest(SET_BOND_SERIES_STATUS_START, SetBondSeriesStatusStartSaga)
}

export function* onFetchInvestmentDataStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_INVESTMENT_CHART_DATA_START,
    FetchInvestmentChartDataStartSaga,
  )
}

export function* bondsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchBondsStart),
    call(onFetchBondsSummaryStart),
    call(onDeleteBondStart),
    call(onAddBondStart),
    call(onFetchBondStart),
    call(onEditBondDetailStart),
    call(onEditBondSeriesStart),
    call(onCreateBondSeriesStart),
    call(onFetchBondSeriesInvestorsStart),
    call(onSetBondSeriesStatusStart),
    call(onFetchInvestmentDataStart),
  ])
}
