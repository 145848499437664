import React, {FC} from 'react'
import ActionBar from 'components/common/action-bar.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import {Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import {useSelector} from 'react-redux'
import {fetchRewardsStart} from '_redux/rewards/rewards.actions'
import {
  selectRewardsMeta,
  selectRewardsRequesting,
  selectRewards,
} from '_redux/rewards/rewards.selectors'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData} from 'components/common/types/table-body.types'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {BasicTable as RewardsTable} from 'components/common/tables'
import {AssociatesMembership} from '_redux/associates/associates.types'
import {transformRewardsForTable} from './utils'

const membershipValues = {
  'bond holder': 'FUND HOLDER',
  green: 'GREEN Associate',
  silver: 'SILVER Associate',
  gold: 'GOLD Associate',
  platinum: 'PLATINUM Associate',
  'platinum plus': 'PLATINUM Plus Associate',
  'brand ambassador': 'AMBASSADOR',
}

const DashboardRewards: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [
    currentRewardsPage,
    loadMoreRewards,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchRewardsStart)

  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/(all|deleted account)/.test(lowercaseTab)) {
      setExtraQueryParams({query: AssociatesMembership[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const rewardsRequesting = useSelector(selectRewardsRequesting)
  const {pages: rewardsPages} = useSelector(selectRewardsMeta)
  const rewards = useSelector(selectRewards)
  const transformedRewards: TableBodyData[] = transformRewardsForTable(
    rewards,
    membershipValues[tab],
  )

  return (
    <AdminLayout pageTitle="Rewards">
      <Row>
        <ActionBar headerText="Rewards" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={[
              'All',
              'Bond Holder',
              'Green',
              'Silver',
              'Gold',
              'Platinum',
              'Platinum Plus',
              'Brand Ambassador',
              'Deleted Accounts',
            ]}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>
      {rewards.length ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              loaded={transformedRewards.length}
              total={transformedRewards.length}
              sortFilter={false}
            />
            <div>
              <RewardsTable
                headers={['', 'ID', 'Name', 'Membership', 'Location', 'Amount']}
                loading={rewardsRequesting}
                page={currentRewardsPage}
                body={transformedRewards}
                hasMore={currentRewardsPage < rewardsPages}
                loadMore={loadMoreRewards}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={rewardsRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardRewards
