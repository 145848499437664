import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import ReferralsService from 'services/api/referrals/service'
import {normalize} from 'normalizr'
import {
  FetchReferralsStartAction,
  FetchReferredUsersStartAction,
  SearchReferredUsersStartAction,
} from './referrals-actions.types'
import {
  fetchReferralsFailure,
  fetchReferralsSuccess,
  setReferralsMeta,
  fetchReferredUsersFailure,
  fetchReferredUsersSuccess,
  setReferredUsersMeta,
  fetchReferralChartDataSuccess,
  fetchReferralChartDataFailure,
  searchReferredUsersFailure,
  setSearchReferredUsersMeta,
  searchReferredUsersSuccess,
} from './referrals.actions'

export function* FetchReferralsStartSaga({
  payload: {page},
}: FetchReferralsStartAction): SagaIterator<void> {
  const referralsService = new ReferralsService()
  try {
    const {
      data: {data: referrals, pages},
    } = yield call([referralsService, 'fetchReferrals'], page)
    const normalizedReferrals = yield call(
      normalize,
      referrals,
      schema.arrayOfReferrals,
    )
    yield put(fetchReferralsSuccess(normalizedReferrals))
    yield put(setReferralsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchReferralsFailure())
  }
}

export function* FetchReferredUsersStartSaga({
  payload: data,
}: FetchReferredUsersStartAction): SagaIterator<void> {
  const referralsService = new ReferralsService()
  try {
    const {
      data: {data: referredUsers, pages},
    } = yield call([referralsService, 'fetchReferredUsers'], data)
    const normalizedReferredUsers = yield call(
      normalize,
      referredUsers,
      schema.arrayOfReferredUsers,
    )
    yield put(fetchReferredUsersSuccess(normalizedReferredUsers))
    yield put(setReferredUsersMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchReferredUsersFailure())
  }
}

export function* FetchReferralChartDataStartSaga(): SagaIterator<void> {
  const referralsService = new ReferralsService()
  try {
    const {
      data: {data},
    } = yield call([referralsService, 'getReferralData'])
    yield put(fetchReferralChartDataSuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchReferralChartDataFailure())
  }
}

export function* SearchReferredUsersStartSaga({
  payload,
}: SearchReferredUsersStartAction): SagaIterator<void> {
  const referralsService = new ReferralsService()
  try {
    const {
      data: {data: referrals, pages},
    } = yield call([referralsService, 'searchReferredUsers'], payload)
    const normalizedReferrals = yield call(
      normalize,
      referrals,
      schema.arrayOfReferredUsers,
    )
    yield put(searchReferredUsersSuccess(normalizedReferrals))
    yield put(setSearchReferredUsersMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(searchReferredUsersFailure())
  }
}
