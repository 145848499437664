import React, {FC} from 'react'
import {Row} from 'reactstrap'
import {Helmet} from 'react-helmet'
import Header from 'components/common/header/overview.component'
import Footer from 'components/common/footer.component'
import {StyledAdminLayoutContainer} from './admin.styles'

type Props = {
  pageTitle: string
}

const AdminLayout: FC<Props> = ({pageTitle, children}) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle} | SoarRising</title>
      </Helmet>
      <StyledAdminLayoutContainer className="d-flex flex-column justify-content-between h-100">
        <div>
          <Row>
            <Header />
          </Row>
          {children}
        </div>
        <Footer />
      </StyledAdminLayoutContainer>
    </>
  )
}

export default AdminLayout
