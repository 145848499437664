import {format, getISOWeek, eachDayOfInterval} from 'date-fns'
import {InvestmentData} from '_redux/bonds/all/bonds-all.types'
import {Bond} from '_redux/bonds/single/bonds-single.types'
import {Referral, ReferralData} from '_redux/referrals/referrals.types'
import {
  titleCaseName,
  numberRange,
  CalendarMonths,
  formatDate,
} from 'helpers/utils'
import {TableBodyData} from 'components/common/types/table-body.types'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {theme} from 'AppTheme'
import {Member} from '_redux/members/single/members-single.types'
import {LocationData} from '_redux/members/all/members-all.types'
import {ChartProps} from 'components/common/charts/types'

export const transformBondsForTable = (
  bonds: {bond: Bond; bondSeriesKeys: string[]}[],
): TableBodyData[] => {
  return bonds.flatMap(({bond, bondSeriesKeys}) => {
    if (!bondSeriesKeys.length) {
      return [
        {
          key: `${bond?.id}` ?? '',
          header: {
            value: `${bond?.bond_name}` ?? '',
            underlineValue: true,
            isLink: true,
            url: `/dashboard/bond-profile/${bond.id}`,
          },
          data: [
            {
              value: '-',
            },
            {
              value: '-',
            },
          ],
        },
      ]
    }
    return bondSeriesKeys.map(seriesKey => ({
      key: `${bond?.id}${seriesKey}` ?? '',
      header: {
        value: `${bond?.bond_name} - (${seriesKey})` ?? '',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/bond-profile/${bond.id}`,
      },
      data: [
        {
          value: `£${
            bond?.series?.[seriesKey]?.unit_price.toLocaleString() ?? '-'
          }`,
        },
        {
          value: `£${
            bond?.series?.[seriesKey].returns.toLocaleString() ?? '-'
          }`,
        },
      ],
    }))
  })
}

export const transformReferralsForTable = (
  referrals: Referral[],
): TableBodyData[] => {
  return referrals.map(referral => ({
    key: referral?.id ?? '',
    header: {
      value: referral?.id.toString() ?? '',
    },
    data: [
      {
        value: referral?.name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${referral.id}`,
      },
      {value: titleCaseName(referral?.status)},
    ],
    disabled: referral?.status !== 'confirmed',
  }))
}

export const transformMembersForTable = (
  members: Member[],
): TableBodyData[] => {
  return members.map(member => ({
    key: member?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },

    data: [
      {
        value: member?.name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${member.id}`,
      },
      {
        value: format(
          // Date comes in mm-dd-yy format instead of dd-mm-yy
          new Date(
            `${member?.status.substring(3, 5)}/${member?.status.substring(
              0,
              2,
            )}/${member?.status.substring(6)} 00:00:00`,
          ),
          'd MMMM yyyy',
        ),
      },
    ],
  }))
}

const now = new Date()
const currYear = now.getFullYear()
const currMonth = now.getMonth()
// const currDay = now.getDate()

const generateMonthLabels = (monthAndYearArr: string[]) => {
  return monthAndYearArr.map(str => {
    let label = ''
    switch (true) {
      case str.startsWith('01'):
        label = `${CalendarMonths[0]} ${str.slice(3)}`
        break
      case str.startsWith('02'):
        label = `${CalendarMonths[1]} ${str.slice(3)}`
        break
      case str.startsWith('03'):
        label = `${CalendarMonths[2]} ${str.slice(3)}`
        break
      case str.startsWith('04'):
        label = `${CalendarMonths[3]} ${str.slice(3)}`
        break
      case str.startsWith('05'):
        label = `${CalendarMonths[4]} ${str.slice(3)}`
        break
      case str.startsWith('06'):
        label = `${CalendarMonths[5]} ${str.slice(3)}`
        break
      case str.startsWith('07'):
        label = `${CalendarMonths[6]} ${str.slice(3)}`
        break
      case str.startsWith('08'):
        label = `${CalendarMonths[7]} ${str.slice(3)}`
        break
      case str.startsWith('09'):
        label = `${CalendarMonths[8]} ${str.slice(3)}`
        break
      case str.startsWith('10'):
        label = `${CalendarMonths[9]} ${str.slice(3)}`
        break
      case str.startsWith('11'):
        label = `${CalendarMonths[10]} ${str.slice(3)}`
        break
      default:
        label = `${CalendarMonths[11]} ${str.slice(3)}`
        break
    }
    return label
  })
}

export const getFilteredMonthsToDisplay = (
  from: {yearFrom: number; monthFrom: number; dayFrom: number},
  to: {yearTo: number; monthTo: number; dayTo: number},
): string[] => {
  const monthsToDisplay = numberRange(
    from.monthFrom,
    from.yearFrom === currYear ? currMonth + 1 : 12,
  )
    .map(num => `${num < 10 ? 0 : ''}${num + 1}.${from.yearFrom}`)
    .concat(
      numberRange(
        to.yearTo === currYear ? from.monthFrom : 0,
        to.monthTo + 1,
      ).map(num => `${num < 10 ? 0 : ''}${num + 1}.${to.yearTo}`),
    )
  return Array.from(new Set(monthsToDisplay))
}

// eslint-disable-next-line max-lines-per-function
export const transformInvestmentDataForChart = (
  investmentData: {[key: string]: InvestmentData[]},
  from: {yearFrom: number; monthFrom: number; dayFrom: number},
  to: {yearTo: number; monthTo: number; dayTo: number},
  labelBy: string,
): ChartProps => {
  let labels: string[] = []
  const investData: number[] = []
  const charityData: number[] = []
  if (!Object.keys(investmentData).length) {
    return {
      labels: [],
      datasets: [],
    }
  }

  if (labelBy === 'month') {
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    labels = generateMonthLabels(filteredMonths)
    let investTotal = 0
    let charityTotal = 0
    filteredMonths.forEach(str => {
      if (investmentData[str]) {
        investmentData[str].forEach(data => {
          if (data.investment_type === 'invest') {
            investTotal = investTotal + data.total_and_fee
          } else {
            charityTotal = charityTotal + data.total_and_fee
          }
        })
        investData.push(investTotal)
        charityData.push(charityTotal)
      } else {
        investData.push(0)
        charityData.push(0)
      }
    })
    return {
      labels,
      datasets: [
        {
          label: 'Total Investments',
          backgroundColor: theme.colors.primary,
          borderColor: theme.colors.primary,
          data: investData,
        },
        {
          label: 'Total Charity',
          backgroundColor: theme.colors.darkPinkRed,
          borderColor: theme.colors.darkPinkRed,
          data: charityData,
        },
      ],
    }
  }

  if (labelBy === 'week') {
    const dateFromWeek = getISOWeek(
      new Date(from.yearFrom, from.monthFrom, from.dayFrom),
    )
    const dateToWeek = getISOWeek(new Date(to.yearTo, to.monthTo, to.dayTo))
    const currentWeek = getISOWeek(now)
    const weeksToDisplay = numberRange(
      dateFromWeek,
      from.yearFrom === currYear ? currentWeek + 1 : 54,
    )
      .map(num => `Wk-${num < 10 ? 0 : ''}${num}.${from.yearFrom}`)
      .concat(
        numberRange(
          to.yearTo === currYear ? dateFromWeek : 1,
          dateToWeek + 1,
        ).map(num => `Wk-${num < 10 ? 0 : ''}${num}.${to.yearTo}`),
      )
    labels = Array.from(new Set(weeksToDisplay))
    const investMap: {[key: number]: number} = {}
    const charityMap: {[key: number]: number} = {}
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    filteredMonths.forEach(str => {
      if (investmentData[str]) {
        investmentData[str].forEach(data => {
          const dateWeek = getISOWeek(formatDate(data.date))
          const weekIndex = labels.findIndex(
            label =>
              label ===
              `Wk-${dateWeek < 10 ? 0 : ''}${dateWeek}.${str.slice(3)}`,
          )
          if (data.investment_type === 'invest') {
            const prevInvestData = investMap[weekIndex] || 0
            investMap[weekIndex] = prevInvestData + data.total_and_fee
          } else {
            const prevCharityData = charityMap[weekIndex] || 0
            charityMap[weekIndex] = prevCharityData + data.total_and_fee
          }
        })
      }
    })

    numberRange(0, labels.length).forEach(num => {
      if (investMap[num]) investData.push(investMap[num])
      else investData.push(0)
      if (charityMap[num]) charityData.push(charityMap[num])
      else charityData.push(0)
    })

    return {
      labels,
      datasets: [
        {
          label: 'Total Investments',
          backgroundColor: theme.colors.primary,
          borderColor: theme.colors.primary,
          data: investData,
        },
        {
          label: 'Total Charity',
          backgroundColor: theme.colors.darkPinkRed,
          borderColor: theme.colors.darkPinkRed,
          data: charityData,
        },
      ],
    }
  }

  if (labelBy === 'day') {
    const daysInInterval = eachDayOfInterval({
      start: new Date(from.yearFrom, from.monthFrom, from.dayFrom),
      end: new Date(to.yearTo, to.monthTo, to.dayTo),
    })
    labels = daysInInterval.map(day => format(new Date(day), 'dd.MM.yyyy'))
    const investMap: {[key: number]: number} = {}
    const charityMap: {[key: number]: number} = {}
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    filteredMonths.forEach(str => {
      if (investmentData[str]) {
        investmentData[str].forEach(data => {
          const dayIndex = labels.findIndex(label => label === data.date)
          if (data.investment_type === 'invest') {
            const prevInvestData = investMap[dayIndex] || 0
            investMap[dayIndex] = prevInvestData + data.total_and_fee
          } else {
            const prevCharityData = charityMap[dayIndex] || 0
            charityMap[dayIndex] = prevCharityData + data.total_and_fee
          }
        })
      }
    })

    numberRange(0, labels.length).forEach(num => {
      if (investMap[num]) investData.push(investMap[num])
      else investData.push(0)
      if (charityMap[num]) charityData.push(charityMap[num])
      else charityData.push(0)
    })

    return {
      labels,
      datasets: [
        {
          label: 'Total Investments',
          backgroundColor: theme.colors.primary,
          borderColor: theme.colors.primary,
          data: investData,
        },
        {
          label: 'Total Charity',
          backgroundColor: theme.colors.darkPinkRed,
          borderColor: theme.colors.darkPinkRed,
          data: charityData,
        },
      ],
    }
  }
  return {
    labels: [],
    datasets: [],
  }
}

export const transformReferralDataForChart = (
  referralData: {[key: string]: ReferralData[]},
  from: {yearFrom: number; monthFrom: number; dayFrom: number},
  to: {yearTo: number; monthTo: number; dayTo: number},
  labelBy: string,
): ChartProps => {
  let labels: string[] = []
  const pendingData: number[] = []
  const verifiedData: number[] = []
  if (!Object.keys(referralData).length) {
    return {
      labels: [],
      datasets: [],
    }
  }

  if (labelBy === 'month') {
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    labels = generateMonthLabels(filteredMonths)
    let verifiedTotal = 0
    let pendingTotal = 0
    filteredMonths.forEach(str => {
      if (referralData[str]) {
        referralData[str].forEach(data => {
          if (data.status === 'verified') {
            verifiedTotal = verifiedTotal + 1
          } else {
            pendingTotal = pendingTotal + 1
          }
        })
        verifiedData.push(verifiedTotal)
        pendingData.push(pendingTotal)
      } else {
        verifiedData.push(0)
        pendingData.push(0)
      }
    })
    return {
      labels,
      datasets: [
        {
          label: 'Total Verified',
          backgroundColor: theme.colors.primary,
          borderColor: theme.colors.primary,
          data: verifiedData,
        },
        {
          label: 'Total Pending',
          backgroundColor: theme.colors.darkPinkRed,
          borderColor: theme.colors.darkPinkRed,
          data: pendingData,
        },
      ],
    }
  }

  if (labelBy === 'week') {
    const dateFromWeek = getISOWeek(
      new Date(from.yearFrom, from.monthFrom, from.dayFrom),
    )
    const dateToWeek = getISOWeek(new Date(to.yearTo, to.monthTo, to.dayTo))
    const currentWeek = getISOWeek(now)
    const weeksToDisplay = numberRange(
      dateFromWeek,
      from.yearFrom === currYear ? currentWeek + 1 : 54,
    )
      .map(num => `Wk-${num < 10 ? 0 : ''}${num}.${from.yearFrom}`)
      .concat(
        numberRange(
          to.yearTo === currYear ? dateFromWeek : 1,
          dateToWeek + 1,
        ).map(num => `Wk-${num < 10 ? 0 : ''}${num}.${to.yearTo}`),
      )
    labels = Array.from(new Set(weeksToDisplay))
    const verifiedMap: {[key: number]: number} = {}
    const pendingMap: {[key: number]: number} = {}
    const filteredMonths = getFilteredMonthsToDisplay(from, to)
    filteredMonths.forEach(str => {
      if (referralData[str]) {
        referralData[str].forEach(data => {
          const dateWeek = getISOWeek(formatDate(data.date))
          const weekIndex = labels.findIndex(
            label =>
              label ===
              `Wk-${dateWeek < 10 ? 0 : ''}${dateWeek}.${str.slice(3)}`,
          )
          if (data.status === 'verified') {
            const prevVerifiedData = verifiedMap[weekIndex] || 0
            verifiedMap[weekIndex] = prevVerifiedData + 1
          } else {
            const prevPendingData = pendingMap[weekIndex] || 0
            pendingMap[weekIndex] = prevPendingData + 1
          }
        })
      }
    })

    numberRange(0, labels.length).forEach(num => {
      if (verifiedMap[num]) verifiedData.push(verifiedMap[num])
      else verifiedData.push(0)
      if (pendingMap[num]) pendingData.push(pendingMap[num])
      else pendingData.push(0)
    })

    return {
      labels,
      datasets: [
        {
          label: 'Total Verified',
          backgroundColor: theme.colors.primary,
          borderColor: theme.colors.primary,
          data: verifiedData,
        },
        {
          label: 'Total Pending',
          backgroundColor: theme.colors.darkPinkRed,
          borderColor: theme.colors.darkPinkRed,
          data: pendingData,
        },
      ],
    }
  }

  return {
    labels: [],
    datasets: [],
  }
}

export const transformLocationDataForChart = (
  locationData: LocationData,
): ChartProps => {
  const labels: string[] = []
  const data: number[] = []
  if (!locationData.members)
    return {
      labels: [],
      datasets: [],
    }

  Object.entries(locationData.locations).forEach(([key, value]) => {
    labels.push(titleCaseName(key))
    data.push(value)
  })

  return {
    labels,
    datasets: [
      {
        label: 'Members',
        backgroundColor: [
          theme.colors.primary,
          theme.colors.darkPinkRed,
          theme.colors.lightPrimary,
          theme.colors.pinkRed,
          theme.colors.darkPrimary,
        ],
        borderColor: [
          theme.colors.primary,
          theme.colors.darkPinkRed,
          theme.colors.lightPrimary,
          theme.colors.pinkRed,
          theme.colors.darkPrimary,
        ],
        data,
      },
    ],
  }
}

export const dummyLabels = ['July 7', 'July 8', 'July 9', 'July 10', 'July 11']
export const dummyLabelsTwo = [
  'Africa',
  'Europe',
  'Asia',
  'Americas',
  'Australia',
]
export const dummyLabelsThree = [
  'Value 1',
  'Value 2',
  'Value 3',
  'Value 4',
  'Value 5',
]

export const dummyData = [
  {
    label: 'Total Investments',
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    data: [2400, 1398, 9800, 9800, 9800],
  },
  {
    label: 'Investment Returns',
    backgroundColor: theme.colors.darkPinkRed,
    borderColor: theme.colors.darkPinkRed,
    data: [4000, 3000, 2000, 2000, 2000],
  },
]
export const dummyDataTwo = [
  {
    label: 'Total References',
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    data: [2400, 1398, 9800, 9800, 9800],
  },
  {
    label: 'References Confirmed',
    backgroundColor: theme.colors.darkPinkRed,
    borderColor: theme.colors.darkPinkRed,
    data: [4000, 3000, 2000, 2000, 2000],
  },
]
export const dummyDataThree = [
  {
    label: 'Members',
    backgroundColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    borderColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    data: [2400, 1398, 9800, 9800, 9800],
  },
]

export const dummyDataFive = [
  {
    label: 'Members',
    backgroundColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    borderColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    data: [9, 8, 8, 7, 0],
  },
]

export const dummyDataFour = [
  {
    label: 'Value X',
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    data: [100, 80, 130, 140, 130],
  },
  {
    label: 'Value Y',
    backgroundColor: theme.colors.darkPinkRed,
    borderColor: theme.colors.darkPinkRed,
    data: [110, 90, 120, 180, 140],
  },
  {
    label: 'Value Z',
    backgroundColor: theme.colors.lightPrimary,
    borderColor: theme.colors.lightPrimary,
    data: [70, 60, 90, 140, 90],
  },
]
