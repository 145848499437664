/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {CancelToken, AxiosResponse} from 'axios'
import {CANCEL} from 'redux-saga'
import {toast} from 'react-toastify'
import {shouldRetryRequest} from './retry-request'

axios.defaults.withCredentials = true
axios.defaults.timeout = 180000
axios.defaults.baseURL = 'https://referral.soarising.com'
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const shouldRetry = shouldRetryRequest(error)
    const originalRequest = error.config
    if (shouldRetry) {
      if (error.response) {
        originalRequest.retry = true
      } else {
        originalRequest.noResponseRetry = true
      }
      return axios(originalRequest)
    }
    if (axios.isCancel(error)) {
      console.log('Request cancelled...')
      return Promise.reject(error)
    }
    // Timeout
    if (error.code === 'ECONNABORTED') {
      toast.error(
        'Your request has timed out. Check your internet connection and try again.',
      )
      return Promise.reject(error)
    }

    let errorValue = error
    if ((!error.response || !error.response.data) && error.message) {
      errorValue = error.message
    }
    errorValue = error.response.data
    if (errorValue.message) {
      errorValue = errorValue.message
    }
    if (errorValue[0] && errorValue[0].message) {
      errorValue = errorValue[0].message
    }
    toast.error(errorValue)

    return Promise.reject(error)
  },
)

type RequestHandlerProps = {
  method: string
  url: string
  data?: {
    [key: string]: any
    cancelToken?: CancelToken
    headers?: any
  }
  options?: {cancelToken?: CancelToken; headers?: any}
}

const requestHandler = ({
  method,
  url,
  data = {cancelToken: undefined},
  options = {cancelToken: undefined},
}: RequestHandlerProps): Promise<AxiosResponse<any>> => {
  const httpMethod = method.toLowerCase()
  const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0
  const settings = hasData ? options : data

  const source = axios.CancelToken.source()
  settings.cancelToken = source.token

  const request = hasData
    ? axios[httpMethod](url, data, settings)
    : axios[httpMethod](url, settings)

  request[CANCEL] = (): void => source.cancel()
  return request
}

export default requestHandler
