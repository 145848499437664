import {
  FETCH_BOND_START,
  FETCH_BOND_SUCCESS,
  FETCH_BOND_FAILURE,
  DELETE_BOND_START,
  ADD_BOND_START,
  EDIT_BOND_DETAILS_START,
  EDIT_BOND_SERIES_START,
  CREATE_BOND_SERIES_START,
  FETCH_BOND_SERIES_INVESTORS_START,
  FETCH_BOND_SERIES_INVESTORS_SUCCESS,
  FETCH_BOND_SERIES_INVESTORS_FAILURE,
  SET_BOND_SERIES_STATUS_START,
  CLEAR_BOND_SERIES_INVESTORS,
  DELETE_BOND_SUCCESS,
} from '../bonds.constants'
import {NormalizedBonds} from '../all/bonds-all.types'
import {BondsActionTypes} from '../bonds.types'
import {
  AddBondCredentials,
  EditBondDetailsCredentials,
  EditBondSeriesCredentials,
  CreateBondSeriesCredentials,
  Investor,
  DeleteBondCredentials,
} from './bonds-single.types'

export function fetchBondStart(id: string): BondsActionTypes {
  return {
    type: FETCH_BOND_START,
    payload: id,
  }
}

export function fetchBondSuccess(data: NormalizedBonds): BondsActionTypes {
  return {
    type: FETCH_BOND_SUCCESS,
    payload: data,
  }
}

export function fetchBondFailure(): BondsActionTypes {
  return {
    type: FETCH_BOND_FAILURE,
  }
}

export function addBondStart(bondData: AddBondCredentials): BondsActionTypes {
  return {
    type: ADD_BOND_START,
    payload: bondData,
  }
}

export function deleteBondStart({
  id,
  redirectTo,
}: DeleteBondCredentials): BondsActionTypes {
  return {
    type: DELETE_BOND_START,
    payload: {id, redirectTo},
  }
}

export function deleteBondSuccess(id: string): BondsActionTypes {
  return {
    type: DELETE_BOND_SUCCESS,
    payload: id,
  }
}

export function editBondDetailsStart(
  bondData: EditBondDetailsCredentials,
): BondsActionTypes {
  return {
    type: EDIT_BOND_DETAILS_START,
    payload: bondData,
  }
}

export function editBondSeriesStart(
  seriesData: EditBondSeriesCredentials,
): BondsActionTypes {
  return {
    type: EDIT_BOND_SERIES_START,
    payload: seriesData,
  }
}

export function createBondSeriesStart(
  seriesData: CreateBondSeriesCredentials,
): BondsActionTypes {
  return {
    type: CREATE_BOND_SERIES_START,
    payload: seriesData,
  }
}

export function fetchBondSeriesInvestorsStart(id: number): BondsActionTypes {
  return {
    type: FETCH_BOND_SERIES_INVESTORS_START,
    payload: id,
  }
}

export function fetchBondSeriesInvestorsSuccess(data: {
  seriesId: string
  investors: Investor[]
}): BondsActionTypes {
  return {
    type: FETCH_BOND_SERIES_INVESTORS_SUCCESS,
    payload: data,
  }
}

export function fetchBondSeriesInvestorsFailure(): BondsActionTypes {
  return {
    type: FETCH_BOND_SERIES_INVESTORS_FAILURE,
  }
}

export function clearBondSeriesInvestors(): BondsActionTypes {
  return {
    type: CLEAR_BOND_SERIES_INVESTORS,
  }
}

export function setBondSeriesStatusStart(data: {
  seriesId: number
  bondId: string
}): BondsActionTypes {
  return {
    type: SET_BOND_SERIES_STATUS_START,
    payload: data,
  }
}
