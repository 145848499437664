import {createSelector, OutputParametricSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {formatDate} from 'helpers/utils'
import {BondsState} from './bonds.types'
import {Bond, Investor} from './single/bonds-single.types'
import {InvestmentData} from './all/bonds-all.types'

function getBonds(state: AppState): BondsState {
  return state.bonds
}

function getCurrentBondData(state: AppState, id: string): Bond {
  return state.bonds.byId[id]
}

function getCurrentBondSeriesInvestorsData(
  state: AppState,
  id: string,
): Investor[] {
  return state.bonds.investors[id]
}

export const makeGetCurrentBondData = (): OutputParametricSelector<
  AppState,
  string,
  Bond,
  (res: Bond) => Bond
> =>
  createSelector([getCurrentBondData], bond => {
    return bond
  })

export const makeGetCurrentBondSeriesInvestorsData = (): OutputParametricSelector<
  AppState,
  string,
  Investor[],
  (res: Investor[]) => Investor[]
> =>
  createSelector([getCurrentBondSeriesInvestorsData], investors => {
    return Array.isArray(investors) ? investors : []
  })

export const selectBondSeriesInvestorsRequesting = createSelector(
  getBonds,
  bonds => bonds.investorsRequesting,
)

export const selectBondsRequesting = createSelector(
  getBonds,
  bonds => bonds.requesting,
)

export const selectBondsMeta = createSelector(getBonds, bonds => bonds.meta)

export const selectBonds = createSelector(getBonds, bonds => {
  return bonds.allIds.map(id => ({
    bond: bonds.byId[id],
    bondSeriesKeys: bonds.byId[id]?.series
      ? Object.keys(bonds.byId[id]?.series)
      : [],
  }))
})

export const selectBondsSummary = createSelector(
  getBonds,
  bonds => bonds.summary,
)

export const selectBondsSummaryRequesting = createSelector(
  getBonds,
  bonds => bonds.summaryRequesting,
)

export const selectInvestmentChartData = createSelector(getBonds, bonds => {
  const sortedInvestments = bonds.chartData.sort((a, b) => {
    const tOne = Date.parse(formatDate(a.date).toString())
    const tTwo = Date.parse(formatDate(b.date).toString())
    return tTwo - tOne
  })
  const monthsMap: {[key: string]: InvestmentData[]} = {}
  for (const investment of sortedInvestments) {
    const monthKey = investment.date.slice(3)
    if (monthsMap[monthKey]) {
      monthsMap[monthKey].push(investment)
    } else {
      monthsMap[monthKey] = [investment]
    }
  }
  return monthsMap
})

export const selectInvestmentChartDataRequesting = createSelector(
  getBonds,
  bonds => bonds.chartDataRequesting,
)
