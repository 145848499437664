import styled from 'styled-components/macro'

export const StyledActionBar = styled.div`
  button {
    & {
      color: rgba(0, 0, 0, 0.5);
    }

    &.focus,
    &:focus {
      text-decoration: none;
      box-shadow: none;
    }

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
`
