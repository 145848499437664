import React, {FC} from 'react'
import {Row} from 'reactstrap'
import LoadingSpinner from './loading-spinner.component'
import EmptyResponse from './empty-response.component'

type Props = {
  loading: boolean
}

const LoadingOrEmptyModel: FC<Props> = ({loading}) => {
  return (
    <Row className="w-50 mx-auto my-auto">
      {loading ? (
        <div className="mx-auto py-5">
          <LoadingSpinner />
        </div>
      ) : (
        <EmptyResponse />
      )}
    </Row>
  )
}

export default LoadingOrEmptyModel
