import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {RewardsState} from './rewards.types'

function getRewards(state: AppState): RewardsState {
  return state.rewards
}

export const selectRewardsRequesting = createSelector(
  getRewards,
  rewards => rewards.requesting,
)

export const selectRewardsMeta = createSelector(
  getRewards,
  rewards => rewards.meta,
)

export const selectRewards = createSelector(getRewards, rewards =>
  rewards.allIds.map(id => rewards.byId[id]),
)
