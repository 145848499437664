import {theme} from 'AppTheme'
import React, {ChangeEvent, FC, useEffect, useState} from 'react'
import {IconContext} from 'react-icons'
import {FaSearch} from 'react-icons/fa'
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap'
import useDebounce from '../hooks/use-debounce.hook'
import {TSortBy} from '../types/table-body.types'
import {CustomInputGroup} from './styles'

const dropdownIconValues = {
  color: theme.colors.primary,
  size: '1.4em',
  style: {verticalAlign: 'middle'},
}

type Props = {
  loaded: number
  total: number
  handleSort?: (e: TSortBy) => void
  handleSearch?: (searchTerm: string) => void
  clearSearchResults?: () => void
  hasSearchResults?: boolean
  inputComponent?: JSX.Element
  searchFilter?: boolean
  sortFilter?: boolean
  tagStatusFilter?: boolean
}

// eslint-disable-next-line max-lines-per-function
const TableFilters: FC<Props> = ({
  loaded,
  searchFilter = true,
  sortFilter = true,
  tagStatusFilter = true,
  handleSort = undefined,
  clearSearchResults = undefined,
  handleSearch = undefined,
  hasSearchResults = false,
  inputComponent,
}) => {
  const [openTagDropdown, setOpenTagDropdown] = useState(false)
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false)
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const [openSortDropdown, setOpenSortDropdown] = useState(false)
  const [sortBy, setSortBy] = useState('Newest first')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  useEffect(() => {
    if (handleSearch) {
      if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
        handleSearch(debouncedSearchTerm)
      } else if (clearSearchResults && hasSearchResults) {
        clearSearchResults()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  const setSearchValue = ({currentTarget}: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(currentTarget.value)
  }
  const toggleOpenTagDropdown = () => {
    setOpenTagDropdown(prev => !prev)
  }
  const toggleOpenStatusDropdown = () => {
    setOpenStatusDropdown(prev => !prev)
  }
  const toggleOpenFilterDropdown = () => {
    setOpenFilterDropdown(prev => !prev)
  }
  const toggleOpenSortDropdown = () => {
    setOpenSortDropdown(prev => !prev)
  }
  const setSortValue = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    if (handleSort) {
      setSortBy(String(currentTarget.textContent))
      handleSort(currentTarget.value as TSortBy)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-start py-2 border-bottom">
        {searchFilter ? (
          <div className="flex-fill mr-5">
            {inputComponent ? (
              inputComponent
            ) : (
              <CustomInputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="border-0 bg-gray">
                    <FaSearch />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  onChange={setSearchValue}
                  value={searchTerm}
                  className="border-0 bg-gray"
                  placeholder="Search"
                />
              </CustomInputGroup>
            )}
          </div>
        ) : null}
        {tagStatusFilter ? (
          <IconContext.Provider value={dropdownIconValues}>
            <div>
              <Dropdown
                isOpen={openTagDropdown}
                toggle={toggleOpenTagDropdown}
                className="d-inline-block"
              >
                <DropdownToggle className="font-weight-bold" color="white">
                  Tagged With&nbsp;
                  {openTagDropdown ? <FiChevronUp /> : <FiChevronDown />}
                </DropdownToggle>
                <DropdownMenu className="px-2">
                  <DropdownItem header>Enter Tag</DropdownItem>
                  <DropdownItem divider />
                  <Input />
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={openStatusDropdown}
                toggle={toggleOpenStatusDropdown}
                className="d-inline-block"
              >
                <DropdownToggle className="font-weight-bold" color="white">
                  Status&nbsp;
                  {openStatusDropdown ? <FiChevronUp /> : <FiChevronDown />}
                </DropdownToggle>
                <DropdownMenu className="px-2">
                  <DropdownItem>All</DropdownItem>
                  <DropdownItem>Active</DropdownItem>
                  <DropdownItem>Inactive</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={openFilterDropdown}
                toggle={toggleOpenFilterDropdown}
                className="d-inline-block"
              >
                <DropdownToggle className="font-weight-bold" color="white">
                  Filter&nbsp;
                  {openFilterDropdown ? <FiChevronUp /> : <FiChevronDown />}
                </DropdownToggle>
                <DropdownMenu className="px-2">
                  <DropdownItem header>Enter text</DropdownItem>
                  <DropdownItem divider />
                  <Input />
                </DropdownMenu>
              </Dropdown>
            </div>
          </IconContext.Provider>
        ) : null}
      </div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <div>
          <FormGroup className="d-inline-block mr-3" check>
            <Label check>
              <Input type="checkbox" /> Select all
            </Label>
          </FormGroup>
          <span>
            Showing <strong>{loaded} </strong>
            values
          </span>
        </div>
        {sortFilter ? (
          <IconContext.Provider value={dropdownIconValues}>
            <div>
              <Dropdown
                isOpen={openSortDropdown}
                toggle={toggleOpenSortDropdown}
                className="d-inline-block"
              >
                <DropdownToggle color="white">
                  Sort by:{' '}
                  <strong>Last update ({sortBy.toLowerCase()})&nbsp;</strong>
                  {openSortDropdown ? <FiChevronUp /> : <FiChevronDown />}
                </DropdownToggle>
                <DropdownMenu className="px-2">
                  <DropdownItem<HTMLButtonElement>
                    value="newest"
                    onClick={setSortValue}
                  >
                    Newest first
                  </DropdownItem>
                  <DropdownItem<HTMLButtonElement>
                    value="oldest"
                    onClick={setSortValue}
                  >
                    Newest last
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </IconContext.Provider>
        ) : null}
      </div>
    </>
  )
}

export default TableFilters
