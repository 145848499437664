import React, {useState} from 'react'
import {Table, Button} from 'reactstrap'
import {navigate} from 'helpers/utils'
import {IconContext} from 'react-icons'
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'
import {TableBodyData} from '../types/table-body.types'
import TableHeaders from './table-headers.component'
import TableLoader from './table-loader.component'

const dropdownIconValues = {
  size: '1.4em',
  style: {verticalAlign: 'middle'},
}
interface Props {
  headers: string[]
  loading: boolean
  page: number
  body: TableBodyData[]
  expandedRowData: {
    [key: string]: JSX.Element
  }
  hasMore: boolean
  hasActiveColor?: boolean
  loadMore(): void
}

const TableWithDropdown: React.FC<Props> = ({
  headers,
  body,
  expandedRowData,
  loading,
  page,
  hasMore,
  loadMore,
  hasActiveColor,
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const renderRowItemCaret = (rowId: string | number) => {
    let caret = <FiChevronDown />
    if (expandedRows.includes(`${rowId}`)) {
      caret = <FiChevronUp />
    }
    return (
      <IconContext.Provider value={dropdownIconValues}>
        {caret}
      </IconContext.Provider>
    )
  }

  const handleRowItemCaretClick = (rowId: string | number) => {
    setExpandedRows(prevExpandedRows => {
      if (prevExpandedRows.includes(`${rowId}`)) {
        return prevExpandedRows.filter(id => id !== `${rowId}`)
      }
      return [...prevExpandedRows, `${rowId}`]
    })
  }

  const tableBody = body.flatMap(
    ({
      header: {
        value: headerValue,
        underlineValue: underlineHeaderValue,
        isImage: headerIsImage,
        isLink: headerIsLink,
        url: headerUrl,
      },
      data,
      disabled,
      key,
    }) => {
      const isRowActive = (rowIdx: string) =>
        hasActiveColor && expandedRows.includes(String(rowIdx))
          ? 'text-danger'
          : ''

      const tableHeader = (
        <td
          onClick={
            headerIsLink && headerUrl
              ? () => {
                  navigate(headerUrl)
                }
              : undefined
          }
          className={`${headerIsLink ? 'clickable' : ''} 
          `}
        >
          {headerIsImage ? (
            <img src={headerValue} alt="Avatar" />
          ) : underlineHeaderValue ? (
            <u>{headerValue}</u>
          ) : (
            headerValue
          )}
        </td>
      )

      const tableData = data.map(
        ({value, underlineValue, isImage, isLink, url}, index) => (
          <td
            onClick={
              isLink && url
                ? () => {
                    navigate(url)
                  }
                : undefined
            }
            key={index}
            className={`${isLink ? 'clickable' : ''}`}
          >
            {isImage && typeof value === 'string' ? (
              <img src={value} alt="Avatar" />
            ) : underlineValue ? (
              <u>{value}</u>
            ) : (
              value
            )}
          </td>
        ),
      )
      tableData.push(
        <td
          className="clickable"
          key={tableData.length}
          onClick={() => {
            handleRowItemCaretClick(key)
          }}
        >
          {renderRowItemCaret(key)}
        </td>,
      )
      const itemRows = [
        <tr
          className={`${disabled ? 'text-muted' : ''} ${isRowActive(
            String(key),
          )}`}
          key={key}
        >
          {tableHeader}
          {tableData}
        </tr>,
      ]
      if (expandedRows.includes(`${key}`)) {
        itemRows.push(expandedRowData[`${key}`])
      }
      return itemRows
    },
  )

  return (
    <Table responsive hover>
      <TableHeaders headers={headers} />
      <tbody>
        {tableBody}
        {(page === 1 && body.length) || !loading ? null : <TableLoader />}
        {!loading && hasMore ? (
          <Button className="mb-1" onClick={loadMore}>
            More
          </Button>
        ) : null}
      </tbody>
    </Table>
  )
}

export default TableWithDropdown
