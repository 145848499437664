import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  SetAdminsMetaAction,
  FetchAdminsSuccessAction,
  UpdateAdminsAction,
} from './admins-actions.types'

import {
  FETCH_ADMINS_START,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  SET_ADMINS_META,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_START,
  FETCH_ADMIN_FAILURE,
  UPDATE_ADMINS,
} from './admins.constants'

import {
  AdminsRequestingStateActions,
  AdminsStateActions,
  AdminsDictionary,
  AdminsIdList,
  Admin,
} from './admins.types'

export function adminsById(
  state: AdminsDictionary = {},
  {type, payload}: FetchAdminsSuccessAction | UpdateAdminsAction,
): AdminsDictionary {
  switch (type) {
    case FETCH_ADMINS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload?.entities.admins}
      }
      return state
    }
    case UPDATE_ADMINS: {
      if (typeof payload === 'string') {
        const stateCopy = {...state}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[payload]: _, ...rest} = stateCopy
        return {
          ...rest,
        }
      }
      return state
    }
    default:
      return state
  }
}

export function allAdminsIds(
  state: AdminsIdList = [],
  {type, payload}: FetchAdminsSuccessAction | UpdateAdminsAction,
): AdminsIdList {
  switch (type) {
    case FETCH_ADMINS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    case UPDATE_ADMINS: {
      if (typeof payload === 'number') {
        return state.filter(id => +id !== payload)
      }
      return state
    }
    default:
      return state
  }
}

export function adminsRequesting(
  state = true,
  {type}: AdminsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_ADMINS_START:
      return true
    case FETCH_ADMINS_SUCCESS:
    case FETCH_ADMINS_FAILURE:
      return false
    default:
      return state
  }
}

export function adminsMeta(
  state: DashboardModelMeta = {pages: 0, total: 0},
  {type, payload}: SetAdminsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_ADMINS_META:
      return payload
    default:
      return state
  }
}

export function currentAdmin(
  state = {requesting: false, details: {} as Admin},
  action: AdminsStateActions,
): {details: Admin; requesting: boolean} {
  switch (action.type) {
    case FETCH_ADMIN_START:
      return {...state, requesting: true}
    case FETCH_ADMIN_SUCCESS:
      return {details: action.payload, requesting: false}
    case FETCH_ADMIN_FAILURE:
      return {...state, requesting: false}
    default:
      return state
  }
}

const adminsReducer = combineReducers({
  byId: adminsById,
  allIds: allAdminsIds,
  meta: adminsMeta,
  requesting: adminsRequesting,
  current: currentAdmin,
})

export default adminsReducer
