import {createSelector, OutputParametricSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {TSortBy} from 'components/common/types/table-body.types'
import {formatDate} from 'helpers/utils'
import {AssociatesState, Associate} from './associates.types'

function getAssociates(state: AppState): AssociatesState {
  return state.associates
}

function getSortedAssociates(
  state: AppState,
  {sortOrder, modelType}: {sortOrder: TSortBy; modelType: 'all' | 'search'},
): Associate[] {
  const associates = state.associates.allIds[modelType].map(
    id => state.associates.byId[modelType][id],
  )

  return associates.sort((a, b) => {
    const tOne = Date.parse(formatDate(a.Date_joined).toString())
    const tTwo = Date.parse(formatDate(b.Date_joined).toString())
    return sortOrder === 'newest' ? tTwo - tOne : tOne - tTwo
  })
}

export const makeGetSortedAssociates = (): OutputParametricSelector<
  AppState,
  {sortOrder: TSortBy; modelType: 'all' | 'search'},
  Associate[],
  (res: Associate[]) => Associate[]
> =>
  createSelector([getSortedAssociates], associates => {
    return associates
  })

export const selectAssociatesRequesting = createSelector(
  getAssociates,
  associates => associates.requesting,
)

export const selectAssociatesMeta = createSelector(
  getAssociates,
  associates => associates.meta.all,
)

export const selectAssociatesSearchMeta = createSelector(
  getAssociates,
  associates => associates.meta.search,
)
