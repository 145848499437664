import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_RETURNS_START} from './returns.constants'
import {FetchReturnsStartSaga} from './returns.sagas'

export function* onFetchReturnsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_RETURNS_START, FetchReturnsStartSaga)
}

export function* returnsSagas(): SagaIterator<void> {
  yield all([call(onFetchReturnsStart)])
}
