import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REWARDS_START,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARDS_FAILURE,
  SET_REWARDS_META,
} from './rewards.constants'
import {NormalizedRewards, RewardsActionTypes} from './rewards.types'

export function fetchRewardsStart(
  data: DashboardModelQuery,
): RewardsActionTypes {
  return {
    type: FETCH_REWARDS_START,
    payload: data,
  }
}

export function fetchRewardsSuccess(
  data: NormalizedRewards,
): RewardsActionTypes {
  return {
    type: FETCH_REWARDS_SUCCESS,
    payload: data,
  }
}

export function fetchRewardsFailure(): RewardsActionTypes {
  return {
    type: FETCH_REWARDS_FAILURE,
  }
}

export function setRewardsMeta(meta: DashboardModelMeta): RewardsActionTypes {
  return {
    type: SET_REWARDS_META,
    payload: meta,
  }
}
