import React, {FC} from 'react'
import EmptyRes from 'assets/images/empty_response.png'
import EmptyResWebp from 'assets/images/empty_response.webp'

const EmptyResponse: FC = () => {
  return (
    <>
      <picture className="text-center">
        <source srcSet={EmptyResWebp} type="image/webp" />
        <source srcSet={EmptyRes} type="image/png" />
        <img
          src={EmptyRes}
          alt="Empty response"
          className="w-50 py-3 mx-auto"
        />
      </picture>
      <h4 className="text-center mx-auto">No information to display</h4>
    </>
  )
}

export default EmptyResponse
