import {combineReducers} from 'redux'
import {
  CHECK_USER_SESSION_START,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  LOGIN_USER_SUCCESS,
  FETCH_ADMIN_PROFILE_FAILURE,
  FETCH_ADMIN_PROFILE_SUCCESS,
} from './user.constants'
import {
  UserSessionRequestingStateActions,
  UserAuthStateActions,
  UserStateActions,
} from './user.types'

// TODO: change unknown to proper type
function userProfile(state = {}, {type, payload}: UserStateActions): unknown {
  switch (type) {
    case FETCH_ADMIN_PROFILE_SUCCESS:
      return payload
    case FETCH_ADMIN_PROFILE_FAILURE:
    default:
      return state
  }
}

function userAuth(state = false, {type}: UserAuthStateActions): boolean {
  switch (type) {
    case CHECK_USER_SESSION_SUCCESS:
    case LOGIN_USER_SUCCESS:
      return true
    case CHECK_USER_SESSION_FAILURE:
      return false
    default:
      return state
  }
}

function userSessionRequesting(
  state = true,
  {type}: UserSessionRequestingStateActions,
): boolean {
  switch (type) {
    case CHECK_USER_SESSION_START:
      return true
    case CHECK_USER_SESSION_SUCCESS:
    case CHECK_USER_SESSION_FAILURE:
    case LOGIN_USER_SUCCESS:
      return false
    default:
      return state
  }
}

const userReducer = combineReducers({
  auth: userAuth,
  userProfile,
  sessionRequesting: userSessionRequesting,
})

export default userReducer
