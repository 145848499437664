import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import RewardsService from 'services/api/rewards/service'
import {normalize} from 'normalizr'
import {FetchRewardsStartAction} from './rewards-actions.types'
import {
  fetchRewardsFailure,
  fetchRewardsSuccess,
  setRewardsMeta,
} from './rewards.actions'

export function* FetchRewardsStartSaga({
  payload: {page},
}: FetchRewardsStartAction): SagaIterator<void> {
  const rewardsService = new RewardsService()
  try {
    const {
      data: {data: rewards, pages},
    } = yield call([rewardsService, 'fetchRewards'], page)
    const normalizedRewards = yield call(
      normalize,
      rewards,
      schema.arrayOfRewards,
    )
    yield put(fetchRewardsSuccess(normalizedRewards))
    yield put(setRewardsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchRewardsFailure())
  }
}
