import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {AdminsState} from './admins.types'

function getAdmins(state: AppState): AdminsState {
  return state.admins
}

export const selectAdminDetails = createSelector(
  getAdmins,
  admins => admins.current.details,
)

export const selectAdminRequesting = createSelector(
  getAdmins,
  admins => admins.current.requesting,
)

export const selectAdminsRequesting = createSelector(
  getAdmins,
  admins => admins.requesting,
)

export const selectAdminsMeta = createSelector(getAdmins, admins => admins.meta)

export const selectAdmins = createSelector(getAdmins, admins =>
  admins.allIds.map(id => admins.byId[id]),
)
