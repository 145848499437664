import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  ADD_ADMIN_START,
  DELETE_ADMIN_START,
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_START,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMIN_FAILURE,
  FETCH_ADMIN_START,
  FETCH_ADMIN_SUCCESS,
  SET_ADMINS_META,
  UPDATE_ADMINS,
} from './admins.constants'
import {Admin, AdminsActionTypes, NormalizedAdmins} from './admins.types'

export function fetchAdminsStart(
  data?: DashboardModelQuery,
): AdminsActionTypes {
  return {
    type: FETCH_ADMINS_START,
    payload: data,
  }
}

export function fetchAdminsSuccess(data: NormalizedAdmins): AdminsActionTypes {
  return {
    type: FETCH_ADMINS_SUCCESS,
    payload: data,
  }
}

export function fetchAdminsFailure(): AdminsActionTypes {
  return {
    type: FETCH_ADMINS_FAILURE,
  }
}

export function setAdminsMeta(meta: DashboardModelMeta): AdminsActionTypes {
  return {
    type: SET_ADMINS_META,
    payload: meta,
  }
}

export function fetchAdminStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_ADMIN_START,
    payload: id,
  }
}

export function fetchAdminSuccess(data: Admin): AdminsActionTypes {
  return {
    type: FETCH_ADMIN_SUCCESS,
    payload: data,
  }
}

export function fetchAdminFailure(): AdminsActionTypes {
  return {
    type: FETCH_ADMIN_FAILURE,
  }
}

export function addAdminStart(id: string): AdminsActionTypes {
  return {
    type: ADD_ADMIN_START,
    payload: id,
  }
}

export function deleteAdminStart(adminId: number): AdminsActionTypes {
  return {
    type: DELETE_ADMIN_START,
    payload: adminId,
  }
}

export function updateAdmins(id: number): AdminsActionTypes {
  return {
    type: UPDATE_ADMINS,
    payload: id,
  }
}
