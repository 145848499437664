import ActionBar from 'components/common/action-bar.component'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {StyledTableRow} from 'components/common/styles/row.styles'
import TableFilters from 'components/common/tables/table-filter.component'
import AdminLayout from 'components/layouts/admin.component'
import React, {FC, useState, lazy, Suspense} from 'react'
import {useSelector} from 'react-redux'
import {Col, Row} from 'reactstrap'
import {fetchAdsStart} from '_redux/ads/ads.actions'
import {
  selectAds,
  selectAdsMeta,
  selectAdsRequesting,
} from '_redux/ads/ads.selectors'
import {BasicTable as AdsListTable} from 'components/common/tables'
import {transformAdsForTable} from './utils'

const AddAdFormOverview = lazy(() => import('./add-form.component'))
const BaseModal = lazy(() => import('components/common/base-modal.component'))

const AdsList: FC = () => {
  const ads = useSelector(selectAds)
  const adsRequesting = useSelector(selectAdsRequesting)
  const {pages: adsPages} = useSelector(selectAdsMeta)
  const transformedAds = transformAdsForTable(ads)
  const [isOpen, setIsOpen] = useState(false)
  const [currentAdsPage, loadMoreAds] = usePagination(fetchAdsStart)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  return (
    <AdminLayout pageTitle="Adverts">
      <Row>
        <ActionBar headerText="Adverts" onAddClick={toggleModal} />
      </Row>
      {ads.length ? (
        <StyledTableRow>
          <Col>
            <TableFilters
              loaded={transformedAds.length}
              total={transformedAds.length}
              sortFilter={false}
            />
            <AdsListTable
              headers={['', 'Title', 'Description', 'Status']}
              loading={adsRequesting}
              page={currentAdsPage}
              body={transformedAds}
              hasMore={currentAdsPage < adsPages}
              loadMore={loadMoreAds}
            />
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={adsRequesting} />
      )}
      <Suspense fallback={<div />}>
        <BaseModal title="Add Advert" isOpen={isOpen} toggle={toggleModal}>
          <AddAdFormOverview toggleModal={toggleModal} />
        </BaseModal>
      </Suspense>
    </AdminLayout>
  )
}

export default AdsList
