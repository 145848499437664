import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {UserState} from './user.types'

function getUser(state: AppState): UserState {
  return state.user
}

export const selectUserAuth = createSelector(getUser, user => user.auth)

export const selectAdminProfile = createSelector(
  getUser,
  user => user.userProfile,
)

export const selectUserSessionIsRequesting = createSelector(
  getUser,
  user => user.sessionRequesting,
)
