import styled from 'styled-components/macro'
import {Container} from 'reactstrap'
import HeroImg from 'assets/images/login_background.jpg'

export const StyledLoginContainer = styled(Container)`
  background-image: url(${HeroImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;
  object-fit: cover;
  margin-left: 0;
  margin-right: 0;
`
