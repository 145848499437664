export const FETCH_MEMBERS_START = 'FETCH_MEMBERS_START'
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS'
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE'
export const SET_MEMBERS_META = 'SET_MEMBERS_META'

export const FETCH_MEMBER_DETAILS_START = 'FETCH_MEMBER_DETAILS_START'
export const FETCH_MEMBER_DETAILS_SUCCESS = 'FETCH_MEMBER_DETAILS_SUCCESS'
export const FETCH_MEMBER_DETAILS_FAILURE = 'FETCH_MEMBER_DETAILS_FAILURE'

export const FETCH_MEMBER_INVESTMENTS_START = 'FETCH_MEMBER_INVESTMENTS_START'
export const FETCH_MEMBER_INVESTMENTS_SUCCESS =
  'FETCH_MEMBER_INVESTMENTS_SUCCESS'
export const FETCH_MEMBER_INVESTMENTS_FAILURE =
  'FETCH_MEMBER_INVESTMENTS_FAILURE'

export const FETCH_MEMBER_REFERRED_START = 'FETCH_MEMBER_REFERRED_START'
export const FETCH_MEMBER_REFERRED_SUCCESS = 'FETCH_MEMBER_REFERRED_SUCCESS'
export const FETCH_MEMBER_REFERRED_FAILURE = 'FETCH_MEMBER_REFERRED_FAILURE'

export const FETCH_MEMBER_REWARDS_START = 'FETCH_MEMBER_REWARDS_START'
export const FETCH_MEMBER_REWARDS_SUCCESS = 'FETCH_MEMBER_REWARDS_SUCCESS'
export const FETCH_MEMBER_REWARDS_FAILURE = 'FETCH_MEMBER_REWARDS_FAILURE'

export const FETCH_MEMBER_RETURNS_START = 'FETCH_MEMBER_RETURNS_START'
export const FETCH_MEMBER_RETURNS_SUCCESS = 'FETCH_MEMBER_RETURNS_SUCCESS'
export const FETCH_MEMBER_RETURNS_FAILURE = 'FETCH_MEMBER_RETURNS_FAILURE'

export const FETCH_LOCATION_CHART_DATA_START = 'FETCH_LOCATION_CHART_DATA_START'
export const FETCH_LOCATION_CHART_DATA_SUCCESS =
  'FETCH_LOCATION_CHART_DATA_SUCCESS'
export const FETCH_LOCATION_CHART_DATA_FAILURE =
  'FETCH_LOCATION_CHART_DATA_FAILURE'
