import React, {FC} from 'react'
import {Table} from 'reactstrap'

const SystemTable: FC = () => {
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Update</th>
          <th>Status</th>
          <th>Last backup</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>06-07-2020</td>
          <td>Pending</td>
          <td>-</td>
        </tr>
        <tr>
          <td>02-07-2020</td>
          <td>Confirmed</td>
          <td>03-07-2020</td>
        </tr>
        <tr>
          <td>25-06-2020</td>
          <td>Confirmed</td>
          <td>26-06-2020</td>
        </tr>
        <tr>
          <td>23-06-2020</td>
          <td>Confirmed</td>
          <td>24-06-2020</td>
        </tr>
        <tr>
          <td>02-06-2020</td>
          <td>Confirmed</td>
          <td>03-06-2020</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default SystemTable
