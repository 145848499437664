import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {ReturnsState} from './returns.types'

function getReturns(state: AppState): ReturnsState {
  return state.returns
}

export const selectReturnsRequesting = createSelector(
  getReturns,
  returns => returns.requesting,
)

export const selectReturnsMeta = createSelector(
  getReturns,
  returns => returns.meta,
)

export const selectReturns = createSelector(getReturns, returns =>
  returns.allIds.map(id => returns.byId[id]),
)
