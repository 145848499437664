import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_ASSOCIATES_START,
  FETCH_ASSOCIATES_SUCCESS,
  FETCH_ASSOCIATES_FAILURE,
  SET_ASSOCIATES_META,
  SEARCH_ASSOCIATES_START,
  SEARCH_ASSOCIATES_SUCCESS,
  SEARCH_ASSOCIATES_FAILURE,
  SET_SEARCH_ASSOCIATES_META,
  CLEAR_SEARCH_ASSOCIATES,
} from './associates.constants'
import {AssociatesActionTypes, NormalizedAssociates} from './associates.types'

export function fetchAssociatesStart(
  data: DashboardModelQuery,
): AssociatesActionTypes {
  return {
    type: FETCH_ASSOCIATES_START,
    payload: data,
  }
}

export function fetchAssociatesSuccess(
  data: NormalizedAssociates,
): AssociatesActionTypes {
  return {
    type: FETCH_ASSOCIATES_SUCCESS,
    payload: data,
  }
}

export function fetchAssociatesFailure(): AssociatesActionTypes {
  return {
    type: FETCH_ASSOCIATES_FAILURE,
  }
}

export function setAssociatesMeta(
  meta: DashboardModelMeta,
): AssociatesActionTypes {
  return {
    type: SET_ASSOCIATES_META,
    payload: meta,
  }
}

export function searchAssociatesStart(
  query: DashboardModelQuery,
): AssociatesActionTypes {
  return {
    type: SEARCH_ASSOCIATES_START,
    payload: query,
  }
}

export function searchAssociatesSuccess(
  data: NormalizedAssociates,
): AssociatesActionTypes {
  return {
    type: SEARCH_ASSOCIATES_SUCCESS,
    payload: data,
  }
}

export function searchAssociatesFailure(): AssociatesActionTypes {
  return {
    type: SEARCH_ASSOCIATES_FAILURE,
  }
}

export function setSearchAssociatesMeta(
  meta: DashboardModelMeta,
): AssociatesActionTypes {
  return {
    type: SET_SEARCH_ASSOCIATES_META,
    payload: meta,
  }
}

export function clearSearchAssociates(): AssociatesActionTypes {
  return {
    type: CLEAR_SEARCH_ASSOCIATES,
  }
}
