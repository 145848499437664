import {combineReducers} from 'redux'
import {
  bondsById,
  allBondsIds,
  bondsMeta,
  bondsRequesting,
  bondsSummary,
  bondsSummaryRequesting,
  investmentChartDataRequesting,
  investmentChartData,
} from './all/bonds-all.reducers'
import {
  bondSeriesInvestors,
  bondSeriesInvestorsRequesting,
} from './single/bonds-single.reducer'

const bondsReducer = combineReducers({
  byId: bondsById,
  allIds: allBondsIds,
  meta: bondsMeta,
  requesting: bondsRequesting,
  summary: bondsSummary,
  summaryRequesting: bondsSummaryRequesting,
  investors: bondSeriesInvestors,
  investorsRequesting: bondSeriesInvestorsRequesting,
  chartData: investmentChartData,
  chartDataRequesting: investmentChartDataRequesting,
})

export default bondsReducer
