import {theme} from 'AppTheme'
import ActionBar from 'components/common/action-bar.component'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {StyledFixedTableRow} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import {PlaceholderImage} from 'helpers/placeholder-image'
import React, {FC, useEffect, useState, Suspense, lazy} from 'react'
import {IconContext} from 'react-icons'
import {FaRegTimesCircle} from 'react-icons/fa'
import {FiEdit} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps} from 'react-router'
import {Button, Col, Input, Label, Row} from 'reactstrap'
import {fetchAdStart} from '_redux/ads/ads.actions'
import {selectAdDetails, selectAdRequesting} from '_redux/ads/ads.selectors'

import {StyledDetailsRow} from './styles'

const EditAdFormOverview = lazy(() => import('./edit-form.component'))
const BaseModal = lazy(() => import('components/common/base-modal.component'))

const colorRed = {color: theme.colors.primary}
const editIconValues = {
  ...colorRed,
  size: '1.4em',
  style: {verticalAlign: 'middle', marginBottom: '4px'},
}

const AdsProfile: FC<RouteComponentProps<{id: string}>> = ({
  match: {
    params: {id},
  },
}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchAdStart(id))
  }, [id, dispatch])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const adDetails = useSelector(selectAdDetails)
  const adRequesting = useSelector(selectAdRequesting)

  return (
    <AdminLayout pageTitle="Advert Profile">
      <Row>
        <ActionBar headerText="Advert Profile" />
      </Row>
      <StyledDetailsRow className="py-3 align-items-start">
        {adRequesting ? (
          <LoadingOrEmptyModel loading={adRequesting} />
        ) : (
          <>
            <Col className="border rounded pt-3 pb-2 px-3">
              <img
                className="img-fluid img-thumbnail border-0 rounded-circle"
                alt="..."
                src={adDetails?.image || PlaceholderImage}
              />
              <IconContext.Provider value={editIconValues}>
                <Label for="bondImage" className="pt-2 float-right clickable">
                  <FiEdit />
                </Label>
                <Input
                  type="file"
                  name="file"
                  id="bondImage"
                  accept="image/*"
                  className="d-none"
                />
              </IconContext.Provider>
            </Col>

            <Col sm="9">
              <StyledFixedTableRow className="py-3">
                <Col sm="12" className="border rounded">
                  <div className="font-weight-bold py-3 border-bottom">
                    Details
                    <IconContext.Provider value={editIconValues}>
                      <Button onClick={toggleModal} color=" float-right">
                        <div className="d-inline text-secondary">
                          <span className="pr-2">Edit</span>
                          <FiEdit />
                        </div>
                      </Button>
                    </IconContext.Provider>
                  </div>

                  <div
                    className="font-weight-bold py-3 border-bottom"
                    style={colorRed}
                  >
                    {adDetails?.bond_name}
                  </div>
                </Col>
              </StyledFixedTableRow>

              <StyledFixedTableRow className="py-3">
                <Col sm="12" className="border rounded">
                  <div className="font-weight-bold py-3">Description</div>

                  <div>
                    <p>{adDetails?.description || ''}</p>
                  </div>
                </Col>
              </StyledFixedTableRow>
              <IconContext.Provider
                value={{...editIconValues, color: 'text-secondary'}}
              >
                <div
                  role="button"
                  // onClick={toggleOpenEditModal}
                  className="d-inline text-secondary float-right"
                >
                  <span className="pr-2">Delete</span>
                  <FaRegTimesCircle />
                </div>
              </IconContext.Provider>
            </Col>
            <Suspense fallback={<div />}>
              <BaseModal
                title="Edit Advert"
                isOpen={isOpen}
                toggle={toggleModal}
              >
                <EditAdFormOverview ad={adDetails} toggleModal={toggleModal} />
              </BaseModal>
            </Suspense>
          </>
        )}
      </StyledDetailsRow>
    </AdminLayout>
  )
}

export default AdsProfile
