import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_ADS_START, FETCH_AD_START, ADD_AD_START} from './ads.constants'
import {
  fetchAdsStartSaga,
  fetchAdStartSaga,
  saveNewAdStartSaga,
} from './ads.sagas'

export function* onFetchAdsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_ADS_START, fetchAdsStartSaga)
}

export function* onFetchAdStart(): SagaIterator<void> {
  yield takeLatest(FETCH_AD_START, fetchAdStartSaga)
}

export function* onAddAdStart(): SagaIterator<void> {
  yield takeLatest(ADD_AD_START, saveNewAdStartSaga)
}

export function* adsSagas(): SagaIterator<void> {
  yield all([call(onFetchAdsStart), call(onFetchAdStart), call(onAddAdStart)])
}
