// import {AddAdFormProps} from 'components/dashboard/ads-profile/types'
import {AxiosResponse} from 'axios'
import {getCookieValue, toFormData} from 'helpers/utils'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {
  FETCH_ADS_ENDPOINT,
  FETCH_AD_ENDPOINT,
  ADD_AD_ENDPOINT,
} from '../endpoints'

class AdsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchAds(): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_ADS_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchAd(id: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_AD_ENDPOINT}?id=${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
  async addAd(data: any): Promise<AxiosResponse<any>> {
    const form = toFormData({...data})
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: `${ADD_AD_ENDPOINT}`,
      data: form,
      options: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default AdsService
