import React, {Component} from 'react'
// import * as Sentry from '@sentry/browser'
import {Link, Redirect} from 'react-router-dom'
import ErrorBoundaryImg from 'assets/images/error-boundary.png'
import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
} from './styles/error-boundary.styles'

export class ErrorBoundary extends Component {
  state = {hasError: false, redirect: false}

  static getDerivedStateFromError(): {hasError: boolean} {
    // Sentry.captureException(error)
    return {hasError: true}
  }

  componentDidCatch(): void {
    // Sentry.withScope(scope => {
    //   Object.keys(errorInfo).forEach(key => {
    //     scope.setExtra(key, errorInfo[key])
    //   })
    //   Sentry.captureException(error)
    // })
  }

  componentDidUpdate(): void {
    if (this.state.hasError) {
      setTimeout(() => this.setState({redirect: true}), 5000)
    }
  }

  render(): React.ReactNode {
    if (this.state.redirect) {
      return <Redirect to="/" />
    }
    return this.state.hasError ? (
      <ErrorImageOverlay>
        <ErrorImageContainer imageUrl={ErrorBoundaryImg} />
        <ErrorImageText>
          There was an error with this page. <Link to="/">Click here</Link> to
          back to the home page or wait five seconds
        </ErrorImageText>
      </ErrorImageOverlay>
    ) : (
      this.props.children
    )
  }
}
