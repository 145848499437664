export const CHECK_USER_SESSION_START = 'CHECK_USER_SESSION_START'
export const CHECK_USER_SESSION_SUCCESS = 'CHECK_USER_SESSION_SUCCESS'
export const CHECK_USER_SESSION_FAILURE = 'CHECK_USER_SESSION_FAILURE'

export const LOGIN_USER_START = 'LOGIN_USER_START'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const FETCH_ADMIN_PROFILE_START = 'FETCH_ADMIN_PROFILE_START'
export const FETCH_ADMIN_PROFILE_SUCCESS = 'FETCH_ADMIN_PROFILE_SUCCESS'
export const FETCH_ADMIN_PROFILE_FAILURE = 'FETCH_ADMIN_PROFILE_FAILURE'
