import {Reward} from '_redux/rewards/rewards.types'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from 'components/common/types/table-body.types'

export const transformRewardsForTable = (
  rewards: Reward[],
  tab: string | undefined,
): TableBodyData[] => {
  const filteredRewards = tab
    ? rewards.filter(reward => reward.Membership === tab)
    : rewards

  return filteredRewards.map(reward => ({
    key: reward?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },
    data: [
      {value: reward?.id ?? '-'},
      {
        value: reward?.Name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${reward.user_id}`,
      },
      {value: reward?.Membership ?? '-'},
      {value: reward?.Location ?? '-'},
      {
        value:
          reward?.Reward_Amount === 0
            ? reward.Reward_Amount
            : `£${reward.Reward_Amount.toLocaleString()}`,
      },
    ],
  }))
}
