import {LoginFormProps} from 'components/login/types'
import {AxiosResponse} from 'axios'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {ADMIN_LOGIN_ENDPOINT, ADMIN_AUTH_SESSION_ENDPOINT} from '../endpoints'

class UserAuthService {
  requestHandler: typeof requestHandler
  constructor() {
    this.requestHandler = requestHandler
  }

  async checkUserSession(token: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: ADMIN_AUTH_SESSION_ENDPOINT,
      data: {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    })
    return res
  }

  async loginUser(data: LoginFormProps): Promise<AxiosResponse<any>> {
    const form = new FormData()
    form.set('email', data.email)
    form.set('password', data.password)
    const res = await this.requestHandler({
      method: HttpMethods.POST,
      url: ADMIN_LOGIN_ENDPOINT,
      data: form,
      options: {
        headers: {'Content-Type': 'multipart/form-data'},
      },
    })
    return res
  }
}

export default new UserAuthService()
