// import {AddAdFormProps} from 'components/dashboard/ads-profile/types'
import {AxiosResponse} from 'axios'
import {getCookieValue} from 'helpers/utils'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {
  ADD_ADMIN_ENDPOINT,
  DELETE_ADMIN_ENDPOINT,
  FETCH_ADMINS_ENDPOINT,
} from '../endpoints'

class AdminsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchAdmins(): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_ADMINS_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async deleteAdmin(id: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${DELETE_ADMIN_ENDPOINT}${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async addAdmin(id: string): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${ADD_ADMIN_ENDPOINT}${id}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default AdminsService
