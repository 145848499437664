import ActionBar from 'components/common/action-bar.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import React, {FC, useState, useMemo, useCallback, useEffect} from 'react'
import {Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {
  fetchReferredUsersStart,
  searchReferredUsersStart,
  clearSearchReferredUsers,
} from '_redux/referrals/referrals.actions'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import {useSelector} from 'react-redux'
import {
  selectReferredUsersRequesting,
  makeGetSortedReferredUsers,
} from '_redux/referrals/referrals.selectors'
import {ReferralStatus} from '_redux/referrals/referrals.types'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData, TSortBy} from 'components/common/types/table-body.types'
import {AppState} from '_redux/store.types'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {TableWithDropdown} from 'components/common/tables'
import {useSearch} from 'components/common/hooks/use-search.hook'
import {transformReferredUsersForTable} from './utils'
import {bondsRowsForReferralsTable} from './table-bonds-rows.component'

const referralsValue = {
  active: 'Active',
  pending: 'Pending',
}

const DashboardReferrals: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOrder, setSortOrder] = useState<TSortBy>('newest')
  const [
    currentReferredUsersPage,
    loadMoreReferredUsers,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchReferredUsersStart)
  const [
    currentSearchPage,
    loadMoreSearch,
    handleSearchRequest,
    resetCurrentSearchPage,
  ] = useSearch(searchReferredUsersStart, clearSearchReferredUsers)

  const referredUsersRequesting = useSelector(selectReferredUsersRequesting)
  const select = useMemo(makeGetSortedReferredUsers, [])
  const referrals = useSelector((state: AppState) =>
    select(state, {sortOrder, modelType: 'all'}),
  )
  const referralsSearch = useSelector((state: AppState) =>
    select(state, {sortOrder, modelType: 'search'}),
  )
  const bondsRowsForTable = bondsRowsForReferralsTable(referrals)
  const bondsRowsForSearchTable = bondsRowsForReferralsTable(referralsSearch)

  const transformedReferredUsers: TableBodyData[] = transformReferredUsersForTable(
    referrals,
    referralsValue[tab],
  )
  const transformedSearchReferredUsers: TableBodyData[] = transformReferredUsersForTable(
    referralsSearch,
    undefined,
  )
  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/all/.test(lowercaseTab)) {
      setExtraQueryParams({query: ReferralStatus[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const handleSort = (sortBy: TSortBy) => {
    setSortOrder(sortBy)
  }

  const handleSearch = useCallback(
    (query: string) => {
      setSearchTerm(query)
      handleSearchRequest({query})
    },
    [handleSearchRequest],
  )

  const clearSearchResults = useCallback(() => {
    setSearchTerm('')
    resetCurrentSearchPage()
  }, [resetCurrentSearchPage])

  useEffect(() => {
    return () => {
      clearSearchResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableValues = (() => ({
    page: searchTerm ? currentSearchPage : currentReferredUsersPage,
    expandedRowData: searchTerm ? bondsRowsForTable : bondsRowsForSearchTable,
    body: searchTerm
      ? transformedSearchReferredUsers
      : transformedReferredUsers,
    hasMore: true,
    loadMore: searchTerm ? loadMoreSearch : loadMoreReferredUsers,
  }))()

  return (
    <AdminLayout pageTitle="Referrals">
      <Row>
        <ActionBar headerText="Referrals" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={['All', 'Active', 'Pending']}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>
      {(searchTerm ? true : transformedReferredUsers.length) ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              handleSort={handleSort}
              handleSearch={handleSearch}
              clearSearchResults={clearSearchResults}
              hasSearchResults={transformedSearchReferredUsers.length > 0}
              loaded={
                searchTerm
                  ? transformedSearchReferredUsers.length
                  : transformedReferredUsers.length
              }
              total={
                searchTerm
                  ? transformedSearchReferredUsers.length
                  : transformedReferredUsers.length
              }
            />
            <div>
              <TableWithDropdown
                headers={[
                  '',
                  'ID',
                  'Name',
                  'Membership',
                  'Location',
                  'Status',
                  'Join Date',
                  'Referred by',
                  'Referrals',
                  'Amount',
                  '',
                ]}
                hasActiveColor
                loading={referredUsersRequesting}
                {...tableValues}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={referredUsersRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardReferrals
