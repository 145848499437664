export const FETCH_ADS_START = 'FETCH_ADS_START'
export const FETCH_ADS_SUCCESS = 'FETCH_ADS_SUCCESS'
export const FETCH_ADS_FAILURE = 'FETCH_ADS_FAILURE'
export const SET_ADS_META = 'SET_ADS_META'

export const FETCH_AD_START = 'FETCH_AD_START'
export const FETCH_AD_SUCCESS = 'FETCH_AD_SUCCESS'
export const FETCH_AD_FAILURE = 'FETCH_AD_FAILURE'
export const ADD_AD_START = 'ADD_AD_START'
export const EDIT_AD_START = 'EDIT_AD_START'
