import React, {FC, useEffect, useState} from 'react'
import {Row, Nav, Col} from 'reactstrap'
import AdminLayout from 'components/layouts/admin.component'
import ActionBar from 'components/common/action-bar.component'
import {
  selectBondsSummary,
  selectBondsSummaryRequesting,
  selectBondsRequesting,
  selectBondsMeta,
  selectBonds,
} from '_redux/bonds/bonds.selectors'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchBondsSummaryStart,
  fetchBondsStart,
} from '_redux/bonds/all/bonds-all.actions'
import BaseModal from 'components/common/base-modal.component'
import {
  StyledNavLinkRow,
  StyledFixedTableRow,
} from 'components/common/styles/row.styles'
import TableFilters from 'components/common/tables/table-filter.component'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {BasicTable as DashboardBondsDetailsTable} from 'components/common/tables'
import {StyledChartRow} from './styles'
import AddBondFormOverview from './add-form.component'
import DashboardBondMgmtCharts from './charts.component'
import {transformBondsForTable} from './utils'

const DashboardBondMgmt: FC = () => {
  const [currentBondTab, setCurrentBondTab] = useState('bond')
  const [openAddModal, setOpenAddModal] = useState(false)
  const [currentBondsPage, loadMoreBonds] = usePagination(fetchBondsStart)
  const toggleCurrentBondTab = (tab: string) => {
    if (currentBondTab !== tab) setCurrentBondTab(tab)
  }
  const toggleOpenAddModal = () => {
    setOpenAddModal(prev => !prev)
  }
  const closeAddModal = () => {
    setOpenAddModal(false)
  }

  const dispatch = useDispatch()
  const summaryRequesting = useSelector(selectBondsSummaryRequesting)
  const bondsSummary = useSelector(selectBondsSummary)
  const bondsRequesting = useSelector(selectBondsRequesting)
  const {pages: bondsPages, total} = useSelector(selectBondsMeta)
  const bonds = useSelector(selectBonds)

  useEffect(() => {
    dispatch(fetchBondsSummaryStart())
  }, [dispatch])

  const transformedBonds = transformBondsForTable(bonds)

  return (
    <AdminLayout pageTitle="Bond Management">
      <Row>
        <ActionBar
          onAddClick={toggleOpenAddModal}
          headerText="Bond Management"
        />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={['Bond', 'Text']}
            currentTab={currentBondTab}
            toggleCurrentTab={toggleCurrentBondTab}
          />
        </Nav>
      </StyledNavLinkRow>
      <StyledChartRow className="py-3 d-flex justify-content-around border rounded">
        <h5 className="w-100 pl-3 pb-4 font-weight-bold h5">Overview</h5>
        <DashboardBondMgmtCharts
          loading={summaryRequesting}
          bondsSummary={bondsSummary}
        />
      </StyledChartRow>
      <BaseModal
        isOpen={openAddModal}
        toggle={toggleOpenAddModal}
        title="Create Bond"
      >
        <AddBondFormOverview toggleModal={closeAddModal} />
      </BaseModal>
      {bonds.length ? (
        <StyledFixedTableRow className="py-3">
          <Col sm="12" className="border rounded">
            <div className="font-weight-bold py-3 border-bottom">
              Bond details
            </div>
            <TableFilters
              loaded={bonds.length}
              total={bondsPages * (total || 1)}
            />
            <div>
              <DashboardBondsDetailsTable
                headers={[
                  '',
                  'Bond',
                  'Location',
                  'Status',
                  'Start date',
                  'End date',
                  'Members',
                  'Unit price',
                  'Units',
                  'Returns',
                ]}
                loading={bondsRequesting}
                page={currentBondsPage}
                body={transformedBonds}
                hasMore={currentBondsPage < (total || 1000)}
                loadMore={loadMoreBonds}
              />
            </div>
          </Col>
        </StyledFixedTableRow>
      ) : (
        <LoadingOrEmptyModel loading={bondsRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardBondMgmt
